import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { BillingAction } from "../../store/actions";
import { Layout, Text, dateFormatter } from "../../components";
import { Colors } from "../../config";

import BillingForm from "./BillingForm";

import styles from "./styles";

const stripeKey =
  process.env.REACT_APP_ENV === "production"
    ? process.env.REACT_APP_STRIPE_KEY_PROD
    : process.env.REACT_APP_STRIPE_KEY_STAGING;

const stripePromise = loadStripe(stripeKey);

function BillingInformation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const schoolId = useSelector((state) => state.auth.user.user.schoolId);
  const billingInfo = useSelector(
    (state) => state.billing.billings.updatedBilling
  );

  useEffect(() => {
    dispatch(BillingAction.getBilling({ schoolId: schoolId }));
  }, []);

  return (
    <>
      <Layout.Spacer height={5} />

      <div style={styles.rowStyle}>
        <div>
          <Text.Heading
            fontSize={"18px"}
            fontWeight={"600"}
            color={Colors.Primary}
            text="Billing Information"
          />
          <Layout.Spacer height={0} />
          <Text.Heading
            fontSize={"16px"}
            fontWeight={"400"}
            color={Colors.Gray}
            text="Here you can view and change the billing information of the account."
          />
        </div>
        <div
          style={styles.viewInvoicesButton}
          onClick={() => navigate("/admin/billing/paymentDetails")}
        >
          View Invoices
        </div>
      </div>
      <Layout.Spacer height={15} />
      <div style={styles.accountInfoContainer}>
        {billingInfo?.paymentType === "stripe" ? (
          <div>
            <Text.Heading
              fontSize={"18px"}
              fontWeight={"500"}
              color={Colors.Primary}
              text="Billing cycle : "
            />
            <Text.Heading
              fontSize={"18px"}
              fontWeight={"600"}
              color={Colors.Gray}
              text={
                billingInfo?.billingCycle !== ""
                  ? billingInfo?.billingCycle
                  : "-"
              }
            />
          </div>
        ) : (
          <div>
            <Text.Heading
              fontSize={"18px"}
              fontWeight={"500"}
              color={Colors.Primary}
              text="Validity : "
            />
            <Text.Heading
              fontSize={"18px"}
              fontWeight={"600"}
              color={Colors.Gray}
              text={dateFormatter(billingInfo?.validUntil)}
            />
          </div>
        )}

        <div>
          <Text.Heading
            fontSize={"18px"}
            fontWeight={"500"}
            color={Colors.Primary}
            text="Subscription : "
          />
          <Text.Heading
            fontSize={"18px"}
            fontWeight={"600"}
            color={Colors.Gray}
            text={
              billingInfo?.subscriptionPeriod !== ""
                ? billingInfo?.subscriptionPeriod === "1"
                  ? "Monthly"
                  : "Yearly"
                : "-"
            }
          />
        </div>
        <div>
          <Text.Heading
            fontSize={"18px"}
            fontWeight={"500"}
            color={Colors.Primary}
            text="Subscription Status: "
          />
          <Text.Heading
            fontSize={"18px"}
            fontWeight={"600"}
            color={Colors.Gray}
            text={
              billingInfo?.billingStatus === "active"
                ? "Active"
                : billingInfo?.billingStatus === "inactive"
                ? "Inactive"
                : "-"
            }
          />
        </div>
        <div>
          <Text.Heading
            fontSize={"18px"}
            fontWeight={"500"}
            color={Colors.Primary}
            text="Payment Method: "
          />
          <Text.Heading
            fontSize={"18px"}
            fontWeight={"600"}
            color={Colors.Gray}
            textTransform="capitalize"
            text={billingInfo?.paymentType || "-"}
          />
        </div>
      </div>

      {(!billingInfo?.amount || billingInfo?.amount === 0) && (
        <>
          <Layout.Spacer height={10} />
          <div style={{ color: "red", fontWeight: "600" }}>
            Please discuss the amount with our admins for payment.
          </div>
        </>
      )}

      {billingInfo?.amount !== 0 && billingInfo?.paymentType === "stripe" && (
        <Elements stripe={stripePromise}>
          <BillingForm />
        </Elements>
      )}
    </>
  );
}

export default BillingInformation;
