import React from 'react';
import { Route, Routes } from 'react-router-dom';

import DashboardLayout from '../../components/Layout/DashboardLayout';
import { PROTECTED_ROUTES } from './siteMap';

const AppRoutes = (props) => {
  return (
    <Routes>
      <Route element={<DashboardLayout />}>
        {PROTECTED_ROUTES.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={route.component}
            exact={route.exact}
          />
        ))}
      </Route>
    </Routes>
  );
};

export default AppRoutes;
