import { Layout } from "../../components";

function Settings({ children }) {
  const steps = [
    {
      heading: "Account Settings",
      subHeading: "Change Personal Info",
      route: "/settings",
    },
    {
      heading: "Security Settings",
      subHeading: "Change Password",
      route: "/settings/change-password",
    },
  ];
  return (
    <Layout.StepsSection
      heading="Settings"
      stepsList={steps}
      children={children}
    />
  );
}

export default Settings;
