import { put } from "redux-saga/effects";

import { ApiCaller } from "../../config";
import { ApplicationAction } from "../actions";

export default class ApplicationMiddleware {
  static *getApplications({ payload, cb }) {
    try {
      const { limit, offset } = payload;
      let res;
      if (payload.schoolId) {
        res = yield ApiCaller.Get(
          `/school/fetch-applications?limit=${limit}&offset=${offset}&schoolId=${payload.schoolId}`
        );
      } else {
        res = yield ApiCaller.Get(
          `/school/fetch-applications?limit=${limit}&offset=${offset}`
        );
      }
      if (res.status === 200) {
        yield put(ApplicationAction.getApplicationsSuccess(res.data.data));
        if (cb) {
          cb();
        }
      } else {
        yield put(ApplicationAction.getApplicationsSuccess([]));
        // Utils.showSnackBar({
        //     message: res.data.message,
        //     severity: 'error',
        //   });
      }
    } catch (err) {
      yield put(ApplicationAction.getApplicationsFailure());
    }
  }

  static *changeApplicationStatus({ id, status, cb }) {
    try {
      let res = yield ApiCaller.Get(
        `/school/change-application-status/${id}?status=${status}`
      );
      if (res.status === 200) {
        yield put(
          ApplicationAction.changeApplicationStatusSuccess(res.data.data)
        );
        if (cb) {
          cb();
        }
      } else {
        yield put(ApplicationAction.changeApplicationStatusFailure());
        // Utils.showSnackBar({
        //   message: res.data.message,
        //   severity: 'error',
        // });
      }
    } catch (err) {
      yield put(ApplicationAction.changeApplicationStatusFailure());
    }
  }
}
