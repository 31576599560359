import { Colors } from "../../config";

const styles = {
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  alignCenter: {
    alignSelf: "center",
  },
  itemCenter: {
    display: "flex",
    alignItems: "center",
  },
  flexColumnCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  itemCenterjustifyCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  sectionStyle: {
    display: "flex",
    flexDirection: "row",
  },

  line: {
    background: "rgba(3, 129, 117, 0.22)",
    height: "550px",
    width: "2px",
    marginInline: "30px",
  },

  // login layout
  background: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: "0",
    bottom: "0",
    overflow: "hidden",
  },
  loginBackgroundImage: { height: "100%", width: "100%", objectFit: "cover" },

  backgroundOpacity: {
    backgroundColor: "rgba(255,255,255,.5)",
    width: "100%",
    height: "100%",
  },
  card: {
    position: "absolute",
    top: "40px",
    bottom: 0,
    height: "max-content",
    marginTop: "auto",
    marginBottom: "auto",
    width: "600px",
    padding: "30px 90px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "92px",
  },

  carChildernContainer: {
    margin: "10px 0px",
    maxHeight: "600px",
    overflowX: "auto",
  },

  backButtonLogin: {
    position: "absolute",
    left: 50,
    top: 40,
    cursor: "pointer",
  },

  // navbar
  navbarContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "70px",
  },
  backButton: {
    height: "30px",
  },
  navbarRightPart: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  proIconNavbar: {
    height: "24px",
    marginRight: "5px",
  },
  univisionPro: {
    color: "white",
    background: "orange",
    borderRadius: "5px",
    fontSize: "12px",
    height: "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
  },
  currencyConverter: {
    margin: "5px",
    padding: "2px",
    borderRadius: "10px",
  },
  currencyHover: {
    position: "absolute",
    zIndex: "100",
    backgroundColor: Colors.Primary,
    color: "white",
    fontSize: "12px",
    height: "50px",
    width: "250px",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px",
    marginTop: "5px",
    left: "-100px",
    animation: "slide-down 0.3s ease",
  },
  currencyHoverText: {
    fontWeight: "500",
    fontSize: "12px",
  },
  notificationBell: {
    color: Colors.Primary,
    cursor: "pointer",
    fontSize: "30px",
    margin: "5px 10px",
  },
  profileImage: {
    height: "35px",
    width: "35px",
    margin: "5px 10px",
    borderRadius: "100%",
    cursor: "pointer",
    background: "white",
    border: `1px solid ${Colors.Primary}`,
  },
  defaultImage: {
    height: "35px",
    width: "35px",
    margin: "5px 10px",
    borderRadius: "100%",
    color: "white",
    background: Colors.Primary,
    cursor: "pointer",
    padding: "8px",
  },
  profileContainer: {
    margin: "5px 10px",
  },
  profileName: {
    fontSize: "18px",
    fontWeight: "400",
    color: Colors.Primary,
    textTransform: "capitalize",
    height: "35px",
    display: "flex",
    justifyContent: "left",
    paddingTop: "15px",
    alignItems: "center",
  },
  profileRole: {
    fontSize: "14px",
    fontWeight: "400",
    color: Colors.GrayText,
    textTransform: "capitalize",
    height: "35px",
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    paddingBottom: "15px",
  },

  // profile dropdown
  dropdownContainer: {
    background: Colors.Primary,
    borderRadius: "10px",
    color: "white",
    width: "140px",
    marginTop: "15px",
  },
  dropdownDivider: {
    width: "80%",
    margin: "auto",
    height: "1px",
    color: "white",
    backgroundColor: "white",
  },

  // notification dropdown
  notificationTitle: {
    fontSize: "16px",
    fontWeight: "700",
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
  },
  notificationBodyIcon: {
    color: Colors.Primary,
    fontSize: "24px",
  },
  notificationBody: {
    fontSize: "12px",
    textAlign: "left",
    marginLeft: "20px",
  },
  allNotificationsContainer: {
    textAlign: "center",
    padding: "10px",
  },

  // sidebar
  sidebarLogoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "64px",
    margin: "25px 0 40px 0",
  },
  sidebarImage: {
    height: "130px",
    width: "150px",
    maxWidth: "200px",
  },

  sidebarIcon: {
    color: "#fff",
    fontSize: "24px",
    marginRight: "15px",
  },
  sidebarIcon2: {
    height: "24px",
    marginRight: "15px",
  },
  proIconSidebar: {
    height: "18px",
    position: "absolute",
    left: "2px",
  },
  sidebarItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "25px",
    width: "200px",
    cursor: "pointer",
  },

  // dashboard
  bottomTextContainer: {
    color: "black",
    fontSize: "7px",
    fontWeight: "400",
    textAlign: "right",
    paddingRight: "50px",
    marginBottom: "5px",
    position: "relative",
  },
};

export default styles;
