import { Colors } from "../../config";

const styles = {
  loginFormContainer: {
    width: "100%",
    margin: "0 auto",
  },
  inputFieldBorder: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #DFDFDD",
    borderRadius: "100px",
    padding: "14px",
    width: "100%",
    backgroundColor: "#F7F6F2",
    outline: "none",
  },
  passwordContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    position: "relative",
  },
  passwordIcon: {
    fontSize: "20px",
    cursor: "pointer",
    color: Colors.Primary,
  },
  forgotPassword: {
    textAlign: "right",
  },
  forgotPasswordText: {
    textDecoration: "none",
    color: Colors.BlackText,
    fontSize: "14px",
  },
  buttonStyle: {
    width: "100%",
    backgroundColor: Colors.Primary,
    borderRadius: "100px",
    fontWeight: "600",
  },
};

export default styles;
