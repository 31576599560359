import {
  CHECK_EMAIL,
  CHECK_EMAIL_FAILURE,
  CHECK_EMAIL_SUCCESS,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_SUCCESS,
  LOADER_FALSE,
  LOADER_TRUE,
  LOGIN,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT,
  LOGOUT_FAILURE,
  LOGOUT_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
  SIGNUP,
  SIGNUP_FAILURE,
  SIGNUP_SUCCESS,
  VERIFY_OTP,
  VERIFY_OTP_FAILURE,
  VERIFY_OTP_SUCCESS,
  VERIFY_SIGNUP_OTP,
  VERIFY_SIGNUP_OTP_FAILURE,
  VERIFY_SIGNUP_OTP_SUCCESS,
} from '../constants';

export default class AuthAction {
  static SignIn(payload, cb) {
    return {
      type: LOGIN,
      payload,
      cb,
    };
  }
  static SignInSuccess(payload) {
    return {
      type: LOGIN_SUCCESS,
      payload,
    };
  }
  static SignInFailure() {
    return {
      type: LOGIN_FAILURE,
    };
  }
  static Signup(payload, cb) {
    return {
      type: SIGNUP,
      payload,
      cb,
    };
  }
  static SignupSuccess(payload) {
    return {
      type: SIGNUP_SUCCESS,
      payload,
    };
  }
  static SignupFailure() {
    return {
      type: SIGNUP_FAILURE,
    };
  }
  static Logout(cb) {
    return {
      type: LOGOUT,
      cb,
    };
  }
  static LogoutSuccess() {
    return {
      type: LOGOUT_SUCCESS,
    };
  }
  static LogoutFailure() {
    return {
      type: LOGOUT_FAILURE,
    };
  }
  static ForgotPassword(payload, cb) {
    return {
      type: FORGOT_PASSWORD,
      payload,
      cb,
    };
  }
  static ForgotPasswordSuccess(payload) {
    return {
      type: FORGOT_PASSWORD_SUCCESS,
      payload,
    };
  }
  static ForgotPasswordFailure() {
    return {
      type: FORGOT_PASSWORD_FAILURE,
    };
  }
  static VerifyOtp(payload, cb) {
    return {
      type: VERIFY_OTP,
      payload,
      cb,
    };
  }
  static VerifyOtpSuccess(payload) {
    return {
      type: VERIFY_OTP_SUCCESS,
      payload,
    };
  }
  static VerifyOtpFailure() {
    return {
      type: VERIFY_OTP_FAILURE,
    };
  }
  static ResetPassword(payload, cb) {
    return {
      type: RESET_PASSWORD,
      payload,
      cb,
    };
  }
  static ResetPasswordSuccess(payload) {
    return {
      type: RESET_PASSWORD_SUCCESS,
      payload,
    };
  }
  static ResetPasswordFailure() {
    return {
      type: RESET_PASSWORD_FAILURE,
    };
  }
  static CheckEmail(payload, cb) {
    return {
      type: CHECK_EMAIL,
      payload,
      cb,
    };
  }
  static CheckEmailSuccess(payload) {
    return {
      type: CHECK_EMAIL_SUCCESS,
      payload,
    };
  }
  static CheckEmailFailure() {
    return {
      type: CHECK_EMAIL_FAILURE,
    };
  }
  static VerifySignupOtp(payload, cb) {
    return {
      type: VERIFY_SIGNUP_OTP,
      payload,
      cb,
    };
  }
  static VerifySignupOtpSuccess(payload) {
    return {
      type: VERIFY_SIGNUP_OTP_SUCCESS,
      payload,
    };
  }
  static VerifySignupOtpFailure() {
    return {
      type: VERIFY_SIGNUP_OTP_FAILURE,
    };
  }
  static LoaderTrue() {
    return {
      type: LOADER_TRUE,
    };
  }
  static LoaderFalse() {
    return {
      type: LOADER_FALSE,
    };
  }
}
