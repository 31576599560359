import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { AiOutlineCloudDownload } from "react-icons/ai";

import { BillingAction } from "../../store/actions";

import { Layout, Table, Text } from "../../components";
import { Colors } from "../../config";

function Invoices() {
  const location = useLocation();
  const dispatch = useDispatch();

  const id = location.pathname.split("/")[4];

  const billingState = useSelector((state) => state.billing);
  const invoices = useSelector((state) => state.billing?.invoices?.invoiceList);

  useEffect(() => {
    dispatch(BillingAction.getInvoices({ subId: id }));
  }, []);

  const data = [
    {
      title: "Invoice ID",
      dataIndex: "id",
      align: "center",
      width: 250,
      render: (text, record) => <span>{record?.id}</span>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      align: "center",
      width: 200,
      render: (text, record) => <span>USD {record?.amount}</span>,
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      align: "center",
      width: 200,
      render: (text, record) => (
        <span style={{ textTransform: "uppercase" }}>{record?.status}</span>
      ),
    },
    {
      title: "Last Paid",
      dataIndex: "lastpaid",
      align: "center",
      width: 200,
      render: (text, record) => <span>{record?.paidAtReadable}</span>,
    },
    {
      title: "PDF",
      dataIndex: "pdf",
      align: "center",
      width: 100,
      render: (text, record) => (
        <div style={{ cursor: "pointer" }}>
          <AiOutlineCloudDownload
            fontSize="25px"
            color={Colors.Primary}
            cursor={"pointer"}
            onClick={() => {
              const url = record?.pdf;
              window.open(url, "_blank");
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <Text.Heading
        fontSize={"18px"}
        fontWeight={"600"}
        color={Colors.Primary}
        text="Payments"
      />

      <Layout.Spacer height={20} />
      <Table.AntDesign
        rowKey={(record) => record._id}
        columns={data}
        data={invoices}
        loading={billingState?.isLoading}
        pagination={false}
      />
      <Layout.Spacer height={20} />
    </div>
  );
}

export default Invoices;
