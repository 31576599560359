import { Colors } from "../../config";

const PrimaryButton = (props) => {
  const style = {
    ...props.style,
    backgroundColor: props.backgroundColor
      ? props.backgroundColor
      : Colors.Primary,
    color: props.color ? props.color : "white",
    fontWeight: "400",
    fontSize: "16px",
    border: "none",
    minHeight: "45px",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  };
  return (
    <button
      className="btn btn-primary"
      {...props}
      style={{ ...style, ...props.style }}
    >
      {props.children}
    </button>
  );
};

export default PrimaryButton;
