import {
  ADD_SCHOOL_STUDENT,
  ADD_SCHOOL_STUDENT_FAILURE,
  ADD_SCHOOL_STUDENT_SUCCESS,
  ADD_SCHOOL_STUDENT_SHEET,
  ADD_SCHOOL_STUDENT_SHEET_FAILURE,
  ADD_SCHOOL_STUDENT_SHEET_SUCCESS,
  GET_ACTIVE_STUDENTS,
  GET_ACTIVE_STUDENTS_FAILURE,
  GET_ACTIVE_STUDENTS_SUCCESS,
  GET_REGISTERED_STUDENTS,
  GET_REGISTERED_STUDENTS_FAILURE,
  GET_REGISTERED_STUDENTS_SUCCESS,
  GET_REGISTERED_STUDENT_DETAILS,
  GET_REGISTERED_STUDENT_DETAILS_FAILURE,
  GET_REGISTERED_STUDENT_DETAILS_SUCCESS,
  GET_SCHOOL_ACTIVE_STUDENTS,
  GET_SCHOOL_ACTIVE_STUDENTS_FAILURE,
  GET_SCHOOL_ACTIVE_STUDENTS_SUCCESS,
  GET_STUDENTS_DETAILS,
  GET_STUDENTS_DETAILS_FAILURE,
  GET_STUDENTS_DETAILS_SUCCESS,
  GET_STUDENT_DETAILS,
  GET_STUDENT_DETAILS_FAILURE,
  GET_STUDENT_DETAILS_SUCCESS,
  GET_STUDENT_MEETING_DETAILS,
  GET_STUDENT_MEETING_DETAILS_FAILURE,
  GET_STUDENT_MEETING_DETAILS_SUCCESS,
  GET_STUDENT_ALL_MEETING_DETAILS,
  GET_STUDENT_ALL_MEETING_DETAILS_SUCCESS,
  GET_STUDENT_ALL_MEETING_DETAILS_FAILURE,
  SEND_STUDENT_EMAIL,
  SEND_STUDENT_EMAIL_FAILURE,
  SEND_STUDENT_EMAIL_SUCCESS,
} from "../constants";

const initialState = {
  count: 0,
  totalStudents: 0,
  activeStudents: [],
  registeredStudents: [],
  studentsDetails: [],
  schoolStudents: [],
  student: {},
  studentMeetings: [],
  studentMeetingsAll: [],
  validity: {},
  isLoading: true,
  error: null,
};

export default function StudentReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ACTIVE_STUDENTS:
    case GET_REGISTERED_STUDENTS:
    case GET_STUDENT_DETAILS:
    case SEND_STUDENT_EMAIL:
    case GET_STUDENTS_DETAILS:
    case ADD_SCHOOL_STUDENT:
    case ADD_SCHOOL_STUDENT_SHEET:
    case GET_REGISTERED_STUDENT_DETAILS:
    case GET_STUDENT_MEETING_DETAILS:
    case GET_STUDENT_ALL_MEETING_DETAILS:
    case GET_SCHOOL_ACTIVE_STUDENTS:
      state = {
        count: 0,
        activeStudents: [],
        registeredStudents: [],
        studentsDetails: [],
        studentMeetings: [],
        studentMeetingsAll: [],
        student: {},
        isLoading: true,
        error: null,
      };
      break;
    case GET_ACTIVE_STUDENTS_SUCCESS:
      state = {
        ...state,
        student: {},
        count: action.payload.count,
        activeStudents: action.payload.data,
        totalStudents: action.payload.totalStudents,
        isLoading: false,
      };
      break;
    case GET_SCHOOL_ACTIVE_STUDENTS_SUCCESS:
      state = {
        ...state,
        schoolStudents: action.payload.data,
        isLoading: false,
      };
      break;
    case GET_REGISTERED_STUDENTS_SUCCESS:
      state = {
        ...state,
        student: {},
        count: action.payload.count,
        registeredStudents: action.payload.data,
        isLoading: false,
      };
      break;
    case GET_STUDENTS_DETAILS_SUCCESS:
      state = {
        ...state,
        studentsDetails: action.payload,
        isLoading: false,
      };
      break;
    case GET_STUDENT_DETAILS_SUCCESS:
      state = {
        ...state,
        student: {
          ...action.payload.studentDetails,
          preferredProgram: action.payload.preferredProgram,
          validity: action.payload.validity,
        },
        isLoading: false,
      };
      break;
    case GET_STUDENT_MEETING_DETAILS_SUCCESS:
      state = {
        ...state,
        studentMeetings: action.payload,
        isLoading: false,
      };
      break;
    case GET_STUDENT_ALL_MEETING_DETAILS_SUCCESS:
      state = {
        ...state,
        studentMeetingsAll: action.payload,
        isLoading: false,
      };
      break;
    case GET_REGISTERED_STUDENT_DETAILS_SUCCESS:
      state = {
        ...state,
        student: action.payload,
        isLoading: false,
      };
      break;
    case SEND_STUDENT_EMAIL_SUCCESS:
    case ADD_SCHOOL_STUDENT_SUCCESS:
    case ADD_SCHOOL_STUDENT_SHEET_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case GET_ACTIVE_STUDENTS_FAILURE:
    case GET_REGISTERED_STUDENTS_FAILURE:
    case GET_STUDENT_DETAILS_FAILURE:
    case SEND_STUDENT_EMAIL_FAILURE:
    case GET_STUDENTS_DETAILS_FAILURE:
    case ADD_SCHOOL_STUDENT_FAILURE:
    case ADD_SCHOOL_STUDENT_SHEET_FAILURE:
    case GET_REGISTERED_STUDENT_DETAILS_FAILURE:
    case GET_STUDENT_MEETING_DETAILS_FAILURE:
    case GET_STUDENT_ALL_MEETING_DETAILS_FAILURE:
    case GET_SCHOOL_ACTIVE_STUDENTS_FAILURE:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    default:
      break;
  }
  return state;
}
