import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "antd";

import { IoMdCloseCircle } from "react-icons/io";

import { Colors, Images } from "../../config";

import { Layout, Table, Text } from "../../components";
import { StaffAction } from "../../store/actions";

import styled from "styled-components";
import styles from "./styles";

function StaffRoles() {
  const dispatch = useDispatch();

  const staff = useSelector((state) => state.staff);
  const schoolIdState = useSelector((state) => state.auth.user.user.schoolId);

  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const applicationsData = [
    {
      name: "Admins",
      value: 1,
    },
    {
      name: "Staff members",
      value: staff.staffs.length,
    },
  ];

  useEffect(() => {
    const payload = {
      limit,
      offset,
      schoolId: schoolIdState,
    };
    dispatch(StaffAction.getStaff(payload));
  }, []);

  const handlePaginationChange = (pageNumber) => {
    let offset = (pageNumber - 1) * limit;
    setOffset(offset);
    setPage(pageNumber);
    const payload = {
      limit,
      offset: offset,
      schoolId: schoolIdState,
    };
    dispatch(StaffAction.getStaff(payload));
    setPage(1);
  };

  const handleDeleteStaff = (id) => {
    dispatch(
      StaffAction.removeStaff(id, () => {
        const payload = {
          limit,
          offset: 0,
          schoolId: schoolIdState,
        };
        dispatch(StaffAction.getStaff(payload));
        setPage(1);
      })
    );
  };

  return (
    <>
      <Layout.Spacer height={5} />
      <Text.Heading
        fontSize={"18px"}
        fontWeight={"600"}
        color={Colors.Primary}
        text="Staff Information"
      />
      <Layout.Spacer height={0} />
      <Text.Heading
        fontSize={"14px"}
        fontWeight={"500"}
        color={Colors.Gray}
        text="Here you can view the school staff and change their account roles"
      />
      <Layout.Spacer height={20} />
      <div style={styles.flexRowCenter}>
        {applicationsData.map((item) => (
          <div style={styles.cardDiv}>
            <Text.Heading
              fontSize="40px"
              color={Colors.Primary}
              fontWeight={"500"}
              text={item.value}
            />
            <Text.Heading
              fontSize={"16px"}
              fontWeight={"500"}
              color={Colors.GrayText}
              text={item.name}
            />
          </div>
        ))}
      </div>

      <Layout.Spacer height={20} />
      <Text.Heading
        fontSize={"18px"}
        fontWeight={"600"}
        color={Colors.Primary}
        text="Staff Members"
      />
      <Layout.Spacer height={10} />
      <Table.AntDesign
        rowKey={(record) => record._id}
        columns={[
          {
            title: "Name",
            dataIndex: "name",
            width: 250,
            render: (text, record) => (
              <ColumnDiv>
                <img
                  src={record?.imageUrl ? record?.imageUrl : Images.DummyUser}
                  alt="img"
                />
                <span>
                  <Text.Heading
                    fontSize={"14px"}
                    fontWeight={"500"}
                    color={Colors.BlackText}
                    textAlign="left"
                    text={record?.name}
                  />
                  <Text.Heading
                    fontSize={"12px"}
                    fontWeight={"400"}
                    color={Colors.GrayText}
                    textAlign="left"
                    text={record?.position}
                  />
                </span>
              </ColumnDiv>
            ),
          },
          {
            title: "Account Type",
            dataIndex: "accountType",
            width: 200,
            render: (text, record) => (
              <span style={styles.tableText}>
                {record?.position === "admin"
                  ? "Staff Admin"
                  : record?.position}
              </span>
            ),
          },
          {
            title: "Joined",
            dataIndex: "joinedAt",
            width: 200,
            render: (text, record) => (
              <span style={styles.tableText}>
                {record?.createdAt
                  ? record?.createdAt
                  : new Date().toLocaleDateString()}
              </span>
            ),
          },
          {
            title: "Email",
            dataIndex: "email",
            width: 300,
            render: (text, record) => (
              <span style={styles.flexRowSpace}>
                <span style={{ ...styles.tableText, textTransform: "none" }}>
                  {text}
                </span>
                <span style={styles.iconRowDiv}>
                  <IoMdCloseCircle
                    fontSize={"30px"}
                    cursor={"pointer"}
                    color={"#FF494C"}
                    onClick={() => handleDeleteStaff(record?._id)}
                  />
                </span>
              </span>
            ),
          },
        ]}
        data={staff.staffs}
        loading={staff.isLoading}
        pagination={false}
      />
      <Layout.Spacer height={20} />
      <div style={styles.flexRowCenter}>
        <Pagination
          responsive="true"
          showSizeChanger={false}
          hideOnSinglePage={false}
          current={page}
          pageSize={10}
          total={staff.count}
          onChange={handlePaginationChange}
        />
      </div>
    </>
  );
}

export default StaffRoles;

const ColumnDiv = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  img {
    width: 40px;
    height: 40px;
    margin-right: 20px;
    border-radius: 100%;
    object-fit: contain;
    background: white;
  }
  span {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    span:first-of-type {
      font-size: 12px;
      font-weight: 600;
      color: ${Colors.BlackText};
    }
    span:last-of-type {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 10px;
      font-weight: 400;
      color: ${Colors.BlackText};
    }
  }
`;
