import { put } from "redux-saga/effects";

import { ApiCaller } from "../../config";
import { StaffAction } from "../actions";

export default class StaffMiddleware {
  static *getStaff({ payload, cb }) {
    try {
      const { limit, offset, schoolId } = payload;
      const res = yield ApiCaller.Get(
        `/school/get-staff?limit=${limit}&offset=${offset}&schoolId=${schoolId}`
      );
      if (res.status === 200) {
        yield put(StaffAction.getStaffSuccess(res.data.data));
        if (cb) {
          cb();
        }
      } else {
        yield put(StaffAction.getStaffSuccess([]));
        // Utils.showSnackBar({
        //     message: res.data.message,
        //     severity: 'error',
        //   });
      }
    } catch (err) {
      yield put(StaffAction.getStaffFailure());
    }
  }

  static *addStaff({ payload, cb }) {
    try {
      let res = yield ApiCaller.Post("/school/add-staff", payload);
      if (res.status === 200) {
        yield put(StaffAction.addStaffSuccess(res.data.data));
        if (cb) {
          cb(res);
        }
      } else {
        yield put(StaffAction.addStaffFailure());
        if (cb) {
          cb(res);
        }
        // Utils.showSnackBar({
        //   message: res.data.message,
        //   severity: 'error',
        // });
      }
    } catch (err) {
      yield put(StaffAction.addStaffFailure());
    }
  }
  static *changeRole({ id, payload, cb }) {
    try {
      let res = yield ApiCaller.Post(
        `/miscellaneous/change-roles?id=${id}`,
        payload
      );
      if (res.status === 200) {
        yield put(StaffAction.changeRoleSuccess(res.data.data));
        if (cb) {
          cb();
        }
      } else {
        yield put(StaffAction.changeRoleFailure());
        // Utils.showSnackBar({
        //   message: res.data.message,
        //   severity: 'error',
        // });
      }
    } catch (err) {
      yield put(StaffAction.changeRoleFailure());
    }
  }
  static *removeStaff({ id, cb }) {
    try {
      const res = yield ApiCaller.Get(`/school/remove-staff/${id}`);
      if (res.status === 200) {
        yield put(StaffAction.removeStaffSuccess(res.data.data));
        if (cb) {
          cb();
        }
      } else {
        yield put(StaffAction.removeStaffFailure());
        // Utils.showSnackBar({
        //     message: res.data.message,
        //     severity: 'error',
        //   });
      }
    } catch (err) {
      yield put(StaffAction.removeStaffFailure());
    }
  }

  static *getCounselorInfo({ email, cb }) {
    try {
      const res = yield ApiCaller.Get(
        `/auth/get-counselor-info?email=${email}`
      );
      if (res.status === 200) {
        yield put(StaffAction.getCounselorInfoSuccess(res.data));
        if (cb) {
          cb();
        }
      } else {
        yield put(StaffAction.getCounselorInfoFailure());
        // Utils.showSnackBar({
        //     message: res.data.message,
        //     severity: 'error',
        //   });
      }
    } catch (err) {
      yield put(StaffAction.getCounselorInfoFailure());
    }
  }
}
