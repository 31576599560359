import {
  ADD_NOTIFICATIONS,
  ADD_NOTIFICATIONS_FAILURE,
  ADD_NOTIFICATIONS_SUCCESS,
  CHANGE_NOTIFICATIONS_STATUS,
  CHANGE_NOTIFICATIONS_STATUS_FAILURE,
  CHANGE_NOTIFICATIONS_STATUS_SUCCESS,
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_FAILURE,
  GET_NOTIFICATIONS_SUCCESS,
} from "../constants";

export default class NotificationsAction {
  static addNotifications(payload, cb) {
    return {
      type: ADD_NOTIFICATIONS,
      payload,
      cb,
    };
  }
  static addNotificationsSuccess(payload) {
    return {
      type: ADD_NOTIFICATIONS_SUCCESS,
      payload,
    };
  }
  static addNotificationsFailure(payload) {
    return {
      type: ADD_NOTIFICATIONS_FAILURE,
      payload,
    };
  }
  static getNotifications(payload, cb) {
    return {
      type: GET_NOTIFICATIONS,
      payload,
      cb,
    };
  }
  static getNotificationsSuccess(payload) {
    return {
      type: GET_NOTIFICATIONS_SUCCESS,
      payload,
    };
  }
  static getNotificationsFailure(payload) {
    return {
      type: GET_NOTIFICATIONS_FAILURE,
      payload,
    };
  }
  static changeNotificationsStatus(payload, cb) {
    return {
      type: CHANGE_NOTIFICATIONS_STATUS,
      payload,
      cb,
    };
  }
  static changeNotificationsStatusSuccess(payload) {
    return {
      type: CHANGE_NOTIFICATIONS_STATUS_SUCCESS,
      payload,
    };
  }
  static changeNotificationsStatusFailure(payload) {
    return {
      type: CHANGE_NOTIFICATIONS_STATUS_FAILURE,
      payload,
    };
  }
}
