import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Form } from "react-bootstrap";

import { AuthAction } from "../../store/actions";

import { Button, Layout } from "../../components";
import { Colors, Utils } from "../../config";

import FormLink from "./FormLink";

import styles from "./styles";

function EmailForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [isAdminTab, setIsAdminTab] = useState(
    location?.state?.site === "loginPage" ? false : true
  );
  const [state, setState] = useState({
    email: "",
    error: "",
  });

  const handleEmailChange = (e) => {
    setState({
      ...state,
      email: e.target.value,
    });
  };
  const handleNext = () => {
    if (state.email === "") {
      setState({
        ...state,
        error: "Email can not be left empty",
      });
    } else if (Utils.emailRegex.test(state.email) === false) {
      setState({
        ...state,
        error: "Please enter a valid email address",
      });
    } else {
      setState({
        ...state,
        error: "",
      });
      const payload = {
        email: state.email,
        role: isAdminTab ? "School" : "Counselor",
      };
      dispatch(
        AuthAction.CheckEmail(payload, (res) => {
          if (res.data.status === 200) {
            navigate("/signup/verify-otp", {
              state: {
                email: state.email,
                role: isAdminTab ? "School" : "Counselor",
              },
            });
          }
        })
      );
    }
  };

  return (
    <Layout.LoginLayout backLink={"/login"}>
      <Layout.HeaderSection
        title="Sign Up"
        subtitle="Enter email to get an OTP."
      />
      <Layout.Spacer height={25} />
      <div>
        <Button.TabButton
          isActive={isAdminTab}
          onClick={() => {
            setIsAdminTab(true);
            setState({
              email: "",
              error: "",
            });
          }}
          text="Admin"
        />
        <Button.TabButton
          isActive={!isAdminTab}
          onClick={() => {
            setIsAdminTab(false);
            setState({
              email: "",
              error: "",
            });
          }}
          text="Staff"
          position="right"
        />

        <Layout.Spacer height={40} />
        <Form.Group>
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type="email"
            placeholder="xyz@emailprovider.com"
            value={state.email}
            onChange={handleEmailChange}
          />
          {state.error && (
            <Form.Text className="text-danger">{state.error}</Form.Text>
          )}
        </Form.Group>
        <Layout.Spacer height={35} />

        <Button.Primary style={styles.buttonStyle} onClick={handleNext}>
          Continue
        </Button.Primary>

        <Layout.Spacer height={30} />
        {isAdminTab && <FormLink />}

        <Layout.Spacer height={30} />

        <div style={{ textAlign: "center" }}>
          <span style={{ color: Colors.LabelText, fontSize: "14px" }}>
            Already have an account?
          </span>
          <Link to="/login" style={styles.signinText}>
            Sign In
          </Link>
        </div>
      </div>
    </Layout.LoginLayout>
  );
}

export default EmailForm;
