import { put } from "redux-saga/effects";

import { BillingAction } from "../actions";
import { ApiCaller } from "../../config";

export default class BillingMiddleware {
  static *addBilling({ payload, cb }) {
    try {
      let res = yield ApiCaller.Post(`/billing/addBilling`, payload);

      if (res.status === 201) {
        yield put(BillingAction.addBillingSuccess(res.data.data));

        if (cb) {
          cb({ data: res.data.data, status: res.status });
        }
      } else {
        yield put(BillingAction.addBillingFailure());
        if (cb) {
          cb({ data: res.data, status: res.status });
        }
        // Utils.showSnackBar({
        //   message: res.data.message,
        //   severity: 'error',
        // });
      }
    } catch (err) {
      yield put(BillingAction.addBillingFailure());
    }
  }

  static *getBilling({ payload, cb }) {
    try {
      let res;

      res = yield ApiCaller.Post(`/billing/getSpecificBilling`, payload);

      if (res.status === 200) {
        yield put(BillingAction.getBillingsSuccess(res.data.data));
        if (cb) {
          cb();
        }
      } else {
        yield put(BillingAction.getBillingsSuccess([]));
        // Utils.showSnackBar({
        //     message: res.data.message,
        //     severity: 'error',
        //   });
      }
    } catch (err) {
      yield put(BillingAction.getBillingFailure());
    }
  }
  static *getInvoices({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(`/billing/getInvoices`, payload);

      if (res.status === 200) {
        yield put(BillingAction.getInvoicesSuccess(res.data.data));
      } else {
        yield put(BillingAction.getInvoicesFailure());
      }
      if (cb) {
        cb();
      }
    } catch (err) {
      yield put(BillingAction.getInvoicesFailure());
    }
  }
  static *updateBilling({ payload, cb }) {
    try {
      let res = yield ApiCaller.Post(`/billing/updateBilling`, payload);
      if (res.status === 200) {
        yield put(BillingAction.updateBillingSuccess(res.data.data));
        if (cb) {
          cb(res.data.data);
        }
      } else {
        yield put(BillingAction.updateBillingFailure());
        // Utils.showSnackBar({
        //   message: res.data.message,
        //   severity: 'error',
        // });
      }
    } catch (err) {
      yield put(BillingAction.updateBillingFailure());
    }
  }
}
