import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "antd";

import { FaSearch } from "react-icons/fa";
import { AiFillRightCircle } from "react-icons/ai";
import { MdLocationPin } from "react-icons/md";

import { UniversityAction } from "../../store/actions";

import {
  Layout,
  Table,
  Text,
  TextField,
  convertStringToUSD,
} from "../../components";
import { Colors, Images } from "../../config";

import styled from "styled-components";
import styles from "./styles";

function Universities() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currency = useSelector((state) => state.converter.usd);
  const userId = useSelector((state) => state.auth.user.user._id);
  const universityState = useSelector((state) => state.university);

  const [search, setSearch] = useState("");

  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("qsWorldRanking");
  const [filterRegions, setFilterRegions] = useState([
    {
      name: "All",
      selected: true,
    },
    {
      name: "Asia",
      value: "asia",
      selected: false,
    },
    {
      name: "North America",
      value: "north america",
      selected: false,
    },
    {
      name: "Australia",
      value: "australia",
      selected: false,
    },
    {
      name: "Europe",
      value: "europe",
      selected: false,
    },
    {
      name: "Africa",
      value: "africa",
      selected: false,
    },

    {
      name: "South America",
      value: "south america",
      selected: false,
    },
  ]);

  useEffect(() => {
    const allRegions = filterRegions.filter((item) => item.selected === true);
    const payload = {
      search,
      region: allRegions[0].name === "All" ? "" : allRegions[0].value,
      limit,
      offset: 0,
      filter: selectedValue,
      userId,
    };
    dispatch(UniversityAction.getUniversities(payload));

    setPage(1);
  }, [filterRegions, selectedValue, search]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleFilterStatus = (status) => {
    const newStatusFilters = filterRegions.map((filter) => {
      if (filter.name === status.name) {
        return {
          ...filter,
          selected: !filter.selected,
        };
      } else {
        return {
          ...filter,
          selected: false,
        };
      }
    });
    setFilterRegions(newStatusFilters);
  };

  const handlePaginationChange = (pageNumber) => {
    const allRegions = filterRegions.filter((item) => item.selected === true);
    let offset = (pageNumber - 1) * limit;

    const payload = {
      search: search,
      region: allRegions[0].name === "All" ? "" : allRegions[0].value,
      limit: limit,
      offset: offset,
      filter: selectedValue,
      userId,
    };
    dispatch(UniversityAction.getUniversities(payload));

    setOffset(offset);
    setPage(pageNumber);
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <>
      <div>
        <div style={{ ...styles.flexRow, width: "100%" }}>
          <TextField.CustomInput
            placeholder="Search Universities"
            suffix={<FaSearch color={Colors.Primary} />}
            style={styles.customTextfieldStyle}
            onChange={handleChange}
          />
          <div
            style={{
              marginLeft: "10px",
              position: "relative",
            }}
          >
            <div style={styles.filterButton} onClick={toggleDropdown}>
              <img src={Images.Sort} style={{ width: "100%" }} />
            </div>
            {isOpen && (
              <div style={styles.filterDropdown}>
                <div
                  style={{
                    ...styles.filterDropdownValue,
                    fontWeight:
                      selectedValue === "qsWorldRanking" ? "700" : "400",
                  }}
                  onClick={() => {
                    setSelectedValue("qsWorldRanking");
                    toggleDropdown();
                  }}
                >
                  QS World Ranking
                </div>

                <div
                  style={{
                    cursor: "pointer",
                    fontWeight: selectedValue === "name" ? "700" : "400",
                  }}
                  onClick={() => {
                    setSelectedValue("name");
                    toggleDropdown();
                  }}
                >
                  Alphabetical
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Layout.Spacer height={20} />
      <div style={styles.flexRow}>
        <div>
          <Text.Heading
            fontSize={"16px"}
            fontWeight={"500"}
            color={Colors.Primary}
            letterSpacing="1px"
            marginRight="30px"
            style={{ marginRight: "30px", letterSpacing: "1px" }}
            textAlign="right"
            text="Filter by Region"
          />
        </div>
        <div style={styles.flexRow}>
          {filterRegions.map((region) => (
            <div
              style={{
                ...styles.filterRegion,
                backgroundColor: region?.selected
                  ? Colors.Primary
                  : Colors.White,
                color: region?.selected ? Colors.White : Colors.BlackText2,
                border: region?.selected ? "" : "1px solid #387f768c",
              }}
              onClick={() => handleFilterStatus(region)}
              key={region?.name}
            >
              {region?.name}
            </div>
          ))}
        </div>
      </div>
      <Layout.Spacer height={15} />

      <Text.Heading
        marginRight="10px"
        fontSize={"18px"}
        fontWeight={"600"}
        color={Colors.Primary}
        text="Universities"
      />

      <Layout.Spacer height={20} />
      <Table.AntDesign
        rowKey={(record) => record._id}
        columns={[
          {
            title: "Name",
            dataIndex: "name",
            width: 450,
            onCell: (record) => ({
              onClick: () => navigate(`/universities/${record._id}/general`),
            }),
            render: (text, record) => (
              <ColumnDiv>
                <img src={record?.profilePic} alt="img" />
                <span>
                  <Text.Heading
                    fontSize="12px"
                    fontWeight="600"
                    color="#081C15"
                    textTransform="capitalize"
                    textAlign="left"
                    text={record.name}
                  />
                  <span>
                    <MdLocationPin
                      color={Colors.GrayText}
                      style={{ marginRight: "5px" }}
                    />
                    <Text.Heading
                      fontSize="10px"
                      fontWeight="400"
                      color={Colors.GrayText}
                      textTransform="capitalize"
                      textAlign="left"
                      text={record.location}
                    />
                  </span>
                </span>
              </ColumnDiv>
            ),
          },
          {
            title: "QS World Ranking",
            dataIndex: "QSWorldRanking",
            align: "center",
            width: 200,
            onCell: (record) => ({
              onClick: () => navigate(`/universities/${record._id}/general`),
            }),
            render: (text, record) => (
              <div style={styles.universityTableText}>
                {record?.summary?.qsWorldRanking}
              </div>
            ),
          },
          {
            title: !currency ? "Average Fee" : "Average Fee (USD)",
            dataIndex: "averageFee",
            align: "center",
            width: 350,
            onCell: (record) => ({
              onClick: () => navigate(`/universities/${record._id}/general`),
            }),
            render: (text, record) => {
              if (!currency) {
                return (
                  <div style={styles.universityTableText}>
                    {record?.summary?.fee_structure}
                  </div>
                );
              } else {
                const converted = convertStringToUSD(
                  record?.summary?.fee_structure
                );
                return (
                  <div style={styles.universityTableText}>{converted}</div>
                );
              }
            },
          },
          {
            title: " ",
            dataIndex: "action",
            width: 150,
            render: (text, record) => (
              <div style={styles.universityActions}>
                <AiFillRightCircle
                  fontSize={"30px"}
                  cursor={"pointer"}
                  color={Colors.Primary}
                  onClick={() =>
                    navigate(`/universities/${record._id}/general`)
                  }
                />
              </div>
            ),
          },
        ]}
        data={universityState.universities}
        loading={universityState.isLoading}
        pagination={false}
      />
      <Layout.Spacer height={20} />
      <div style={styles.universityActions}>
        <Pagination
          responsive="true"
          showSizeChanger={false}
          hideOnSinglePage={true}
          current={page}
          pageSize={10}
          total={universityState.count}
          onChange={handlePaginationChange}
        />
      </div>
    </>
  );
}

export default Universities;

const ColumnDiv = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: contain;
    margin-right: 10px;
    background: white;
  }
  span {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    span:last-of-type {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
`;
