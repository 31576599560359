import { Colors } from "../../config";

const styles = {
  flexRowSpace: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  flexRow: {
    display: "flex",
    flexDirection: "row",
  },

  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  flexColumnCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  justifyCenterItemsCenter: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  mb5: {
    marginBottom: "5px",
  },

  // indeex
  upperSideContainer: { display: "grid", gridTemplateColumns: "3fr 1fr" },
  leftSideData: { display: "flex", alignItems: "center" },
  profileImage: {
    width: "100px",
    height: "100px",
    objectFit: "cover",
    borderRadius: "100%",
    border: `1px solid ${Colors.Primary}`,
  },
  rightSideData: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    background: Colors.LightGray,
    borderRadius: "10px",
    padding: "10px",
    width: "100%",
    overflow: "hidden",
  },

  requestButton: {
    width: "100%",
    fontWeight: "500",
    fontSize: "15px",
    backgroundColor: Colors.Primary,
  },

  // general
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: Colors.LightGray,
    borderRadius: "10px",
    height: "205px",
  },
  cardContainerRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    background: Colors.LightGray,
    width: "100%",
    height: "205px",
    borderRadius: "10px",
  },
  meetingsContainer: { height: "220px", overflowY: "auto" },

  // aplpications
  applicationUpperDataContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    height: "150px",
    borderRadius: "10px",
    width: "50%",
    margin: "auto",
  },
  applicationUpperData: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    background: Colors.LightGray,
    padding: "0px 50px",
    borderRadius: "10px",
    width: "250px",
  },

  // cardRow
  cardHeader: {
    fontSize: "12px",
    fontWeight: "600",
    color: "#081C15",
    textTransform: "capitalize",
    textAlign: "left",
  },
  cardSubHeader: {
    fontSize: "10px",
    fontWeight: "400",
    color: Colors.GrayText,
    textAlign: "left",
  },
  iconDivPrimary: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "4px",
    borderRadius: "50px",
    marginRight: "5px",
    marginTop: "2px",
    backgroundColor: Colors.Primary,
    border: `1px solid ${Colors.Primary}`,
  },
  iconDivSecondary: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "4px",
    borderRadius: "50px",
    marginRight: "5px",
    marginTop: "2px",
    backgroundColor: "transparent",
    border: `1px solid ${Colors.Primary}`,
  },
};

export default styles;
