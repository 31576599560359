import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { AiFillRightCircle } from "react-icons/ai";
import { MdLocationPin } from "react-icons/md";
import { RiRefreshFill } from "react-icons/ri";
import { IoCheckmarkCircleSharp } from "react-icons/io5";

import { MeetingAction, NotificationsAction } from "../../store/actions";

import {
  Button,
  Layout,
  Modal,
  Table,
  Text,
  Loader,
  CustomCalendar,
  dateFormatter,
} from "../../components";
import { Colors } from "../../config";

import Lottie from "react-lottie-player";
import Done from "../../assets/images/57767-done.json";

import styled from "styled-components";
import styles from "./styles";

function Meetings() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const schoolIdState = useSelector((state) => state.auth.user.user.schoolId);
  const meetings = useSelector((state) => state.meeting);
  const loginUser = useSelector((state) => state.auth.user.user);
  const { meetingInvites, meetingSent } = useSelector((state) => state.meeting);
  const meetingsThisMonth = useSelector(
    (state) => state.meeting?.meetingBasedOnDate
  );
  const { meetingDataAll, meetingsToday, meetingsThisWeek, upcomingMeetings } =
    useSelector((state) => state.meeting?.meetingsAll);

  const [successModal, setSuccessModal] = useState(false);

  useEffect(() => {
    fetchMeeting();
  }, []);

  const getDueDate = (date) => {
    const meetingDate = moment(date).format("DD MMMM YYYY");
    const todaysDate = moment(new Date()).format("DD MMMM YYYY");

    if (meetingDate === todaysDate) {
      return <div style={styles.dueText}>Due today</div>;
    }
    if (new Date(meetingDate) < new Date(todaysDate)) {
      const daysDifference = moment(date).fromNow();
      return <div style={styles.overdueText}>Overdue {daysDifference}</div>;
    }
    if (new Date(meetingDate) > new Date(todaysDate)) {
      const daysDifference = moment(date).fromNow();
      return <div style={styles.dueText}>Due {daysDifference}</div>;
    }
  };

  const meetingPayload = {
    organizer: "School",
    schoolId: schoolIdState,
    limit: 10,
    offset: 0,
  };

  const fetchMeeting = () => {
    dispatch(MeetingAction.getAllMeetingsAndCount(meetingPayload));
    dispatch(MeetingAction.getMeetingsBasedOnDate(meetingPayload));
    dispatch(MeetingAction.getMeetingInvitesAndRequests(meetingPayload));
  };

  const acceptMeeting = (record) => {
    dispatch(MeetingAction.changeMeetingStatus(record._id, "Accepted"));
    setSuccessModal(true);

    const payload = {
      limit: "",
      offset: "",
      userId: loginUser._id || "",
    };
    setTimeout(() => {
      dispatch(NotificationsAction.getNotifications(payload));
    }, 1500);
  };

  const statusColor = (record) => {
    if (record?.status?.toLowerCase() === "accepted") return Colors.Success;
    else if (record?.status?.toLowerCase() === "rescheduled")
      return Colors.Blue;
    else if (record?.status?.toLowerCase() === "pending") return Colors.Yellow;
    else return "black";
  };

  const upperBoxes = [
    {
      value: meetingsToday,
      text: "Meetings Today",
    },
    {
      value: meetingsThisWeek,
      text: "Meetings This Week",
    },
    {
      value: upcomingMeetings,
      text: "Upcoming Meetings",
    },
  ];

  const columns = [
    {
      title: "Student Name",
      dataIndex: "studentName",
      width: 350,
      onCell: (record) => ({
        onClick: () =>
          navigate("/meetings/meetingdetails", {
            state: { data: record, sent: "true" },
          }),
      }),
      render: (text, record) => (
        <span style={styles.meetingColumnText}>{record?.studentId?.name}</span>
      ),
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      align: "center",
      width: 350,
      onCell: (record) => ({
        onClick: () =>
          navigate("/meetings/meetingdetails", {
            state: { data: record, sent: "true" },
          }),
      }),
      render: (text, record) => (
        <ColumnDiv>
          <span>
            <Text.Heading
              style={styles.meetingColumnText}
              textAlign="left"
              text={record?.subject}
            />
            <span>
              <MdLocationPin color={Colors.GrayText} style={styles.mr5} />
              <Text.Heading
                fontSize={"10px"}
                fontWeight={"500"}
                color={Colors.GrayText}
                textAlign="left"
                style={styles.ml5}
                textTransform="capitalize"
                text={record?.meetingType}
              />
            </span>
          </span>
        </ColumnDiv>
      ),
    },
    {
      title: "Meeting Date",
      dataIndex: "date",
      align: "center",
      width: 300,
      onCell: (record) => ({
        onClick: () =>
          navigate("/meetings/meetingdetails", {
            state: { data: record, sent: "true" },
          }),
      }),
      render: (text, record) => {
        return (
          <span style={styles.meetingColumnText}>
            {dateFormatter(record?.meetingDate)}
          </span>
        );
      },
    },
    {
      title: "Meeting Time",
      dataIndex: "time",
      align: "center",
      width: 250,
      onCell: (record) => ({
        onClick: () =>
          navigate("/meetings/meetingdetails", {
            state: { data: record, sent: "true" },
          }),
      }),
      render: (text, record) => (
        <span style={styles.meetingColumnText}>{record?.timeSlot}</span>
      ),
    },
  ];

  return (
    <div>
      <Text.Heading style={styles.meetingHeadlines} text="Meetings" />
      <Layout.Spacer height={15} />
      <div style={styles.meetingDivContainer}>
        {upperBoxes?.map((item) => (
          <div style={styles.meetingDiv}>
            <Text.Heading
              fontSize="30px"
              color={Colors.Black}
              fontWeight={"700"}
              text={item.value}
            />
            <Text.Heading
              fontSize={"16px"}
              fontWeight={"600"}
              color={Colors.Primary}
              text={item.text}
            />
            <Layout.Spacer height={20} />
          </div>
        ))}
      </div>
      <Layout.Spacer height={35} />
      <div style={styles.upcomingAndCalendarContainer}>
        <div>
          <div>
            <Text.Heading
              style={styles.meetingHeadlines}
              text="Upcoming Meetings"
            />
            <Layout.Spacer height={10} />
          </div>
          {meetings?.isMeetingBasedOnDateLoading ? (
            <Loader.Circular />
          ) : (
            <div>
              {meetingsThisMonth?.length === 0 ? (
                <div style={{ color: "black" }}>No Meetings This Month</div>
              ) : (
                <div style={styles.upcomingMeetingsContainer}>
                  {meetingsThisMonth?.map((item, index) => {
                    return (
                      <RowDiv
                        key={index}
                        onClick={() => {
                          navigate("/meetings/meetingdetails", {
                            state: { data: item, sent: "true" },
                          });
                        }}
                      >
                        <span>
                          <Text.Heading
                            fontSize={"14px"}
                            fontWeight={"600"}
                            color={Colors.BlackText}
                            textALign={"center"}
                            text={
                              item.subject.charAt(0).toUpperCase() +
                              item.subject.slice(1)
                            }
                          />
                          <Text.Heading
                            fontSize={"12px"}
                            fontWeight={"400"}
                            color={Colors.GrayText}
                            textALign={"left"}
                            textTransform={"capitalize"}
                            text={`with ${item?.counselor?.fullName}`}
                          />
                        </span>
                        <span>
                          <div style={styles.dueContainer}>
                            {getDueDate(item?.meetingDate)}
                          </div>
                          <AiFillRightCircle
                            fontSize={"30px"}
                            cursor={"pointer"}
                            color={Colors.Primary}
                          />
                        </span>
                      </RowDiv>
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </div>
        <div>
          <Text.Heading
            fontSize={"18px"}
            fontWeight={"600"}
            color={Colors.Primary}
            textAlign="left"
            text="Calendar"
          />
          <Layout.Spacer height={10} />
          <CustomCalendar allMeetings={meetingDataAll} />
        </div>
      </div>
      <Layout.Spacer height={35} />
      <div style={styles.flexSpaceBetween}>
        <Text.Heading style={styles.meetingHeadlines} text="Meetings Sent" />
        <Button.Primary
          style={styles.buttonStyle}
          onClick={() =>
            navigate("/meetings/add-meeting", {
              state: {
                title: "New Meeting",
                edit: false,
                data: {},
              },
            })
          }
        >
          New Meeting
        </Button.Primary>
      </div>
      <Layout.Spacer height={20} />
      <Table.AntDesign
        rowKey={(record) => record._id}
        columns={[
          ...columns,
          {
            title: "Meeting Status",
            dataIndex: "meetingStatus",
            align: "center",
            width: 450,
            onCell: (record) => ({
              onClick: () =>
                navigate("/meetings/meetingdetails", {
                  state: { data: record, sent: "true" },
                }),
            }),
            render: (text, record) => (
              <span
                style={{
                  ...styles.meetingStatus,
                  color: statusColor(record),
                }}
              >
                {record?.status}
                {record?.status === "Pending" &&
                  getDueDate(record?.meetingDate)}
              </span>
            ),
          },
        ]}
        loading={meetings?.isMeetingInvitesLoading}
        data={meetingSent}
        pagination={{ defaultPageSize: 10, hideOnSinglePage: false }}
      />
      <Layout.Spacer height={20} />
      <Text.Heading style={styles.meetingHeadlines} text="Meetings Received" />
      <Layout.Spacer height={20} />
      <Table.AntDesign
        bordered={false}
        columns={[
          ...columns,
          {
            title: "Meeting Status",
            dataIndex: "status",
            key: "status",
            align: "center",
            width: 370,
            render: (text, record) => (
              <div
                style={{
                  ...styles.meetingStatus,
                  color: statusColor(record),
                }}
              >
                {record?.status === "Rejected" ? (
                  "DECLINED"
                ) : record?.status === "Pending" ||
                  record?.status === "pending" ? (
                  <div style={styles.rowCenter}>
                    <div
                      style={{ marginRight: "25px", cursor: "pointer" }}
                      onClick={() => acceptMeeting(record)}
                    >
                      <IoCheckmarkCircleSharp style={styles.acceptIcon} />
                      <Text.Heading
                        style={styles.meetingColumnText}
                        textAlign="left"
                        text="ACCEPT"
                      />
                    </div>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/meetings/add-meeting", {
                          state: {
                            title: "Reschedule Meeting",
                            edit: true,
                            data: record,
                          },
                        });
                      }}
                    >
                      <RiRefreshFill style={styles.rescheduleIcon} />
                      <Text.Heading
                        style={styles.meetingColumnText}
                        textAlign="left"
                        text="RESCHEDULE"
                      />
                    </div>
                  </div>
                ) : (
                  record?.status.toUpperCase()
                )}
              </div>
            ),
          },
        ]}
        loading={meetings?.isMeetingInvitesLoading}
        data={meetingInvites}
        pagination={{ defaultPageSize: 10, hideOnSinglePage: false }}
      />
      {successModal && (
        <Modal.Basic
          isModalVisible={successModal}
          centered={true}
          handleCancel={() => {
            fetchMeeting();
            setSuccessModal(false);
          }}
          closable={false}
        >
          <div style={styles.modalContainer}>
            <div>
              <Lottie
                loop
                animationData={Done}
                play
                style={styles.modalIcon}
              ></Lottie>
            </div>
            <div style={styles.modalHeader}>Successful!</div>
            <div style={styles.modalDescription}>
              Meeting Scheduled Successfully.
            </div>
            <div>
              <Button.Primary
                style={styles.modalButton}
                onClick={() => {
                  fetchMeeting();
                  setSuccessModal(false);
                }}
              >
                Done
              </Button.Primary>
            </div>
          </div>
        </Modal.Basic>
      )}
    </div>
  );
}

export default Meetings;

const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 99%;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid ${Colors.Primary};
  height: 90px;
  cursor: pointer;
  span:first-of-type {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 600px;
  }
  span:last-of-type {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    cursor: pointer;
    span:first-of-type {
      width: 27px;
      height: 27px;
    }
  }
`;

const ColumnDiv = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  img {
    width: 40px;
    height: 40px;
    margin-right: 20px;
  }
  span {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    span:first-of-type {
      color: ${Colors.BlackText};
    }
    span:last-of-type {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: ${Colors.BlackText};
    }
  }
`;
