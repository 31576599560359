import { Colors } from "../../config";

const styles = {
  // common
  customTextfieldStyle: {
    borderRadius: "10px",
    border: `1px solid ${Colors.Primary}`,
    padding: "10px",
    width: "100%",
    fontSize: "16px",
    fontWeight: "500",
    color: "#081C15",
  },
  flex: {
    display: "flex",
    flexDirection: "row",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  mr5: {
    marginRight: "5px",
  },

  // university page
  filterButton: {
    width: "45px",
    height: "45px",
    padding: "6px",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.Primary,
  },
  filterDropdown: {
    position: "absolute",
    zIndex: "100",
    backgroundColor: Colors.Primary,
    color: "white",
    fontSize: "12px",
    height: "80px",
    width: "150px",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    marginTop: "5px",
    left: "-70px",
    animation: "slide-down 0.3s ease",
  },
  filterDropdownValue: {
    cursor: "pointer",
    borderBottom: "1px solid white",
    marginBottom: "5px",
    paddingBottom: "5px",
  },
  filterRegion: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    width: "110px",
    height: "30px",
    margin: "0px 8px",
    fontSize: "11px",
    fontWeight: "500",
    borderRadius: "5px",
  },
  universityTableText: {
    fontSize: "12px",
    fontWeight: "500",
    color: Colors.BlackText,
  },
  universityActions: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  // compare
  compareContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  compareLeftPart: { width: "20%", display: "flex", flexDirection: "column" },
  compareRightPart: { width: "90%", overflowX: "auto", color: "black" },
  compareDetailsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  compareDetailsContainerEmpty: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    justifyContent: "start",
    position: "relative",
    width: "100%",
  },
  compareHeader: {
    height: "120px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  comparelabelsContainer: {
    borderTop: `2px solid ${Colors.LightGrayText}`,
    borderRight: `2px solid ${Colors.LightGrayText}`,
  },
  compareValuesContainer: {
    width: "100%",
    borderTop: `2px solid ${Colors.LightGrayText}`,
    borderRight: `2px solid ${Colors.LightGrayText}`,
  },
  compareLabelDiv: {
    height: "60px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: `1px solid ${Colors.LightGrayText}`,
  },
  compareLabelImageContainer: { width: "40%", textAlign: "center" },
  compareLabelImage: {
    height: "30px",
    width: "30px",
    color: Colors.Primary,
    objectFit: "contain",
  },

  compareLabel: {
    fontSize: "12px",
    fontWeight: "600",
    color: "black",
    width: "100%",
    margin: "0px 5px",
    textAlign: "center",
  },
  compareImageBox: {
    backgroundColor: Colors.Primary,
    width: "200px",
    height: "120px",
    borderRadius: "5px",
    margin: "0px 20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
  },
  compareImageDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "100%",
    width: "100%",
  },
  compareImage: {
    width: "100px",
    height: "100px",
    borderRadius: "100%",
    backgroundColor: "white",
    objectFit: "contain",
  },
  compareValuesText: {
    fontSize: "10px",
    fontWeight: "600",
    padding: "10px",
    textTransform: "capitalize",
    textAlign: "center",
  },
  deleteButton: {
    right: "5px",
    marginTop: "5px",
    position: "absolute",
  },
  addMoreIcon: { color: "white", fontSize: "60px" },
  addButtonText: {
    color: "white",
    fontSize: "10px",
    fontWeight: "500",
  },

  // compare select modal
  compareDropdownSelect: {
    color: Colors.Primary,
    width: "500px",
    borderColor: Colors.Primary,
    borderRadius: "10px",
    textTransform: "capitalize",
  },
  compareDropdownValues: {
    color: Colors.Primary,
    width: "500px",
    textTransform: "capitalize",
  },
  closeButton: {
    textDecoration: "none",
    color: "white",
    background: Colors.Primary,
    padding: "10px 40px",
    borderRadius: "6px",
    fontSize: "14px",
  },
};

export default styles;
