import {
  ADD_STAFF,
  ADD_STAFF_FAILURE,
  ADD_STAFF_SUCCESS,
  CHANGE_ROLE,
  CHANGE_ROLE_FAILURE,
  CHANGE_ROLE_SUCCESS,
  GET_COUNSELOR_INFO,
  GET_COUNSELOR_INFO_FAILURE,
  GET_COUNSELOR_INFO_SUCCESS,
  GET_STAFF,
  GET_STAFF_FAILURE,
  GET_STAFF_SUCCESS,
  REMOVE_STAFF,
  REMOVE_STAFF_FAILURE,
  REMOVE_STAFF_SUCCESS,
} from '../constants';

export default class StaffAction {
  static addStaff(payload, cb) {
    return {
      type: ADD_STAFF,
      payload,
      cb,
    };
  }
  static addStaffSuccess(payload) {
    return {
      type: ADD_STAFF_SUCCESS,
      payload,
    };
  }
  static addStaffFailure(payload) {
    return {
      type: ADD_STAFF_FAILURE,
      payload,
    };
  }
  static getStaff(payload, cb) {
    return {
      type: GET_STAFF,
      payload,
      cb,
    };
  }
  static getStaffSuccess(payload) {
    return {
      type: GET_STAFF_SUCCESS,
      payload,
    };
  }
  static getStaffFailure(payload) {
    return {
      type: GET_STAFF_FAILURE,
      payload,
    };
  }
  static getCounselorInfo(email, cb) {
    return {
      type: GET_COUNSELOR_INFO,
      email,
      cb,
    };
  }
  static getCounselorInfoSuccess(payload) {
    return {
      type: GET_COUNSELOR_INFO_SUCCESS,
      payload,
    };
  }
  static getCounselorInfoFailure(payload) {
    return {
      type: GET_COUNSELOR_INFO_FAILURE,
      payload,
    };
  }
  static changeRole(id, payload, cb) {
    return {
      type: CHANGE_ROLE,
      id,
      payload,
      cb,
    };
  }
  static changeRoleSuccess(payload) {
    return {
      type: CHANGE_ROLE_SUCCESS,
      payload,
    };
  }
  static changeRoleFailure(payload) {
    return {
      type: CHANGE_ROLE_FAILURE,
      payload,
    };
  }
  static removeStaff(id, cb) {
    return {
      type: REMOVE_STAFF,
      id,
      cb,
    };
  }
  static removeStaffSuccess(payload) {
    return {
      type: REMOVE_STAFF_SUCCESS,
      payload,
    };
  }
  static removeStaffFailure(payload) {
    return {
      type: REMOVE_STAFF_FAILURE,
      payload,
    };
  }
}
