import {
  ADD_BILL_INFORMATION,
  ADD_BILL_INFORMATION_FAILURE,
  ADD_BILL_INFORMATION_SUCCESS,
  ADD_INTEREST,
  ADD_INTEREST_FAILURE,
  ADD_INTEREST_SUCCESS,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_SUCCESS,
  EDIT_PROFILE,
  EDIT_PROFILE_FAILURE,
  EDIT_PROFILE_SUCCESS,
  GET_BILL_INFORMATION,
  GET_BILL_INFORMATION_FAILURE,
  GET_BILL_INFORMATION_SUCCESS,
  GET_PROFILE,
  GET_PROFILE_FAILURE,
  GET_PROFILE_SUCCESS,
  REMOVE_INTEREST,
  REMOVE_INTEREST_FAILURE,
  REMOVE_INTEREST_SUCCESS,
} from "../constants";

const initialState = {
  user: {},
  billInfo: [],
  isLoading: true,
  error: null,
};

export default function ProfileReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PROFILE:
    case EDIT_PROFILE:
    case ADD_INTEREST:
    case REMOVE_INTEREST:
    case ADD_BILL_INFORMATION:
    case GET_BILL_INFORMATION:
    case CHANGE_PASSWORD:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case GET_PROFILE_SUCCESS:
      state = {
        ...state,
        user: action.payload,
        isLoading: false,
      };
      break;
    case GET_BILL_INFORMATION_SUCCESS:
      state = {
        ...state,
        billInfo: action.payload,
        isLoading: false,
      };
      break;
    case EDIT_PROFILE_SUCCESS:
    case ADD_INTEREST_SUCCESS:
    case REMOVE_INTEREST_SUCCESS:
    case ADD_BILL_INFORMATION_SUCCESS:
    case CHANGE_PASSWORD_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case GET_PROFILE_FAILURE:
    case EDIT_PROFILE_FAILURE:
    case ADD_INTEREST_FAILURE:
    case REMOVE_INTEREST_FAILURE:
    case ADD_BILL_INFORMATION_FAILURE:
    case GET_BILL_INFORMATION_FAILURE:
    case CHANGE_PASSWORD_FAILURE:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    default:
      break;
  }
  return state;
}
