import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Select, DatePicker } from "antd";
import ReactCountryFlag from "react-country-flag";

import { MdLocationPin } from "react-icons/md";
import { SearchOutlined } from "@ant-design/icons";

import {
  ApplicationAction,
  AnnouncementAction,
  BillingAction,
  MeetingAction,
  StudentAction,
  UniversityAction,
} from "../../store/actions";

import {
  Charts,
  Layout,
  Loader,
  Text,
  CustomCalendar,
  dateFormatter,
} from "../../components";
import { Colors, Images, MockData } from "../../config";

import styled from "styled-components";
import styles from "./styles";

const findOccRegion = (arr, key) => {
  let arr2 = [];
  arr?.forEach((x) => {
    if (
      arr2.some((val) => {
        return val[key] === x[key];
      })
    ) {
      arr2.forEach((k) => {
        if (k[key] === x[key]) {
          k["occurrence"]++;
        }
      });
    } else {
      let a = {};
      a[key] = x[key];
      a["occurrence"] = 1;
      arr2.push(a);
    }
  });
  return arr2;
};

function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const schoolId = useSelector((state) => state.auth.user.user.schoolId);
  const schoolIdState = useSelector((state) => state.auth.user.user.schoolId);
  const meetings = useSelector((state) => state.meeting);
  const meetingBasedOnDate = useSelector(
    (state) => state.meeting?.meetingBasedOnDate
  );
  const applicationsLoading = useSelector((state) => state.application);
  const applications = useSelector((state) => state.application.applications);
  const universityLoading = useSelector((state) => state.university);
  const universityState = useSelector(
    (state) => state.university.allUniversities
  );
  const { activeStudents, count } = useSelector((state) => state.student);
  const { popularUniversities, popularCountries } = useSelector(
    (state) => state.university
  );
  const announcements = useSelector(
    (state) => state.announcement.announcements
  );
  const { meetingDataAll } = useSelector((state) => state.meeting?.meetingsAll);
  const billingInfo = useSelector(
    (state) => state.billing.billings.updatedBilling
  );

  const [showRange, setShowRange] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const [asia, setAsia] = useState("0");
  const [europe, setEurope] = useState("0");
  const [australia, setAustralia] = useState("0");
  const [southAmerica, setSouthAmerica] = useState("0");
  const [northAmerica, setNorthAmerica] = useState("0");
  const [africa, setAfrica] = useState("0");

  const applicationsCount = applications?.allApplications?.map(
    (item) => item.universityId
  );
  const approvedStudents = activeStudents?.filter(
    (item) => item.status !== "pending"
  );

  const femaleStudents = approvedStudents?.filter(
    (item) => item.gender.toLowerCase() === "female"
  );
  const maleStudents = approvedStudents?.filter(
    (item) => item.gender.toLowerCase() === "male"
  );
  const studentsApplied = approvedStudents?.filter((item) => {
    if (item.applications.length > 0) {
      return item;
    }
  });

  useEffect(() => {
    dispatch(BillingAction.getBilling({ schoolId: schoolIdState }));
  }, []);

  useEffect(() => {
    const studentPayload = {
      schoolId: schoolIdState,
      search: "",
      limit: "",
      offset: "",
    };
    dispatch(StudentAction.getActiveStudents(studentPayload));
  }, []);

  useEffect(() => {
    const announcementPayload = {
      limit: 10,
      offset: 0,
      schoolId: schoolIdState,
    };
    dispatch(AnnouncementAction.getAnnouncements(announcementPayload));
  }, []);

  useEffect(() => {
    const popularUniversityPayload = {
      schoolId: schoolIdState,
    };
    dispatch(UniversityAction.getPopularUniversities(popularUniversityPayload));
  }, []);

  useEffect(() => {
    const meetingPayload = {
      organizer: "School",
      schoolId: schoolIdState,
    };
    dispatch(MeetingAction.getAllMeetingsAndCount(meetingPayload));
    dispatch(MeetingAction.getMeetingsBasedOnDate(meetingPayload));
  }, []);

  useEffect(() => {
    const payload = {
      limit: "",
      offset: "",
      schoolId,
    };
    dispatch(ApplicationAction.getApplications(payload));
  }, []);

  useEffect(() => {
    if (universityState?.length === 0) {
      dispatch(UniversityAction.getAllUniversities());
    }
  }, []);

  useEffect(() => {
    const applicationDataOccurenceRegion = findOccRegion(
      applicationsCount,
      "region"
    );
    applicationDataOccurenceRegion.map((occurence) => {
      if (occurence.region === "asia") {
        setAsia(occurence.occurrence);
      }
      if (occurence.region === "europe") {
        setEurope(occurence.occurrence);
      }
      if (occurence.region === "australia") {
        setAustralia(occurence.occurrence);
      }
      if (occurence.region === "north america") {
        setNorthAmerica(occurence.occurrence);
      }
      if (occurence.region === "south america") {
        setSouthAmerica(occurence.occurrence);
      }
      if (occurence.region === "africa") {
        setAfrica(occurence.occurrence);
      }
    });
  }, [applications, applications?.isLoading]);

  const applicationsData = [
    {
      name: "Total Students",
      value: count ? count : 0,
      icon: Images.TotalStudents,
    },
    {
      name: "Students Applied",
      value: studentsApplied ? studentsApplied?.length : 0,
      icon: Images.Apply,
    },
    {
      name: "Active Students",
      value: approvedStudents ? approvedStudents?.length : 0,
      icon: Images.ActiveStudents,
    },
  ];

  const popularRegions = [
    {
      name: "Business",
      value: 0,
      icon: Images.Business,
    },
    {
      name: "Sciences",
      value: 0,
      icon: Images.Science,
    },
    {
      name: "Medical",
      value: 0,
      icon: Images.MedicalIcon,
    },
    {
      name: "Law",
      value: 0,
      icon: Images.Law,
    },
    {
      name: "Social Sciences",
      value: 0,
      icon: Images.SocialScience,
    },
  ];

  popularRegions?.forEach((region) => {
    let count = 0;

    applications?.allApplications?.forEach((application) => {
      if (application?.preferredProgram === region?.name) {
        count++;
      }
    });
    region.value =
      count / applicationsLoading?.totalApplicationsCount === 0 ||
      isNaN(count / applicationsLoading?.totalApplicationsCount)
        ? "0%"
        : Math.round(
            Number((count / applicationsLoading?.totalApplicationsCount) * 100)
          ) + "%";
  });

  const handleMeetings = () => {
    const meetingPayload = {
      organizer: "School",
      schoolId: schoolIdState,
      fromDate: fromDate,
      toDate: toDate,
    };
    dispatch(MeetingAction.getAllMeetingsAndCount(meetingPayload));
    dispatch(MeetingAction.getMeetingsBasedOnDate(meetingPayload));
  };

  if (new Date(billingInfo?.validUntil) < new Date()) {
    return (
      <>
        <div>
          <Text.Heading
            fontSize={"18px"}
            fontWeight={"600"}
            color={Colors.Red}
            text={"Account Expired"}
          />
        </div>
        <div>
          <Text.Heading
            fontSize={"16px"}
            fontWeight={"500"}
            color={Colors.Black}
            text={
              "Please activate your account from the Billing section. For further inquiries, contact with our admins at admin@univision.hk."
            }
          />
        </div>
      </>
    );
  } else {
    return (
      <>
        <div
          style={{
            ...styles.rowStyle,
            ...{ alignItems: "flex-start" },
          }}
        >
          <div style={{ width: "65%" }}>
            <Layout.Spacer height={10} />
            <div className="dashboard-select">
              {universityLoading?.allUniversityLoading ? (
                <Loader.Circular />
              ) : (
                <Select
                  style={styles.universityDropdown}
                  onChange={(universityJSON) => {
                    const university = JSON.parse(universityJSON);
                    navigate(`/universities/${university._id}/general`);
                  }}
                  showSearch
                  placeholder="Search University "
                  optionFilterProp="children"
                  suffixIcon={<SearchOutlined />}
                >
                  {universityState?.map((dropdown, index) => {
                    return (
                      <Select.Option
                        value={JSON.stringify(dropdown)}
                        style={{ textTransform: "capitalize" }}
                        key={index}
                      >
                        {dropdown.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              )}
            </div>
            <Layout.Spacer height={20} />
            <Text.Heading
              fontSize={"18px"}
              fontWeight={"600"}
              color={Colors.Primary}
              text={"Analytics"}
            />
            <Layout.Spacer height={20} />
            <div style={styles.cardContainerRow}>
              {applicationsData?.map((item, index) => (
                <div style={styles.flexColumnCenter} key={index}>
                  <img src={item.icon} alt={item.icon} height={90} />
                  <Text.Heading
                    fontSize="45px"
                    color={Colors.Primary}
                    fontWeight={"600"}
                    text={item.value}
                  />
                  <Text.Heading
                    fontSize={"16px"}
                    fontWeight={"500"}
                    color={Colors.GrayText}
                    text={item.name}
                  />
                </div>
              ))}
            </div>
            <Layout.Spacer height={20} />
            <div style={styles.rowStyle}>
              <Text.Heading
                fontSize={"18px"}
                fontWeight={"600"}
                color={Colors.Primary}
                text="Announcements"
              />
              <Text.Heading
                fontSize={"16px"}
                fontWeight={"400"}
                color={Colors.GrayText}
                style={styles.cursor}
                text="See all"
                onClick={() => navigate("/announcements")}
              />
            </div>
            <Layout.Spacer height={20} />
            <div style={styles.announcementDivContainer}>
              {announcements?.length === 0 ? (
                <div style={{ color: "black" }}>No Accouncements</div>
              ) : (
                announcements?.slice(0, 3)?.map((item, index) => (
                  <div
                    style={{
                      ...styles.announcementContainer,
                      background:
                        item.recepient === "all" ? Colors.LightGray : "#DFF5F2",
                    }}
                    key={index}
                  >
                    <div style={styles.rowStyle}>
                      <Text.Heading
                        fontSize={"14px"}
                        fontWeight={"700"}
                        color={Colors.BlackText}
                        textAlign="left"
                        text={
                          item?.subject.length > 20
                            ? `${item?.subject.slice(0, 20)}...`
                            : item?.subject
                        }
                      />
                      <img
                        src={
                          item?.subject.toLowerCase().includes("deadline") ||
                          item?.subject.toLowerCase().includes("deadlines")
                            ? Images.Deadline
                            : item?.subject
                                .toLowerCase()
                                .includes("admission") ||
                              item?.subject.toLowerCase().includes("admissions")
                            ? Images.Admission
                            : Images.AnnouncementIcon
                        }
                        height={26}
                        alt="icon"
                      />
                    </div>
                    <Layout.Spacer height={5} />
                    <Text.Heading
                      fontSize={"12px"}
                      fontWeight={"400"}
                      color={Colors.GrayText}
                      textAlign="left"
                      text={
                        item?.message.length > 50
                          ? `${item?.message.slice(0, 50)}...`
                          : item?.message
                      }
                    />
                  </div>
                ))
              )}
            </div>
            <Layout.Spacer height={20} />
            <div style={styles.popularContainer}>
              <div>
                <div>
                  <Text.Heading
                    fontSize={"18px"}
                    fontWeight={"600"}
                    color={Colors.Primary}
                    text="Most Popular Universities"
                  />
                </div>
                <Layout.Spacer height={10} />
                <div style={styles.universityRankingContainer}>
                  <div style={styles.graphImgBox}>
                    <img
                      src={Images.UniversityStanding}
                      alt="uniStanding"
                      style={styles.graphImg}
                    />
                    <img
                      src={
                        popularUniversities[2]?.profilePic
                          ? popularUniversities[2]?.profilePic
                          : Images.UniversityDefault
                      }
                      alt="uni3"
                      style={styles.graphImg1}
                    />
                    <img
                      src={
                        popularUniversities[0]?.profilePic
                          ? popularUniversities[0]?.profilePic
                          : Images.UniversityDefault
                      }
                      alt="uni1"
                      style={styles.graphImg2}
                    />
                    <img
                      src={
                        popularUniversities[1]?.profilePic
                          ? popularUniversities[1]?.profilePic
                          : Images.UniversityDefault
                      }
                      alt="uni2"
                      style={styles.graphImg3}
                    />
                  </div>

                  <Layout.Spacer height={10} />
                  {popularUniversities?.slice(0, 5)?.map((item, index) => (
                    <div style={styles.popularUniRow} key={index}>
                      <Layout.Spacer height={10} />
                      <div style={styles.numberBox}>
                        <Text.Heading
                          fontSize={"15px"}
                          fontWeight={"600"}
                          color={Colors.White}
                          textAlign="left"
                          textTransform="capitalize"
                          text={index + 1}
                        />
                      </div>
                      <div>
                        <Text.Heading
                          fontSize={"15px"}
                          fontWeight={"600"}
                          color={Colors.BlackText}
                          style={{
                            ...styles.flexColumnStart,
                            ...styles.textContainer,
                          }}
                          textTransform="capitalize"
                          text={
                            item.instituteName.length > 32
                              ? item.instituteName.slice(0, 32) + "..."
                              : item.instituteName
                          }
                          title={item.instituteName}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <div style={styles.flexColumn}>
                  <Text.Heading
                    fontSize={"18px"}
                    fontWeight={"600"}
                    color={Colors.Primary}
                    text="Most Popular Countries"
                    textAlign={"left"}
                  />
                  <Layout.Spacer height={10} />
                  {popularCountries?.slice(0, 5)?.map((item, index) => {
                    let found = MockData?.CountryListAllIsoData?.find(
                      (country) =>
                        country.name.toLowerCase() === item.name.toLowerCase()
                    );
                    let code = found ? found.code : "n/a";

                    return (
                      <div style={styles.popularUniOccurrence} key={index}>
                        <span style={styles.flexRowCenter}>
                          <ReactCountryFlag
                            countryCode={code}
                            svg
                            style={styles.flagStyle}
                            title="US"
                          />
                          <span style={styles.flexColumn}>
                            <Text.Heading
                              fontSize={"14px"}
                              fontWeight={"600"}
                              color={Colors.BlackText}
                              textAlign="left"
                              textTransform="capitalize"
                              text={item.name}
                            />
                            <span>
                              <MdLocationPin style={styles.locationIcon} />
                              <Text.Heading
                                fontSize={"12px"}
                                fontWeight={"400"}
                                color={Colors.BlackText}
                                textTransform="capitalize"
                                text={item.region}
                              />
                            </span>
                          </span>
                        </span>
                        <span style={styles.flexColumn}>
                          <Text.Heading
                            fontSize={"14px"}
                            fontWeight={"600"}
                            color={Colors.Primary}
                            text={item.occurrence}
                          />
                          <Text.Heading
                            fontSize={"12px"}
                            fontWeight={"400"}
                            color={Colors.Primary}
                            text={"Applications"}
                          />
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <Layout.Spacer height={20} />
            <div style={styles.mapContainer}>
              <div style={styles.map}>
                <div
                  style={{
                    top: "40%",
                    left: "20%",
                    ...styles.mapCircles,
                  }}
                >
                  <h2 style={styles.mapValue}>{northAmerica}</h2>
                </div>
                <div
                  style={{
                    top: "72%",
                    left: "30%",
                    ...styles.mapCircles,
                  }}
                >
                  <h2 style={styles.mapValue}>{southAmerica}</h2>
                </div>
                <div
                  style={{
                    top: "40%",
                    left: "50%",
                    ...styles.mapCircles,
                  }}
                >
                  <h2 style={styles.mapValue}>{europe}</h2>
                </div>
                <div
                  style={{
                    top: "63%",
                    left: "55%",
                    ...styles.mapCircles,
                  }}
                >
                  <h2 style={styles.mapValue}>{africa}</h2>
                </div>
                <div
                  style={{
                    top: "40%",
                    left: "70%",
                    ...styles.mapCircles,
                  }}
                >
                  <h2 style={styles.mapValue}>{asia}</h2>
                </div>
                <div
                  style={{
                    top: "80%",
                    left: "90%",
                    ...styles.mapCircles,
                  }}
                >
                  <h2 style={styles.mapValue}>{australia}</h2>
                </div>
              </div>
            </div>
            <Layout.Spacer height={20} />

            <div style={styles.studentsAppliedContainer}>
              <div>
                <div style={styles.flexJustifyCenter}>
                  <Charts.PieChart
                    data={[
                      {
                        name: "Applied",
                        value: studentsApplied?.length,
                      },
                      {
                        name: "Not Applied",
                        value:
                          approvedStudents?.length - studentsApplied?.length,
                      },
                    ]}
                  />
                </div>
                <Text.Heading
                  fontSize={"18px"}
                  fontWeight={"600"}
                  color={Colors.Black}
                  text={"Student Applications"}
                />
                <Layout.Spacer height={20} />
                <div style={styles.rowStyle}>
                  <div
                    style={{
                      ...styles.flexColumnCenter,
                      width: "100%",
                    }}
                  >
                    <div style={styles.appliedPercentageText}>
                      {Math.round(
                        Number(
                          (studentsApplied?.length / approvedStudents?.length) *
                            100
                        )
                      )}
                      %
                    </div>
                    <div style={styles.appliedBox}></div>
                    <div style={styles.appliedPercentageText}>Applied</div>
                  </div>
                  <div
                    style={{
                      ...styles.flexColumnCenter,
                      width: "100%",
                    }}
                  >
                    <div style={styles.appliedPercentageText}>
                      {100 -
                        Math.round(
                          Number(
                            (studentsApplied?.length /
                              approvedStudents?.length) *
                              100
                          )
                        )}
                      %
                    </div>
                    <div style={styles.notAppliedBox}></div>
                    <div style={styles.appliedPercentageText}>Not Applied</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ width: "30%" }}>
            <Layout.Spacer height={10} />
            <Text.Heading
              fontSize={"18px"}
              fontWeight={"600"}
              color={Colors.Primary}
              text={"Most Popular Majors"}
            />
            <Layout.Spacer height={10} />
            {applicationsLoading?.isLoading ? (
              <Loader.Circular />
            ) : (
              <div style={styles.flexColumn}>
                {popularRegions.map((item, index) => (
                  <div style={styles.popularMajorContainer} key={index}>
                    <div>
                      <img
                        src={item.icon}
                        height={28}
                        alt="icon"
                        style={{ margin: "0px 10px" }}
                      />
                      <Text.Heading
                        fontSize={"14px"}
                        fontWeight={"600"}
                        color={Colors.BlackText}
                        textTransform="capitalize"
                        text={item.name}
                      />
                    </div>
                    <Text.Heading
                      fontSize={"14px"}
                      fontWeight={"600"}
                      color={Colors.Primary}
                      text={item.value}
                    />
                  </div>
                ))}
              </div>
            )}
            <Layout.Spacer height={20} />
            <div style={styles.studentPercentageContainer}>
              <img src={Images.MaleStudent} alt="male" height={100} />
              <Layout.Spacer height={10} />
              <Text.Heading
                fontSize={"14px"}
                fontWeight={"600"}
                color={Colors.BlackText}
                text={
                  maleStudents?.length / approvedStudents?.length === 0
                    ? "0% Male Students"
                    : `${Math.round(
                        Number(
                          (maleStudents?.length / approvedStudents?.length) *
                            100
                        )
                      )}% Male Students`
                }
              />
              <Layout.Spacer height={20} />
              <Text.Heading
                fontSize={"18px"}
                fontWeight={"500"}
                color={Colors.BlackText}
                text={"VS"}
              />
              <Layout.Spacer height={20} />
              <img src={Images.FemaleStudent} alt="female" height={100} />
              <Layout.Spacer height={10} />
              <Text.Heading
                fontSize={"14px"}
                fontWeight={"600"}
                color={Colors.BlackText}
                text={
                  femaleStudents?.length / approvedStudents?.length === 0
                    ? "0% Female Students"
                    : `${
                        100 -
                        Math.round(
                          Number(
                            (maleStudents?.length / approvedStudents?.length) *
                              100
                          )
                        )
                      }% Female Students`
                }
              />
            </div>
            <Layout.Spacer height={20} />
            <Text.Heading
              fontSize={"18px"}
              fontWeight={"600"}
              color={Colors.Primary}
              text="Calendar"
            />
            <Layout.Spacer height={10} />

            <CustomCalendar allMeetings={meetingDataAll} />

            <Layout.Spacer height={20} />

            <div>
              <div style={styles.rowStyle}>
                <Text.Heading
                  fontSize={"18px"}
                  fontWeight={"600"}
                  color={Colors.Primary}
                  text="Meetings"
                />
                <div
                  style={styles.dateRangeText}
                  onClick={() => setShowRange(!showRange)}
                >
                  Date Range{" "}
                  <span>
                    <img src={Images.DateRange} height={24}></img>
                  </span>
                </div>
              </div>
              {showRange && (
                <div style={styles.meetingDatePickerDiv}>
                  <Layout.Spacer height={10} />
                  <div style={styles.flexJustifyCenter}>
                    <div
                      style={{
                        ...styles.meetingDatePickerText,
                        width: "120px",
                      }}
                    >
                      From Date
                    </div>
                    <div>
                      <DatePicker
                        style={styles.meetingDatePicker}
                        onChange={(date, dateString) => {
                          setFromDate(dateString);
                        }}
                      />
                    </div>
                    <div
                      style={{
                        ...styles.meetingDatePickerText,
                        width: "110px",
                      }}
                    >
                      To Date
                    </div>
                    <div>
                      <DatePicker
                        style={styles.meetingDatePicker}
                        onChange={(date, dateString) => {
                          setToDate(dateString);
                        }}
                      />
                    </div>
                  </div>
                  <Layout.Spacer height={10} />
                  <div
                    onClick={handleMeetings}
                    style={styles.meetingSubmitButton}
                  >
                    Submit
                  </div>
                </div>
              )}
              <Layout.Spacer height={10} />
              {meetings?.isMeetingBasedOnDateLoading ? (
                <Loader.Circular />
              ) : (
                <div>
                  {meetingBasedOnDate?.length === 0 ? (
                    <div style={styles.noMeetingsText}>
                      No Meetings This Month
                    </div>
                  ) : (
                    <div style={styles.meetingsContainer}>
                      {meetingBasedOnDate?.map((item, index) => {
                        const formattedDate = dateFormatter(item?.meetingDate);
                        return (
                          <RowDiv
                            key={index}
                            onClick={() => {
                              navigate("/meetings/meetingdetails", {
                                state: { data: item, sent: "true" },
                              });
                            }}
                          >
                            <span>
                              <Text.Heading
                                fontSize={"14px"}
                                fontWeight={"900"}
                                color={Colors.BlackText}
                                text={item.subject}
                                textTransform={"capitalize"}
                              />
                              <Text.Heading
                                fontSize={"11px"}
                                fontWeight={"400"}
                                color={Colors.GrayText}
                                textTransform={"capitalize"}
                                text={`with ${item?.studentId?.name}`}
                              />
                              <Text.Heading
                                fontSize={"11px"}
                                fontWeight={"600"}
                                color={Colors.Primary}
                                text={formattedDate}
                              />
                            </span>
                            <span>
                              <img
                                src={Images.MeetingDetails}
                                height="48px"
                                width="48px"
                                alt=""
                              ></img>
                            </span>
                          </RowDiv>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
              <Layout.Spacer height={10} />
              <div style={styles.flexJustifyCenter}>
                <Text.Heading
                  fontSize={"14px"}
                  fontWeight={"500"}
                  color={Colors.GrayText}
                  style={styles.cursor}
                  textAlign="center"
                  text="See all Meetings"
                  onClick={() => navigate("/meetings")}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Dashboard;

const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
  span:first-of-type {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    width: 80%;
  }
  span:last-of-type {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    span:first-of-type {
      width: 27px;
      height: 27px;
    }
  }
`;
