import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';

import { routes } from './siteMap';
import AppRoutes from './AppRoutes';
import AuthRoutes from './AuthRoutes';

const AllRoutes = () => {
  const isLoggedIn = useSelector((state) => state.auth?.isLoggedIn || false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      navigate(routes.protected.dashboard);
    }
  }, [isLoggedIn]);

  if (isLoggedIn) {
    return <AppRoutes />;
  } else {
    return <AuthRoutes />;
  }
};

export default AllRoutes;
