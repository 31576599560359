import { ProfileDropDown } from './ProfileDropDown';
import DashboardLayout from './DashboardLayout';
import LoginLayout, { HeaderSection } from './LoginLayout';
import NavHeader from './NavHeader';
import Spacer from './Spacer';
import StepsSection from './Steps';

const Layout = {
  DashboardLayout,
  ProfileDropDown,
  NavHeader,
  Spacer,
  LoginLayout,
  HeaderSection,
  StepsSection,
};

export default Layout;
