import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { Select, DatePicker } from "antd";

import {
  AnnouncementAction,
  StudentAction,
  UniversityAction,
} from "../../store/actions";

import {
  Button,
  Layout,
  Text,
  TextField,
  Loader,
  capitalizeText,
} from "../../components";
import { Colors } from "../../config";

import styles from "./styles";

function AddAnnouncement() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const schoolStudents = useSelector((state) => state.student?.schoolStudents);
  const schoolId = useSelector((state) => state.auth.user?.user?.schoolId);
  const universityLoading = useSelector(
    (state) => state?.university?.allUniversityLoading
  );
  const universityState = useSelector(
    (state) => state.university?.allUniversities
  );

  const [error, setError] = useState("");
  const [buttonDisable, setButtonDisable] = useState(false);
  const [state, setState] = useState({
    subject: "",
    recepient: "all",
    studentId: "all",
    expiryDate: "",
    message: "",
    universityAssociatedWith: "",
  });

  useEffect(() => {
    if (universityState?.length === 0) {
      dispatch(UniversityAction.getAllUniversities());
    }
    dispatch(StudentAction.getSchoolActiveStudents(schoolId));
  }, []);

  const handleAddAnnouncement = () => {
    if (
      !state.subject ||
      !state.recepient ||
      !state.studentId ||
      !state.message ||
      !state.expiryDate
    ) {
      setError("Please fill all fields");
    } else {
      setError("");
      setButtonDisable(true);
      let body;
      if (state.universityAssociatedWith) {
        body = {
          subject: state.subject,
          recepient: state.recepient,
          studentId: state.studentId,
          expiryDate: state.expiryDate,
          message: state.message,
          universityAssociatedWith: state.universityAssociatedWith,
          schoolId,
        };
      } else {
        body = {
          subject: state.subject,
          recepient: state.recepient,
          studentId: state.studentId,
          expiryDate: state.expiryDate,
          message: state.message,
          schoolId,
        };
      }
      dispatch(
        AnnouncementAction.addAnnouncement(body, (res) => {
          if (res.status !== 201) {
            setButtonDisable(false);
          } else {
            navigate("/announcements");
          }
        })
      );
    }
  };

  return (
    <>
      <Text.Heading
        fontSize={"18px"}
        fontWeight={"600"}
        color={Colors.Primary}
        text="New Announcement"
      />
      <Layout.Spacer height={10} />
      <Text.Heading
        fontSize={"14px"}
        fontWeight={"500"}
        color={Colors.BlackText2}
        text="Have an important news for your students? You can notify them by making an announcement."
      />
      <Layout.Spacer height={10} />
      {universityLoading ? (
        <Loader.Circular />
      ) : (
        <div>
          <div>
            <TextField.CustomInput
              placeholder="Enter the subject of the announcement"
              value={state.subject}
              onChange={(e) =>
                setState({
                  ...state,
                  subject: e.target.value,
                })
              }
              label="Subject*"
              style={styles.textfieldStyle}
            />
          </div>
          <Layout.Spacer height={10} />
          <div style={styles.boxHeader}>Recipient </div>
          <div className="counselor-select">
            <Select
              defaultValue="all"
              value={capitalizeText(state.recepient)}
              onChange={(value) => {
                setState({
                  ...state,
                  recepient: value,
                  studentId: value,
                });
              }}
              style={{ width: "100%" }}
            >
              <Select.Option value="all">All</Select.Option>
              {schoolStudents?.map((item) => (
                <Select.Option
                  style={{ textTransform: "capitalize" }}
                  value={item._id}
                >
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </div>
          <Layout.Spacer height={10} />
          <div style={styles.boxHeader}>University Associated With </div>
          <div>
            <div className="counselor-select">
              <Select
                style={{ width: "100%" }}
                onChange={(universityJSON) => {
                  var university = JSON.parse(universityJSON);
                  setState({
                    ...state,
                    universityAssociatedWith: university._id,
                  });
                }}
                showSearch
                placeholder="Search University "
                optionFilterProp="children"
              >
                {universityState.map((dropdown) => {
                  return (
                    <Select.Option
                      filterOption={(input, dropdown) =>
                        (dropdown?.name ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      value={JSON.stringify(dropdown)}
                      style={{ textTransform: "capitalize" }}
                    >
                      {dropdown.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
          </div>
          <Layout.Spacer height={10} />

          <div style={styles.boxHeader}>Expiry Date* </div>

          <DatePicker
            style={styles.datePicker}
            onChange={(date, dateString) =>
              setState({ ...state, expiryDate: dateString })
            }
          />

          <Layout.Spacer height={10} />
          <div style={styles.boxHeader}>Message</div>

          <TextField.CustomTextArea
            rows={4}
            value={state.message}
            onChange={(e) =>
              setState({
                ...state,
                message: e.target.value,
              })
            }
            placeholder={"Message of the announcement"}
          />

          {error && (
            <div
              style={{
                textAlign: "center",
                fontSize: "16px",
                fontWeight: "500",
                color: Colors.Red,
              }}
            >
              <Layout.Spacer height={10} />
              {error}
            </div>
          )}
          <Layout.Spacer height={20} />
          <div style={styles.justifyEnd}>
            <Button.Primary
              style={styles.buttonStyle}
              disabled={buttonDisable}
              onClick={handleAddAnnouncement}
            >
              Continue
            </Button.Primary>
          </div>
        </div>
      )}
    </>
  );
}

export default AddAnnouncement;
