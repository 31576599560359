import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tabs } from "antd";
import { useLocation, useNavigate } from "react-router";

import { BillingAction } from "../../store/actions";

import { Colors } from "../../config";
import { Layout, Text } from "../../components";

import AddStaff from "./AddStaff";
import BillingInformation from "./BillingInformation";
import Permissions from "./Permissions";
import StaffRoles from "./StaffRoles";

const { TabPane } = Tabs;

function AdminSection() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tabName = location.pathname.split("/")[2];

  const schoolId = useSelector((state) => state.auth.user.user.schoolId);
  const billingInfo = useSelector(
    (state) => state.billing.billings.updatedBilling
  );

  const [subHeading, setSubHeading] = useState("");

  const tabs = ["permission", "billing", "staff-roles", "add-staff"];
  const tabKey = String(tabs.indexOf(tabName) + 1);

  useEffect(() => {
    dispatch(BillingAction.getBilling({ schoolId: schoolId }));
  }, []);

  useEffect(() => {
    switch (tabName) {
      case "permission":
        setSubHeading("Account Permissions");
        break;
      case "billing":
        setSubHeading("Account Billing Information");
        break;
      case "staff-roles":
        setSubHeading("View & Change Roles");
        break;
      case "add-staff":
        setSubHeading("Provides Staff Access");
        break;
      default:
    }
  }, [tabKey]);

  const renderTabs = () => {
    if (new Date(billingInfo?.validUntil) < new Date()) {
      return (
        <>
          <TabPane tab="Billing Information" key="2">
            <BillingInformation />
          </TabPane>
        </>
      );
    } else {
      return (
        <>
          <TabPane tab="Permissions" key="1">
            <Permissions />
          </TabPane>
          <TabPane tab="Billing Information" key="2">
            <BillingInformation />
          </TabPane>
          <TabPane tab="Staff Roles" key="3">
            <StaffRoles />
          </TabPane>
          <TabPane tab="Add Staff" key="4">
            <AddStaff />
          </TabPane>
        </>
      );
    }
  };
  return (
    <>
      <Text.Heading
        fontSize={"18px"}
        fontWeight={"600"}
        color={Colors.Primary}
        text="Admin Section"
      />
      <Layout.Spacer height={0} />
      <Text.Heading
        fontSize={"12px"}
        fontWeight={"500"}
        color={Colors.Gray}
        text={subHeading}
      />
      <Layout.Spacer height={20} />
      <Tabs
        type="card"
        className="university-details-tabs"
        moreIcon={false}
        animated={true}
        key={tabKey}
        defaultActiveKey={tabKey}
        onChange={(value) => {
          switch (value) {
            case "1":
              navigate(`/admin/permission`);
              break;
            case "2":
              navigate(`/admin/billing`);
              break;
            case "3":
              navigate(`/admin/staff-roles`);
              break;
            case "4":
              navigate(`/admin/add-staff`);
              break;
            default:
              navigate(`/admin/permission`);
          }
        }}
      >
        {renderTabs()}
      </Tabs>
    </>
  );
}

export default AdminSection;
