import React from "react";

const FormLink = () => {
  return (
    <div style={{ textAlign: "center" }}>
      Sign up with{" "}
      <span
        style={{
          textDecoration: "underline",
        }}
      >
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSeMqAjGm3zlY0YW0cKEjhymI4JL1prhXCUH1B30-ocGPK_ILw/viewform"
          style={{
            color: "black",
            fontWeight: "700",
          }}
          target="_blank"
        >
          Google Form
        </a>
      </span>
    </div>
  );
};

export default FormLink;
