//--------------login flow
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const SIGNUP = "SIGNUP";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const VERIFY_OTP = "VERIFY_OTP";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAILURE = "VERIFY_OTP_FAILURE";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const CHECK_EMAIL = "CHECK_EMAIL";
export const CHECK_EMAIL_SUCCESS = "CHECK_EMAIL_SUCCESS";
export const CHECK_EMAIL_FAILURE = "CHECK_EMAIL_FAILURE";

export const VERIFY_SIGNUP_OTP = "VERIFY_SIGNUP_OTP";
export const VERIFY_SIGNUP_OTP_SUCCESS = "VERIFY_SIGNUP_OTP_SUCCESS";
export const VERIFY_SIGNUP_OTP_FAILURE = "VERIFY_SIGNUP_OTP_FAILURE";

export const GET_COUNSELOR_INFO = "GET_COUNSELOR_INFO";
export const GET_COUNSELOR_INFO_SUCCESS = "GET_COUNSELOR_INFO_SUCCESS";
export const GET_COUNSELOR_INFO_FAILURE = "GET_COUNSELOR_INFO_FAILURE";

export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const LOADER_TRUE = "LOADER_TRUE";
export const LOADER_FALSE = "LOADER_FALSE";
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";

//-------------universities
export const GET_UNIVERSITIES = "GET_UNIVERSITIES";
export const GET_UNIVERSITIES_SUCCESS = "GET_UNIVERSITIES_SUCCESS";
export const GET_UNIVERSITIES_FAILURE = "GET_UNIVERSITIES_FAILURE";

export const GET_ALL_UNIVERSITIES = "GET_ALL_UNIVERSITIES";
export const GET_ALL_UNIVERSITIES_SUCCESS = "GET_ALL_UNIVERSITIES_SUCCESS";
export const GET_ALL_UNIVERSITIES_FAILURE = "GET_ALL_UNIVERSITIES_FAILURE";

export const GET_POPULAR_UNIVERSITIES = "GET_POPULAR_UNIVERSITIES";
export const GET_POPULAR_UNIVERSITIES_SUCCESS =
  "GET_POPULAR_UNIVERSITIES_SUCCESS";
export const GET_POPULAR_UNIVERSITIES_FAILURE =
  "GET_POPULAR_UNIVERSITIES_FAILURE";

export const GET_UNIVERSITY_DETAILS = "GET_UNIVERSITY_DETAILS";
export const GET_UNIVERSITY_DETAILS_SUCCESS = "GET_UNIVERSITY_DETAILS_SUCCESS";
export const GET_UNIVERSITY_DETAILS_FAILURE = "GET_UNIVERSITY_DETAILS_FAILURE";

export const ADD_OR_REMOVE_FROM_WISHLIST = "ADD_OR_REMOVE_FROM_WISHLIST";
export const ADD_OR_REMOVE_FROM_WISHLIST_SUCCESS =
  "ADD_OR_REMOVE_FROM_WISHLIST_SUCCESS";
export const ADD_OR_REMOVE_FROM_WISHLIST_FAILURE =
  "ADD_OR_REMOVE_FROM_WISHLIST_FAILURE";

//students

export const SEND_STUDENT_EMAIL = "SEND_STUDENT_EMAIL";
export const SEND_STUDENT_EMAIL_SUCCESS = "SEND_STUDENT_EMAIL_SUCCESS";
export const SEND_STUDENT_EMAIL_FAILURE = "SEND_STUDENT_EMAIL_FAILURE";

export const GET_ACTIVE_STUDENTS = "GET_ACTIVE_STUDENTS";
export const GET_ACTIVE_STUDENTS_SUCCESS = "GET_ACTIVE_STUDENTS_SUCCESS";
export const GET_ACTIVE_STUDENTS_FAILURE = "GET_ACTIVE_STUDENTS_FAILURE";

export const GET_SCHOOL_ACTIVE_STUDENTS = "GET_SCHOOL_ACTIVE_STUDENTS";
export const GET_SCHOOL_ACTIVE_STUDENTS_SUCCESS =
  "GET_SCHOOL_ACTIVE_STUDENTS_SUCCESS";
export const GET_SCHOOL_ACTIVE_STUDENTS_FAILURE =
  "GET_SCHOOL_ACTIVE_STUDENTS_FAILURE";

export const GET_REGISTERED_STUDENTS = "GET_REGISTERED_STUDENTS";
export const GET_REGISTERED_STUDENTS_SUCCESS =
  "GET_REGISTERED_STUDENTS_SUCCESS";
export const GET_REGISTERED_STUDENTS_FAILURE =
  "GET_REGISTERED_STUDENTS_FAILURE";

export const GET_STUDENT_DETAILS = "GET_STUDENT_DETAILS";
export const GET_STUDENT_DETAILS_SUCCESS = "GET_STUDENT_DETAILS_SUCCESS";
export const GET_STUDENT_DETAILS_FAILURE = "GET_STUDENT_DETAILS_FAILURE";

export const GET_REGISTERED_STUDENT_DETAILS = "GET_REGISTERED_STUDENT_DETAILS";
export const GET_REGISTERED_STUDENT_DETAILS_SUCCESS =
  "GET_REGISTERED_STUDENT_DETAILS_SUCCESS";
export const GET_REGISTERED_STUDENT_DETAILS_FAILURE =
  "GET_REGISTERED_STUDENT_DETAILS_FAILURE";

export const GET_STUDENTS_DETAILS = "GET_STUDENTS_DETAILS";
export const GET_STUDENTS_DETAILS_SUCCESS = "GET_STUDENTS_DETAILS_SUCCESS";
export const GET_STUDENTS_DETAILS_FAILURE = "GET_STUDENTS_DETAILS_FAILURE";

export const GET_STUDENT_MEETING_DETAILS = "GET_STUDENT_MEETING_DETAILS";
export const GET_STUDENT_MEETING_DETAILS_SUCCESS =
  "GET_STUDENT_MEETING_DETAILS_SUCCESS";
export const GET_STUDENT_MEETING_DETAILS_FAILURE =
  "GET_STUDENT_MEETING_DETAILS_FAILURE";

export const GET_STUDENT_ALL_MEETING_DETAILS =
  "GET_STUDENT_ALL_MEETING_DETAILS";
export const GET_STUDENT_ALL_MEETING_DETAILS_SUCCESS =
  "GET_STUDENT_ALL_MEETING_DETAILS_SUCCESS";
export const GET_STUDENT_ALL_MEETING_DETAILS_FAILURE =
  "GET_STUDENT_ALL_MEETING_DETAILS_FAILURE";

export const FILTER_STUDENTS = "FILTER_STUDENTS";
export const FILTER_STUDENTS_SUCCESS = "FILTER_STUDENTS_SUCCESS";
export const FILTER_STUDENTS_FAILURE = "FILTER_STUDENTS_FAILURE";

export const ADD_SCHOOL_STUDENT = "ADD_SCHOOL_STUDENT";
export const ADD_SCHOOL_STUDENT_SUCCESS = "ADD_SCHOOL_STUDENT_SUCCESS";
export const ADD_SCHOOL_STUDENT_FAILURE = "ADD_SCHOOL_STUDENT_FAILURE";

export const ADD_SCHOOL_STUDENT_SHEET = "ADD_SCHOOL_STUDENT_SHEET";
export const ADD_SCHOOL_STUDENT_SHEET_SUCCESS =
  "ADD_SCHOOL_STUDENT_SHEET_SUCCESS";
export const ADD_SCHOOL_STUDENT_SHEET_FAILURE =
  "ADD_SCHOOL_STUDENT_SHEET_FAILURE";

//profile
export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAILURE = "GET_PROFILE_FAILURE";

export const EDIT_PROFILE = "EDIT_PROFILE";
export const EDIT_PROFILE_SUCCESS = "EDIT_PROFILE_SUCCESS";
export const EDIT_PROFILE_FAILURE = "EDIT_PROFILE_FAILURE";

export const ADD_INTEREST = "ADD_INTEREST";
export const ADD_INTEREST_SUCCESS = "ADD_INTEREST_SUCCESS";
export const ADD_INTEREST_FAILURE = "ADD_INTEREST_FAILURE";

export const REMOVE_INTEREST = "REMOVE_INTEREST";
export const REMOVE_INTEREST_SUCCESS = "REMOVE_INTEREST_SUCCESS";
export const REMOVE_INTEREST_FAILURE = "REMOVE_INTEREST_FAILURE";

export const ADD_BILL_INFORMATION = "ADD_BILL_INFORMATION";
export const ADD_BILL_INFORMATION_SUCCESS = "ADD_BILL_INFORMATION_SUCCESS";
export const ADD_BILL_INFORMATION_FAILURE = "ADD_BILL_INFORMATION_FAILURE";

export const GET_BILL_INFORMATION = "GET_BILL_INFORMATION";
export const GET_BILL_INFORMATION_SUCCESS = "GET_BILL_INFORMATION_SUCCESS";
export const GET_BILL_INFORMATION_FAILURE = "GET_BILL_INFORMATION_FAILURE";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

//miscellaneous
export const ADD_CONTACT = "ADD_CONTACT";
export const ADD_CONTACT_SUCCESS = "ADD_CONTACT_SUCCESS";
export const ADD_CONTACT_FAILURE = "ADD_CONTACT_FAILURE";

export const CHANGE_ROLE = "CHANGE_ROLE";
export const CHANGE_ROLE_SUCCESS = "CHANGE_ROLE_SUCCESS";
export const CHANGE_ROLE_FAILURE = "CHANGE_ROLE_FAILURE";

export const GET_POPULAR_MAJORS = "GET_POPULAR_MAJORS";
export const GET_POPULAR_MAJORS_SUCCESS = "GET_POPULAR_MAJORS_SUCCESS";
export const GET_POPULAR_MAJORS_FAILURE = "GET_POPULAR_MAJORS_FAILURE";

//Staff
export const ADD_STAFF = "ADD_STAFF";
export const ADD_STAFF_SUCCESS = "ADD_STAFF_SUCCESS";
export const ADD_STAFF_FAILURE = "ADD_STAFF_FAILURE";

export const GET_STAFF = "GET_STAFF";
export const GET_STAFF_SUCCESS = "GET_STAFF_SUCCESS";
export const GET_STAFF_FAILURE = "GET_STAFF_FAILURE";

export const REMOVE_STAFF = "REMOVE_STAFF";
export const REMOVE_STAFF_SUCCESS = "REMOVE_STAFF_SUCCESS";
export const REMOVE_STAFF_FAILURE = "REMOVE_STAFF_FAILURE";

//Announcements
export const ADD_ANNOUNCEMENT = "ADD_ANNOUNCEMENT";
export const ADD_ANNOUNCEMENT_SUCCESS = "ADD_ANNOUNCEMENT_SUCCESS";
export const ADD_ANNOUNCEMENT_FAILURE = "ADD_ANNOUNCEMENT_FAILURE";

export const GET_ANNOUNCEMENTS = "GET_ANNOUNCEMENTS";
export const GET_ANNOUNCEMENTS_SUCCESS = "GET_ANNOUNCEMENTS_SUCCESS";
export const GET_ANNOUNCEMENTS_FAILURE = "GET_ANNOUNCEMENTS_FAILURE";

export const CHANGE_ANNOUNCEMENT_STATUS = "CHANGE_ANNOUNCEMENT_STATUS";
export const CHANGE_ANNOUNCEMENT_STATUS_SUCCESS =
  "CHANGE_ANNOUNCEMENT_STATUS_SUCCESS";
export const CHANGE_ANNOUNCEMENT_STATUS_FAILURE =
  "CHANGE_ANNOUNCEMENT_STATUS_FAILURE";

//meetings
export const ADD_MEETING = "ADD_MEETING";
export const ADD_MEETING_SUCCESS = "ADD_MEETING_SUCCESS";
export const ADD_MEETING_FAILURE = "ADD_MEETING_FAILURE";

export const GET_MEETINGS = "GET_MEETINGS";
export const GET_MEETINGS_SUCCESS = "GET_MEETINGS_SUCCESS";
export const GET_MEETINGS_FAILURE = "GET_MEETINGS_FAILURE";

export const GET_MEETINGS_INVITES_AND_REQUESTS =
  "GET_MEETINGS_INVITES_AND_REQUESTS";
export const GET_MEETINGS_INVITES_AND_REQUESTS_SUCCESS =
  "GET_MEETINGS_INVITES_AND_REQUESTS_SUCCESS";
export const GET_MEETINGS_INVITES_AND_REQUESTS_FAILURE =
  "GET_MEETINGS_INVITES_AND_REQUESTS_FAILURE";

export const GET_ALL_MEETINGS_AND_COUNT = "GET_ALL_MEETINGS_AND_COUNT";
export const GET_ALL_MEETINGS_AND_COUNT_SUCCESS =
  "GET_ALL_MEETINGS_AND_COUNT_SUCCESS";
export const GET_ALL_MEETINGS_AND_COUNT_FAILURE =
  "GET_ALL_MEETINGS_AND_COUNT_FAILURE";

export const GET_MEETINGS_BASED_ON_DATE = "GET_MEETINGS_BASED_ON_DATE";
export const GET_MEETINGS_BASED_ON_DATE_SUCCESS =
  "GET_MEETINGS_BASED_ON_DATE_SUCCESS";
export const GET_MEETINGS_BASED_ON_DATE_FAILURE =
  "GET_MEETINGS_BASED_ON_DATE_FAILURE";

export const CHANGE_MEETING_STATUS = "CHANGE_MEETING_STATUS";
export const CHANGE_MEETING_STATUS_SUCCESS = "CHANGE_MEETING_STATUS_SUCCESS";
export const CHANGE_MEETING_STATUS_FAILURE = "CHANGE_MEETING_STATUS_FAILURE";

//applications
export const GET_APPLICATIONS = "GET_APPLICATIONS";
export const GET_APPLICATIONS_SUCCESS = "GET_APPLICATIONS_SUCCESS";
export const GET_APPLICATIONS_FAILURE = "GET_APPLICATIONS_FAILURE";

export const CHANGE_APPLICATION_STATUS = "CHANGE_APPLICATION_STATUS";
export const CHANGE_APPLICATION_STATUS_SUCCESS =
  "CHANGE_APPLICATION_STATUS_SUCCESS";
export const CHANGE_APPLICATION_STATUS_FAILURE =
  "CHANGE_APPLICATION_STATUS_FAILURE";

//notifications
export const ADD_NOTIFICATIONS = "ADD_NOTIFICATIONS";
export const ADD_NOTIFICATIONS_SUCCESS = "ADD_NOTIFICATIONS_SUCCESS";
export const ADD_NOTIFICATIONS_FAILURE = "ADD_NOTIFICATIONS_FAILURE";

export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const GET_NOTIFICATIONS_FAILURE = "GET_NOTIFICATIONS_FAILURE";

export const CHANGE_NOTIFICATIONS_STATUS = "CHANGE_NOTIFICATIONS_STATUS";
export const CHANGE_NOTIFICATIONS_STATUS_SUCCESS =
  "CHANGE_NOTIFICATIONS_STATUS_SUCCESS";
export const CHANGE_NOTIFICATIONS_STATUS_FAILURE =
  "CHANGE_NOTIFICATIONS_STATUS_FAILURE";

//billing
export const ADD_BILLING = "ADD_BILLING";
export const ADD_BILLING_SUCCESS = "ADD_BILLING_SUCCESS";
export const ADD_BILLING_FAILURE = "ADD_BILLING_FAILURE";

export const GET_BILLING = "GET_BILLING";
export const GET_BILLING_SUCCESS = "GET_BILLING_SUCCESS";
export const GET_BILLING_FAILURE = "GET_BILLING_FAILURE";

export const GET_INVOICES = "GET_INVOICES";
export const GET_INVOICES_SUCCESS = "GET_INVOICES_SUCCESS";
export const GET_INVOICES_FAILURE = "GET_INVOICES_FAILURE";

export const UPDATE_BILLING = "UPDATE_BILLING";
export const UPDATE_BILLING_SUCCESS = "UPDATE_BILLING_SUCCESS";
export const UPDATE_BILLING_FAILURE = "UPDATE_BILLING_FAILURE";

// converter
export const GET_CONVERTER = "GET_CONVERTER";
export const GET_CONVERTER_SUCCESS = "GET_CONVERTER_SUCCESS";
export const GET_CONVERTER_FAILURE = "GET_CONVERTER_FAILURE";

export const UPDATE_CONVERTER = "UPDATE_CONVERTER";
export const UPDATE_CONVERTERG_SUCCESS = "UPDATE_CONVERTER_SUCCESS";
export const UPDATE_CONVERTER_FAILURE = "UPDATE_CONVERTER_FAILURE";

// chatbot
export const GET_MESSAGES = "GET_MESSAGES";
export const GET_MESSAGES_SUCCESS = "GET_MESSAGES_SUCCESS";
export const GET_MESSAGES_FAILURE = "GET_MESSAGES_FAILURE";

export const GET_UNREAD_MESSAGES = "GET_UNREAD_MESSAGES";
export const GET_UNREAD_MESSAGES_SUCCESS = "GET_UNREAD_MESSAGES_SUCCESS";
export const GET_UNREAD_MESSAGES_FAILURE = "GET_UNREAD_MESSAGES_FAILURE";

export const ADD_MESSAGE = "ADD_MESSAGE";
export const ADD_MESSAGE_SUCCESS = "ADD_MESSAGE_SUCCESS";
export const ADD_MESSAGE_FAILURE = "ADD_MESSAGE_FAILURE";

export const GET_USERS = "GET_USERS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILURE = "GET_USERS_FAILURE";

export const GET_STUDENTS = "GET_STUDENTS";
export const GET_STUDENTS_SUCCESS = "GET_STUDENTS_SUCCESS";
export const GET_STUDENTS_FAILURE = "GET_STUDENTS_FAILURE";
