import { Colors } from "../../config";

const styles = {
  flexRowSpace: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  flexRow: {
    display: "flex",
    flexDirection: "row",
  },

  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  flexColumnCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  justifyCenterItemsCenter: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  mb5: {
    marginBottom: "5px",
  },

  // cardRow
  cardHeader: {
    fontSize: "12px",
    fontWeight: "600",
    color: "#081C15",
    textTransform: "capitalize",
    textAlign: "left",
  },
  cardSubHeader: {
    fontSize: "10px",
    fontWeight: "400",
    color: Colors.GrayText,
    textAlign: "left",
  },
  iconDivPrimary: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "4px",
    borderRadius: "50px",
    marginRight: "5px",
    marginTop: "2px",
    backgroundColor: Colors.Primary,
    border: `1px solid ${Colors.Primary}`,
  },
  iconDivSecondary: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "4px",
    borderRadius: "50px",
    marginRight: "5px",
    marginTop: "2px",
    backgroundColor: "transparent",
    border: `1px solid ${Colors.Primary}`,
  },
};

export default styles;
