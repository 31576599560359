import { Navigate } from "react-router";

import {
  AddAnnouncement,
  AddMeeting,
  AddStudent,
  AdminSection,
  Announcements,
  Applications,
  Chatbot,
  ChangePassword,
  ContactUs,
  Dashboard,
  DetailsForm,
  EditProfile,
  EmailForm,
  FAQs,
  ForgotPassword,
  Invoices,
  Meetings,
  MeetingDetails,
  Message,
  Notifications,
  PasswordForm,
  PaymentDetails,
  PrivacyPolicy,
  Profile,
  ResetPassword,
  SignIn,
  StudentDetails,
  Students,
  Support,
  TermsAndCondition,
  Universities,
  UniversityDetails,
  VerifyOTP,
  VerifyOTPForm,
} from "../../containers";

export const routes = {
  protected: {
    // dashboard
    dashboard: "/home",

    // students
    students: "/students",
    addStudent: "/students/add-student",
    studentDetails: "/students/:id",

    // announcements
    announcements: "/announcements",
    addAnnouncement: "/announcements/add-announcement",

    // universities
    universities: "/universities",
    universityGeneralDetails: "/universities/:id/general",
    universityStudentLifeDetails: "/universities/:id/student-life",
    universityVisaDetails: "/universities/:id/visa",
    universityProgramsDetails: "/universities/:id/programs",
    universityFinanceDetails: "/universities/:id/finances",
    universityPostGraduationDetails: "/universities/:id/post-graduation",
    universityApplicationJourneyDetails:
      "/universities/:id/application-journey",
    universitySummaryDetails: "/universities/:id/summary",

    // meetings
    meetings: "/meetings",
    addMeeting: "/meetings/add-meeting",
    meetingdetails: "/meetings/meetingdetails",
    message: "/meetings/meetingdetails/message",

    // applications
    applications: "/applications",

    // admin section
    permissions: "/admin/permission",
    billing: "/admin/billing",
    paymentDetails: "/admin/billing/paymentDetails",
    invoices: "/admin/billing/paymentDetails/:id",
    addStaff: "/admin/add-staff",
    staffRoles: "/admin/staff-roles",

    // profile
    profile: "/profile",

    // settings
    settings: "/settings",
    changePassword: "/settings/change-password",

    // support
    contactUs: "/support",
    faqs: "/support/faqs",

    // tna
    privacyPolicy: "/privacy-policy",
    termsAndCondition: "/terms-and-conditions",

    // notifications
    notifications: "/notifications",

    // chatbot
    chatbot: "/more/chatbot",
  },
  public: {
    // signup
    signupEmail: "/signup/email",
    signupOTP: "/signup/verify-otp",
    signupPassword: "/signup/password",
    signupDetails: "/signup/details",

    // login
    login: "/login",
    forgotPassword: "/forgot-password",
    verifyCode: "/verify-code",
    resetPassword: "/reset-password",
  },
};

export const PROTECTED_ROUTES = [
  // dashboard
  {
    path: routes.protected.dashboard,
    exact: false,
    component: <Dashboard />,
  },

  // students
  {
    path: routes.protected.students,
    exact: false,
    component: <Students />,
  },
  {
    path: routes.protected.addStudent,
    exact: false,
    component: <AddStudent />,
  },
  {
    path: routes.protected.studentDetails,
    exact: false,
    component: <StudentDetails />,
  },

  // announcements
  {
    path: routes.protected.announcements,
    exact: false,
    component: <Announcements />,
  },
  {
    path: routes.protected.addAnnouncement,
    exact: false,
    component: <AddAnnouncement />,
  },

  // notifications
  {
    path: routes.protected.notifications,
    exact: false,
    component: <Notifications />,
  },

  // universities
  {
    path: routes.protected.universities,
    exact: false,
    component: <Universities />,
  },
  {
    path: routes.protected.universityGeneralDetails,
    exact: false,
    component: <UniversityDetails />,
  },
  {
    path: routes.protected.universityStudentLifeDetails,
    exact: false,
    component: <UniversityDetails />,
  },
  {
    path: routes.protected.universityApplicationJourneyDetails,
    exact: false,
    component: <UniversityDetails />,
  },
  {
    path: routes.protected.universityFinanceDetails,
    exact: false,
    component: <UniversityDetails />,
  },
  {
    path: routes.protected.universityPostGraduationDetails,
    exact: false,
    component: <UniversityDetails />,
  },
  {
    path: routes.protected.universityProgramsDetails,
    exact: false,
    component: <UniversityDetails />,
  },
  {
    path: routes.protected.universitySummaryDetails,
    exact: false,
    component: <UniversityDetails />,
  },
  {
    path: routes.protected.universityVisaDetails,
    exact: false,
    component: <UniversityDetails />,
  },

  // chatbot
  {
    path: routes.protected.chatbot,
    exact: false,
    component: <Chatbot />,
  },

  // meetings
  {
    path: routes.protected.meetings,
    exact: false,
    component: <Meetings />,
  },
  {
    path: routes.protected.addMeeting,
    exact: false,
    component: <AddMeeting />,
  },
  {
    path: routes.protected.meetingdetails,
    exact: false,
    component: <MeetingDetails />,
  },
  {
    path: routes.protected.message,
    exact: false,
    component: <Message />,
  },

  // applications
  {
    path: routes.protected.applications,
    exact: false,
    component: <Applications />,
  },

  // admin sections
  {
    path: routes.protected.permissions,
    exact: false,
    component: <AdminSection />,
  },
  {
    path: routes.protected.billing,
    exact: false,
    component: <AdminSection />,
  },
  {
    path: routes.protected.addStaff,
    exact: false,
    component: <AdminSection />,
  },
  {
    path: routes.protected.staffRoles,
    exact: false,
    component: <AdminSection />,
  },
  {
    path: routes.protected.paymentDetails,
    exact: false,
    component: <PaymentDetails />,
  },
  {
    path: routes.protected.invoices,
    exact: false,
    component: <Invoices />,
  },

  // profile
  {
    path: routes.protected.profile,
    exact: false,
    component: <Profile />,
  },

  // settings
  {
    path: routes.protected.settings,
    exact: false,
    component: <EditProfile />,
  },
  {
    path: routes.protected.changePassword,
    exact: false,
    component: <ChangePassword />,
  },

  // support
  {
    path: routes.protected.support,
    exact: false,
    component: <Support />,
  },
  {
    path: routes.protected.contactUs,
    exact: false,
    component: <ContactUs />,
  },
  {
    path: routes.protected.faqs,
    exact: false,
    component: <FAQs />,
  },

  // tna
  {
    path: routes.protected.privacyPolicy,
    exact: false,
    component: <PrivacyPolicy />,
  },
  {
    path: routes.protected.termsAndCondition,
    exact: false,
    component: <TermsAndCondition />,
  },
  {
    path: "*",
    exact: false,
    component: <Navigate to={routes.protected.dashboard} />,
  },
];

export const PUBLIC_ROUTES = [
  // signup
  {
    path: routes.public.signupEmail,
    exact: false,
    component: <EmailForm />,
  },
  {
    path: routes.public.signupOTP,
    exact: false,
    component: <VerifyOTPForm />,
  },
  {
    path: routes.public.signupPassword,
    exact: false,
    component: <PasswordForm />,
  },
  {
    path: routes.public.signupDetails,
    exact: false,
    component: <DetailsForm />,
  },

  // login
  {
    path: routes.public.login,
    exact: false,
    component: <SignIn />,
  },
  {
    path: routes.public.forgotPassword,
    exact: false,
    component: <ForgotPassword />,
  },
  {
    path: routes.public.verifyCode,
    exact: false,
    component: <VerifyOTP />,
  },
  {
    path: routes.public.resetPassword,
    exact: false,
    component: <ResetPassword />,
  },
  {
    path: "*",
    exact: false,
    component: <Navigate to={routes.public.login} />,
  },
];
