import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Menu } from "antd";

import { AiFillHome } from "react-icons/ai";
import { ImUsers } from "react-icons/im";
import { MdSchool } from "react-icons/md";
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import { TbLogout } from "react-icons/tb";

import { AuthAction, StaffAction, BillingAction } from "../../store/actions";
import { Images } from "../../config";

import "./sidebar.css";
import styles from "./styles";

const SideBar = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const title = ((location || {}).pathname || {}).split("/")[1];

  const role = useSelector((state) => state.auth.user?.user?.role);
  const email = useSelector((state) => state.auth.user?.user?.email);
  const schoolId = useSelector((state) => state.auth.user?.user?.schoolId);
  const staffRole = useSelector((state) => state.staff.staff.position);
  const billingInfo = useSelector(
    (state) => state.billing.billings.updatedBilling
  );

  useEffect(() => {
    if (schoolId) {
      dispatch(BillingAction.getBilling({ schoolId: schoolId }));
    }
  }, []);

  useEffect(() => {
    if (role === "counselor") {
      dispatch(StaffAction.getCounselorInfo(email));
    }
  }, []);

  useEffect(() => {
    const menu = document.querySelector(".ant-menu").children;
    for (let i = 0; i < menu.length; i++) {
      if (menu[i].innerText.toLowerCase() === title) {
        menu[i].classList.add("ant-menu-item-selected");
      } else {
        menu[i].classList.remove("ant-menu-item-selected");
      }
    }
  }, [title]);

  const handleLogout = () => {
    dispatch(AuthAction.Logout());
  };

  const highlighter = (value) => {
    return title === value ? "menu-item ant-menu-item-selected" : "menu-item";
  };

  const renderMainNavOptions = () => {
    if (new Date(billingInfo?.validUntil) < new Date()) {
      return (
        <>
          <Menu.Item
            className={highlighter("home")}
            key="1"
            id="home"
            icon={<AiFillHome style={styles.sidebarIcon} />}
          >
            <Link to="/home">Home</Link>
          </Menu.Item>
          {role &&
            (role.toLowerCase() === "school" ||
              staffRole?.toLowerCase() === "admin") && (
              <Menu.Item
                className={highlighter("admin")}
                id="admin"
                key="7"
                icon={
                  <img
                    src={Images.AdminIcon}
                    alt="admin"
                    style={{ marginRight: "12px" }}
                  />
                }
              >
                <Link to="/admin/permission">Admin Section</Link>
              </Menu.Item>
            )}
        </>
      );
    } else {
      return (
        <>
          <Menu.Item
            className={highlighter("home")}
            key="1"
            id="home"
            icon={<AiFillHome style={styles.sidebarIcon} />}
          >
            <Link to="/home">Home</Link>
          </Menu.Item>
          <Menu.Item
            className={highlighter("students")}
            id="student"
            key="2"
            icon={<ImUsers style={styles.sidebarIcon} />}
          >
            <Link to="/students">Students</Link>
          </Menu.Item>
          <Menu.Item
            className={highlighter("announcements")}
            id="announcement"
            key="3"
            icon={
              <img
                src={Images.AnnouncementSidebarIcon}
                alt="announcement"
                style={{ marginRight: "12px" }}
              />
            }
          >
            <Link to="/announcements">Announcements</Link>
          </Menu.Item>
          <Menu.Item
            className={highlighter("universities")}
            id="universit"
            key="4"
            icon={<MdSchool style={styles.sidebarIcon} />}
          >
            <Link to="/universities">Universities</Link>
          </Menu.Item>
          <Menu.Item
            className={highlighter("meetings")}
            id="meeting"
            key="5"
            icon={
              <img
                src={Images.MeetingIcon}
                alt="meeting"
                style={{ marginRight: "15px" }}
              />
            }
          >
            <Link to="/meetings">Meetings</Link>
          </Menu.Item>
          <Menu.Item
            className={highlighter("applications")}
            id="application"
            key="6"
            icon={
              <img
                src={Images.ApplicationSidebarIcon}
                alt="application"
                style={{ marginRight: "18px" }}
              />
            }
          >
            <Link to="/applications">Applications</Link>
          </Menu.Item>
          {role &&
            (role.toLowerCase() === "school" ||
              staffRole?.toLowerCase() === "admin") && (
              <Menu.Item
                className={highlighter("admin")}
                id="admin"
                key="7"
                icon={
                  <img
                    src={Images.AdminIcon}
                    alt="admin"
                    style={{ marginRight: "12px" }}
                  />
                }
              >
                <Link to="/admin/permission">Admin Section</Link>
              </Menu.Item>
            )}

          <Menu.Item
            className={highlighter("more")}
            id="chatbot"
            key="9"
            icon={<IoChatbubbleEllipsesOutline style={styles.sidebarIcon} />}
          >
            <Link to="/more/chatbot">Chatbot</Link>
          </Menu.Item>
        </>
      );
    }
  };

  return (
    <div className="sidebar">
      <div style={styles.sidebarLogoContainer}>
        <img src={Images.Logo} style={styles.sidebarImage} />
      </div>
      <Menu theme="dark" mode="inline" className="sidebar-menu">
        {renderMainNavOptions()}
        <Menu.Item
          className="menu-item sidebar-logout"
          key="8"
          id="logout"
          icon={<TbLogout style={styles.sidebarIcon} />}
        >
          <Link to="/login" onClick={handleLogout}>
            Log out
          </Link>
        </Menu.Item>
      </Menu>
    </div>
  );
};
export default SideBar;
