import {
  ADD_BILL_INFORMATION,
  ADD_BILL_INFORMATION_FAILURE,
  ADD_BILL_INFORMATION_SUCCESS,
  ADD_INTEREST,
  ADD_INTEREST_FAILURE,
  ADD_INTEREST_SUCCESS,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_SUCCESS,
  EDIT_PROFILE,
  EDIT_PROFILE_FAILURE,
  EDIT_PROFILE_SUCCESS,
  GET_BILL_INFORMATION,
  GET_BILL_INFORMATION_FAILURE,
  GET_BILL_INFORMATION_SUCCESS,
  GET_PROFILE,
  GET_PROFILE_FAILURE,
  GET_PROFILE_SUCCESS,
  REMOVE_INTEREST,
  REMOVE_INTEREST_FAILURE,
  REMOVE_INTEREST_SUCCESS
} from '../constants';

export default class ProfileAction {
  static getUserProfile(cb) {
    return {
      type: GET_PROFILE,
      cb,
    };
  }
  static getUserProfileSuccess(payload) {
    return {
      type: GET_PROFILE_SUCCESS,
      payload,
    };
  }
  static getUserProfileFailure(payload) {
    return {
      type: GET_PROFILE_FAILURE,
      payload,
    };
  }
  static editUserProfile(payload, cb) {
    return {
      type: EDIT_PROFILE,
      payload,
      cb,
    };
  }
  static editUserProfileSuccess(payload) {
    return {
      type: EDIT_PROFILE_SUCCESS,
      payload,
    };
  }
  static editUserProfileFailure(payload) {
    return {
      type: EDIT_PROFILE_FAILURE,
      payload,
    };
  }
  static addInterest(payload, cb) {
    return {
      type: ADD_INTEREST,
      payload,
      cb,
    };
  }
  static addInterestSuccess(payload) {
    return {
      type: ADD_INTEREST_SUCCESS,
      payload,
    };
  }
  static addInterestFailure(payload) {
    return {
      type: ADD_INTEREST_FAILURE,
      payload,
    };
  }
  static removeInterest(interest, cb) {
    return {
      type: REMOVE_INTEREST,
      interest,
      cb,
    };
  }
  static removeInterestSuccess(payload) {
    return {
      type: REMOVE_INTEREST_SUCCESS,
      payload,
    };
  }
  static removeInterestFailure(payload) {
    return {
      type: REMOVE_INTEREST_FAILURE,
      payload,
    };
  }
  static addUserBillInfo(payload, cb) {
    return {
      type: ADD_BILL_INFORMATION,
      payload,
      cb,
    };
  }
  static addUserBillInfoSuccess(payload) {
    return {
      type: ADD_BILL_INFORMATION_SUCCESS,
      payload,
    };
  }
  static addUserBillInfoFailure(payload) {
    return {
      type: ADD_BILL_INFORMATION_FAILURE,
      payload,
    };
  }
  static getUserBillInfo(cb) {
    return {
      type: GET_BILL_INFORMATION,
      cb,
    };
  }
  static getUserBillInfoSuccess(payload) {
    return {
      type: GET_BILL_INFORMATION_SUCCESS,
      payload,
    };
  }
  static getUserBillInfoFailure(payload) {
    return {
      type: GET_BILL_INFORMATION_FAILURE,
      payload,
    };
  }
  static changePassword(payload, cb) {
    return {
      type: CHANGE_PASSWORD,
      payload,
      cb,
    };
  }
  static changePasswordSuccess(payload) {
    return {
      type: CHANGE_PASSWORD_SUCCESS,
      payload,
    };
  }
  static changePasswordFailure(payload) {
    return {
      type: CHANGE_PASSWORD_FAILURE,
      payload,
    };
  }
}
