import { Colors } from "../../config";
import { Layout, Text, CardRow } from "../../components";

import styles from "./styles";

function Application({ data }) {
  let countries = [];

  data.forEach((app) => {
    if (!countries.includes(app.universityId.location)) {
      countries.push(app.universityId.location);
    }
  });

  const applicationsData = [
    {
      name: "Total Applications",
      value: data ? data?.length : 0,
    },
    {
      name: "Countries Applied to",
      value: countries?.length,
    },
  ];

  return (
    <>
      <Layout.Spacer height={20} />

      <div style={styles.applicationUpperDataContainer}>
        {applicationsData?.map((item) => (
          <div style={styles.applicationUpperData}>
            <Text.Heading
              fontSize="40px"
              color={Colors.Primary}
              fontWeight={"600"}
              text={item.value}
            />
            <Text.Heading
              fontSize={"14px"}
              fontWeight={"500"}
              color={Colors.GrayText}
              text={item.name}
            />
          </div>
        ))}
      </div>
      <Layout.Spacer height={20} />
      <div
        style={{
          width: "70%",
          margin: "auto",
        }}
      >
        <Text.Heading
          fontSize={"18px"}
          fontWeight={"600"}
          color={Colors.Primary}
          text="Submitted Applications"
        />

        <Layout.Spacer height={20} />
        <div>
          {data?.length > 0 ? (
            data?.map((application) => (
              <CardRow data={application} application />
            ))
          ) : (
            <div style={{ textAlign: "center" }}>
              <Text.Heading
                fontSize={"18px"}
                fontWeight={"400"}
                color={Colors.GrayText}
                text="No applications submitted yet"
              />
            </div>
          )}
        </div>
      </div>
      <Layout.Spacer height={20} />
    </>
  );
}

export default Application;
