import { put } from "redux-saga/effects";

import { ApiCaller, Utils } from "../../config";
import { AnnouncementAction } from "../actions";

export default class AnnouncementMiddleware {
  static *addAnnouncement({ payload, cb }) {
    try {
      let res = yield ApiCaller.Post(`/school/add-announcements`, payload);
      if (res.status === 201) {
        yield put(AnnouncementAction.addAnnouncementSuccess(res.data.data));
        if (cb) {
          cb(res);
        }
      } else {
        yield put(AnnouncementAction.addAnnouncementFailure());
        // Utils.showSnackBar({
        //   message: res.data.message,
        //   severity: 'error',
        // });
      }
    } catch (err) {
      yield put(AnnouncementAction.addAnnouncementFailure());
    }
  }

  static *getAnnouncements({ payload, cb }) {
    try {
      const { limit, offset, schoolId } = payload;
      let res;
      if (limit || offset) {
        res = yield ApiCaller.Get(
          `/school/fetch-announcements?limit=${limit}&offset=${offset}&schoolId=${schoolId}&requestedBy=School`
        );
      } else {
        res = yield ApiCaller.Get(
          `/school/fetch-announcements?schoolId=${schoolId}&requestedBy=School`
        );
      }
      if (res.status === 200) {
        yield put(AnnouncementAction.getAnnouncementsSuccess(res.data.data));
        if (cb) {
          cb();
        }
      } else {
        yield put(AnnouncementAction.getAnnouncementsSuccess([]));
        // Utils.showSnackBar({
        //     message: res.data.message,
        //     severity: 'error',
        //   });
      }
    } catch (err) {
      yield put(AnnouncementAction.getAnnouncementsFailure());
    }
  }

  static *changeAnnouncementStatus({ payload, cb }) {
    try {
      const { id, schoolId } = payload;
      let res = yield ApiCaller.Get(
        `/school/change-announcement-status/${id}?schoolId=${schoolId}`
      );
      if (res.status === 200) {
        yield put(
          AnnouncementAction.changeAnnouncementStatusSuccess(res.data.data)
        );
        Utils.showSnackBar({
          message: res.data.message,
        });
        if (cb) {
          cb();
        }
      } else {
        yield put(AnnouncementAction.changeAnnouncementStatusFailure());
        // Utils.showSnackBar({
        //   message: res.data.message,
        //   severity: 'error',
        // });
      }
    } catch (err) {
      yield put(AnnouncementAction.changeAnnouncementStatusFailure());
    }
  }
}
