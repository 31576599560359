import React from "react";
import { useNavigate } from "react-router";
import { AiFillRightCircle } from "react-icons/ai";

import { Colors, Images } from "../../config";
import { Layout, Text, CustomCalendarStudent } from "../../components";

import styled from "styled-components";
import styles from "./styles";

const findOcc = (arr, key) => {
  let arr2 = [];

  arr?.forEach((x) => {
    if (
      arr2.some((val) => {
        return val[key] === x[key];
      })
    ) {
      arr2.forEach((k) => {
        if (k[key] === x[key]) {
          k["occurrence"]++;
        }
      });
    } else {
      let a = {};
      a[key] = x[key];
      a["occurrence"] = 1;
      arr2.push(a);
    }
  });

  return arr2;
};

function General({ data, meetings, meetingsAll, preferredProgram }) {
  const navigate = useNavigate();

  const applicationsData = data?.map((item) => item.universityId);

  const applicationDataOccurence = findOcc(applicationsData, "location");
  const maxOccurenceSorted = applicationDataOccurence.sort(
    (a, b) => b.occurrence - a.occurrence
  );

  return (
    <>
      <Layout.Spacer height={20} />
      <div style={styles.flexRowSpace}>
        <div style={{ width: "65%" }}>
          <div style={styles.flexRowSpace}>
            <div style={{ width: "35%" }}>
              <div style={styles.cardContainer}>
                <img
                  src={Images.TotalApplication}
                  alt=""
                  width={80}
                  style={{ marginBottom: "5px" }}
                />
                <Layout.Spacer height={10} />
                <Text.Heading
                  fontSize={"16px"}
                  fontWeight={"500"}
                  color={Colors.GrayText}
                  text={"Total Applications"}
                />
                <Text.Heading
                  fontSize="20px"
                  color={Colors.Primary}
                  fontWeight={"600"}
                  text={data?.length}
                />
              </div>
            </div>
            <div style={{ width: "60%" }}>
              <div style={styles.cardContainerRow}>
                <div style={styles.flexColumnCenter}>
                  <img
                    src={Images.Exchange1}
                    alt=""
                    width={80}
                    style={{ marginBottom: "5px" }}
                  />
                  <Layout.Spacer height={10} />
                  <Text.Heading
                    fontSize={"16px"}
                    fontWeight={"500"}
                    color={Colors.GrayText}
                    text={"Country of Preference"}
                  />
                  <Text.Heading
                    fontSize="20px"
                    color={Colors.Primary}
                    fontWeight={"600"}
                    textTransform="capitalize"
                    text={
                      maxOccurenceSorted[0]?.location
                        ? maxOccurenceSorted[0]?.location
                        : "-"
                    }
                  />
                </div>
                <div style={styles.flexColumnCenter}>
                  <img
                    src={Images.Graduation}
                    alt=""
                    width={93}
                    style={{ marginBottom: "5px" }}
                  />
                  <Text.Heading
                    fontSize={"16px"}
                    fontWeight={"500"}
                    color={Colors.GrayText}
                    text={"Degree of Preference"}
                  />
                  <Text.Heading
                    fontSize="20px"
                    color={Colors.Primary}
                    fontWeight={"600"}
                    text={preferredProgram ? preferredProgram : "-"}
                  />
                </div>
              </div>
            </div>
          </div>
          <Layout.Spacer height={20} />
          <Text.Heading
            fontSize={"18px"}
            fontWeight={"600"}
            color={Colors.Primary}
            text={"Upcoming"}
          />
          <Layout.Spacer height={20} />
          <div style={styles.meetingsContainer}>
            {meetings?.length > 0 ? (
              meetings?.map((item, index) => {
                return (
                  <RowDiv
                    key={index}
                    onClick={() => {
                      navigate("/meetings/meetingdetails", {
                        state: { data: item, sent: "true" },
                      });
                    }}
                  >
                    <span>
                      <Text.Heading
                        fontSize={"14px"}
                        fontWeight={"600"}
                        color={Colors.BlackText}
                        textALign={"left"}
                        text={item.subject}
                        textTransform="capitalize"
                      />
                      <Text.Heading
                        fontSize={"10px"}
                        fontWeight={"400"}
                        color={Colors.GrayText}
                        textALign={"left"}
                        textTransform="capitalize"
                        text={`with ${item?.counselor?.fullName}`}
                      />
                    </span>
                    <span>
                      <AiFillRightCircle
                        fontSize={"30px"}
                        color={Colors.Primary}
                      />
                    </span>
                  </RowDiv>
                );
              })
            ) : (
              <Text.Heading
                fontSize={"16px"}
                fontWeight={"500"}
                color={Colors.GrayText}
                textAlign="center"
                text="No meetings in this month"
              />
            )}
          </div>
        </div>
        <div style={{ width: "30%" }}>
          <CustomCalendarStudent allMeetings={meetingsAll} />
        </div>
      </div>
    </>
  );
}

const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  padding: 10px 15px;
  border-radius: 10px;
  cursor: pointer;
  span:first-of-type {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 200px;
  }
  span:last-of-type {
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
  }
`;

export default General;
