import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Pagination, message } from "antd";

import { FaSearch } from "react-icons/fa";

import { StudentAction } from "../../store/actions";

import {
  Button,
  Layout,
  Modal,
  Table,
  Text,
  TextField,
} from "../../components";
import { Colors, Images } from "../../config";

import styled from "styled-components";
import styles from "./styles";

function Students() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const csvSelect = useRef();

  const students = useSelector((state) => state.student);
  const schoolIdState = useSelector((state) => state.auth.user.user.schoolId);

  const [search, setSearch] = useState("");
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [isFileSelected, setFileSelected] = useState(false);

  useEffect(() => {
    const payload = {
      schoolId: schoolIdState,
      search,
      limit,
      offset,
    };
    dispatch(StudentAction.getActiveStudents(payload));
    setPage(1);
  }, [search]);

  const handleDownload = () => {
    const fileUrl = Images.StudentTemplate;

    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = "Student Template.xlsx";

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };

  const handleSearchStudents = (e) => {
    setSearch(e.target.value);
  };

  const handlePaginationChange = (pageNumber) => {
    let offset = (pageNumber - 1) * limit;

    const payload = {
      schoolId: schoolIdState,
      search,
      limit,
      offset,
    };

    dispatch(StudentAction.getActiveStudents(payload));

    setOffset(offset);
    setPage(pageNumber);
  };

  const fileSelected = (event) => {
    const formData = new FormData();
    Array.from(event.target.files).forEach((file) => {
      formData.append("file", file);
    });
    formData.append("schoolId", schoolIdState);
    setFileSelected(true);
    onChange(formData);
    setTimeout(() => {
      setFileSelected(false);
    }, 1000);
  };

  const onChange = async (formData) => {
    dispatch(
      StudentAction.addSchoolStudentSheet(formData, (res) => {
        setShowModal(false);
        if (res.status === 500) {
          message.error(res.data.message);
        } else {
          message.success("Students are being added");
          const payload = {
            schoolId: schoolIdState,
            search,
            limit,
            offset,
          };
          dispatch(StudentAction.getActiveStudents(payload));
          setPage(1);
        }
      })
    );
  };

  return (
    <>
      <TextField.CustomInput
        placeholder="Search Students"
        suffix={<FaSearch color={Colors.Primary} />}
        iconPosition="left"
        style={styles.customTextfieldStyle}
        onChange={handleSearchStudents}
      />

      <Layout.Spacer height={20} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Text.Heading
          fontSize={"18px"}
          fontWeight={"600"}
          color={Colors.Primary}
          text="Students"
        />

        <Button.Primary
          style={styles.buttonStyle}
          onClick={() => setShowModal(true)}
        >
          Register New Student
        </Button.Primary>
      </div>
      <Layout.Spacer height={20} />
      <Table.AntDesign
        rowKey={(record) => record._id}
        columns={[
          {
            title: "Name",
            dataIndex: "name",
            className: "pointerCursor",
            width: 250,
            onCell: (record) => ({
              onClick: () => {
                if (record.status.toLowerCase() !== "pending") {
                  navigate(`/students/${record._id}`, {
                    state: {
                      registeredStudents: false,
                    },
                  });
                }
              },
            }),
            render: (text, record) => (
              <div>
                <img
                  src={
                    record?.profileImage
                      ? record?.profileImage
                      : Images.DummyUser
                  }
                  alt="img"
                  style={styles.imageStyle}
                />
                <Text.Heading style={styles.textStyle} text={record?.name} />
              </div>
            ),
          },
          {
            title: "Grade",
            dataIndex: "grade",
            align: "center",
            className: "pointerCursor",
            width: 200,
            onCell: (record) => ({
              onClick: () => {
                if (record.status.toLowerCase() !== "pending") {
                  navigate(`/students/${record._id}`, {
                    state: {
                      registeredStudents: false,
                    },
                  });
                }
              },
            }),
            render: (text, record) => (
              <span style={styles.textStyle}>{text}</span>
            ),
          },
          {
            title: "Email / Phone Number",
            dataIndex: "email",
            align: "center",
            className: "pointerCursor",
            width: 200,
            onCell: (record) => ({
              onClick: () => {
                if (record.status.toLowerCase() !== "pending") {
                  navigate(`/students/${record._id}`, {
                    state: {
                      registeredStudents: false,
                    },
                  });
                }
              },
            }),
            render: (text, record) => (
              <span style={{ ...styles.textStyle, textTransform: "lowercase" }}>
                {record.email
                  ? record.email
                  : "(+" +
                    record.phoneNumber.substr(0, 2) +
                    ") " +
                    record.phoneNumber.substr(2)}
              </span>
            ),
          },
          {
            title: "Number of Applications",
            dataIndex: "numberOfApplications",
            align: "center",
            className: "pointerCursor",
            width: 180,
            onCell: (record) => ({
              onClick: () => {
                if (record.status.toLowerCase() !== "pending") {
                  navigate(`/students/${record._id}`, {
                    state: {
                      registeredStudents: false,
                    },
                  });
                }
              },
            }),
            render: (text, record) => (
              <span style={styles.textStyle}>
                {record.status.toLowerCase() === "active"
                  ? record.applications.length
                  : "-"}
              </span>
            ),
          },
          {
            title: "Status",
            dataIndex: "activeStatus",
            align: "center",
            className: "pointerCursor",
            width: 100,
            onCell: (record) => ({
              onClick: () => {
                if (record.status.toLowerCase() !== "pending") {
                  navigate(`/students/${record._id}`, {
                    state: {
                      registeredStudents: false,
                    },
                  });
                }
              },
            }),
            render: (text, record) => (
              <span
                style={{
                  ...styles.statusText,
                  color:
                    record.status.toLowerCase() === "active"
                      ? Colors.Success
                      : Colors.Gray,
                }}
              >
                {record.status}
              </span>
            ),
          },
        ]}
        data={students?.activeStudents}
        loading={students?.isLoading}
        pagination={false}
      />
      <Layout.Spacer height={20} />
      <div style={{ textAlign: "center" }}>
        <Pagination
          responsive="true"
          showSizeChanger={false}
          hideOnSinglePage={false}
          current={page}
          pageSize={10}
          total={students?.count}
          onChange={handlePaginationChange}
        />
      </div>
      {showModal && (
        <Modal.Basic
          isModalVisible={showModal}
          centered={true}
          handleCancel={() => {
            setShowModal(false);
          }}
          closeIcon={true}
          className="ant-modal"
        >
          <Layout.Spacer height={20} />
          <div style={styles.uploadUniTitle}>Register Student</div>
          <Layout.Spacer height={20} />
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gridGap: "20px",
              alignItems: "center",
            }}
          >
            <div style={{ width: "100%" }}>
              <Button.Primary
                style={styles.addUniversityButton}
                onClick={() => navigate("/students/add-student")}
              >
                <span>Add Single</span>
              </Button.Primary>
            </div>
            <div style={{ width: "100%" }}>
              <input
                style={{ display: "none" }}
                type="file"
                name="csv"
                id="getFile"
                ref={csvSelect}
                onChange={fileSelected}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
              <Button.Primary
                style={styles.addUniversityButton}
                onClick={() => {
                  document.getElementById("getFile").click();
                }}
              >
                <span>Add Multiple</span>
              </Button.Primary>
            </div>
          </div>
          <Layout.Spacer height={20} />
          <div>
            <Button.Primary
              style={styles.addUniversityButton}
              onClick={() => {
                handleDownload();
              }}
            >
              Download Template
            </Button.Primary>
          </div>
        </Modal.Basic>
      )}
    </>
  );
}

export default Students;

const ColumnDiv = styled.span`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  img {
    width: 50px;
    height: 50px;
    object-fit: contain;
    margin-right: 20px;
  }
  span {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    span:first-of-type {
      font-size: 16px;
      font-weight: 500;
      color: ${Colors.Text};
    }
    span:last-of-type {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      color: ${Colors.Text};
    }
  }
`;
