import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { Dropdown } from "antd";

import { MdNotificationsActive, MdNotificationsNone } from "react-icons/md";

import { AiOutlineUser } from "react-icons/ai";

import {
  NotificationsAction,
  ProfileAction,
  ConverterAction,
} from "../../store/actions";

import { Colors, Images } from "../../config";
import { ProfileDropDown, NotificationDropDown } from "./ProfileDropDown";

import styles from "./styles";

const NavHeader = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const loginUser = useSelector((state) => state.auth.user.user);
  const notifications = useSelector((state) => state.notifications);
  const profile = useSelector((state) => state.profile);
  const currency = useSelector((state) => state.converter.usd);
  const path = location.pathname;

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    dispatch(ProfileAction.getUserProfile());
  }, []);

  useEffect(() => {
    const payload = {
      limit: "",
      offset: "",
      userId: loginUser._id ? loginUser._id : "",
    };
    dispatch(NotificationsAction.getNotifications(payload));
  }, [path]);

  const handleMouseEnter = () => {
    setShowPopup(true);
  };

  const handleMouseLeave = () => {
    setShowPopup(false);
  };

  const handleCurrency = () => {
    const payload = {
      usd: !currency,
    };
    dispatch(ConverterAction.updateConverter(payload));
  };

  return (
    <div style={styles.navbarContainer}>
      {!path.includes("/home") ? (
        <div
          onClick={() => {
            window.history.back();
          }}
          style={{ display: "flex" }}
        >
          <img src={Images.LeftArrowCircle} style={styles.backButton} />
        </div>
      ) : (
        <div></div>
      )}
      <div style={styles.navbarRightPart}>
        <div
          onClick={handleCurrency}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{
            position: "relative",
          }}
        >
          <img
            src={Images.Converter}
            height={30}
            style={{
              background: currency ? Colors.Primary : "",
              ...styles.currencyConverter,
            }}
          />
          {showPopup && (
            <div style={styles.currencyHover}>
              <div style={styles.currencyHoverText}>
                Click to toggle currency to USD
              </div>
            </div>
          )}
        </div>
        <Dropdown
          trigger="click"
          overlay={
            <NotificationDropDown notifications={notifications.notifications} />
          }
          placement="bottom"
        >
          {notifications.unreadCount > 0 ? (
            <div style={{ display: "flex" }}>
              <MdNotificationsActive
                style={styles.notificationBell}
                className="bell"
              />
            </div>
          ) : (
            <div style={{ display: "flex" }}>
              <MdNotificationsNone style={styles.notificationBell} />
            </div>
          )}
        </Dropdown>
        <Dropdown
          trigger="click"
          overlay={<ProfileDropDown />}
          placement="bottom"
        >
          {profile?.user?.profileImage ? (
            <img
              src={profile?.user?.profileImage}
              alt="user"
              style={styles.profileImage}
            />
          ) : (
            <AiOutlineUser style={styles.defaultImage} />
          )}
        </Dropdown>
        <div style={styles.profileContainer}>
          <div style={styles.profileName}>{profile?.user?.fullName}</div>
          <div style={styles.profileRole}>{loginUser?.role}</div>
        </div>
      </div>
    </div>
  );
};
export default NavHeader;
