import { put } from "redux-saga/effects";

import { ApiCaller, Utils } from "../../config";
import { ChatbotAction } from "../actions";

export default class ChatbotMiddleware {
  static *addMessage({ payload, cb }) {
    try {
      let res = yield ApiCaller.Post(`/chatbot/addMessage`, payload);
      if (res.status === 201) {
        yield put(ChatbotAction.addMessageSuccess(res.data.data));

        if (cb) {
          cb(res.data.data);
        }
      } else {
        yield put(ChatbotAction.addMessageFailure());
      }
    } catch (err) {
      yield put(ChatbotAction.addMessageFailure());
    }
  }

  static *getMessages({ payload, cb }) {
    try {
      let res = yield ApiCaller.Post(`/chatbot/getMessages`, payload);
      if (res.status === 201) {
        yield put(ChatbotAction.getMessagesSuccess(res.data.data));
        if (cb) {
          cb(res.data.data);
        }
      } else {
        yield put(ChatbotAction.getMessagesFailure());
      }
    } catch (err) {
      yield put(ChatbotAction.getMessagesFailure());
    }
  }
  static *getUnreadMessages({ payload, cb }) {
    try {
      let res = yield ApiCaller.Post(`/chatbot/getUnreadMessages`, payload);
      if (res.status === 201) {
        yield put(ChatbotAction.getUnreadMessagesSuccess(res.data.data));
        if (cb) {
          cb(res.data.data);
        }
      } else {
        yield put(ChatbotAction.getUnreadMessagesFailure());
      }
    } catch (err) {
      yield put(ChatbotAction.getUnreadMessagesFailure());
    }
  }
  static *getUsers({ payload, cb }) {
    try {
      let res = yield ApiCaller.Post(
        `/chatbot/getAdminsAndCounselors`,
        payload
      );
      if (res.status === 201) {
        yield put(ChatbotAction.getUsersSuccess(res.data.data));
        if (cb) {
          cb(res.data.data);
        }
      } else {
        yield put(ChatbotAction.getUsersFailure());
      }
    } catch (err) {
      yield put(ChatbotAction.getUsersFailure());
    }
  }
  static *getStudents({ payload, cb }) {
    try {
      let res = yield ApiCaller.Post(`/chatbot/getStudents`, payload);
      if (res.status === 201) {
        yield put(ChatbotAction.getStudentsSuccess(res.data.data));
        if (cb) {
          cb(res.data.data);
        }
      } else {
        yield put(ChatbotAction.getStudentsFailure());
      }
    } catch (err) {
      yield put(ChatbotAction.getStudentsFailure());
    }
  }
}
