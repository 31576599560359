import Accommodation from "../../assets/images/accommodation.svg";
import AdminIcon from "../../assets/images/adminIcon.svg";
import AnnouncementIcon from "../../assets/images/announcementLogo.svg";
import AnnouncementSidebarIcon from "../../assets/images/announcementIcon.svg";
import ApplicationIcon from "../../assets/images/application.png";
import ApplicationJourney1 from "../../assets/images/journey1.svg";
import ApplicationJourney2 from "../../assets/images/journey2.svg";
import ApplicationJourney3 from "../../assets/images/journey3.svg";
import ApplicationJourney4 from "../../assets/images/journey4.svg";
import ApplicationSidebarIcon from "../../assets/images/applicationIcon.svg";
import Approval from "../../assets/images/approval.svg";
import Book from "../../assets/images/book.svg";
import Bus2 from "../../assets/images/bus.svg";
import BusIcon from "../../assets/images/bus.png";
import CafeIcon from "../../assets/images/cafe.png";
import Calculator from "../../assets/images/calculator.svg";
import Campus from "../../assets/images/university-campus.svg";
import CarIcon from "../../assets/images/cars.png";
import ClassSize from "../../assets/images/presentation.svg";
import Clipboard from "../../assets/images/clipboard.svg";
import ComingSoon from "../../assets/images/comingSoon.gif";
import CountryIcon from "../../assets/images/earth.png";
import DummyUser from "../../assets/images/dummyUser.jpg";
import Duration from "../../assets/images/homework.svg";
import Education from "../../assets/images/education.svg";
import Emailcon from "../../assets/images/msgIcon.png";
import EmploymentIcon from "../../assets/images/employment.png";
import Exam from "../../assets/images/exam.svg";
import Exchange1 from "../../assets/images/world.svg";
import ExchangeIcon from "../../assets/images/exchange.png";
import Facilities from "../../assets/images/school.svg";
import FacultyStudentRatio from "../../assets/images/facultyStudentRatio.svg";
import FemaleStudent from "../../assets/images/female.png";
import FinanceIcon from "../../assets/images/finance.png";
import Flag1 from "../../assets/images/flag1.png";
import Flag2 from "../../assets/images/flag2.png";
import Flag3 from "../../assets/images/flag3.png";
import Flag4 from "../../assets/images/flag4.png";
import FocusIcon from "../../assets/images/focus.png";
import Food from "../../assets/images/fast-food.svg";
import FullLogo from "../../assets/images/univisionLoginText.png";
import GraduatingFemale from "../../assets/images/graduatingFemale.png";
import GraduatingMale from "../../assets/images/graduatingMale.png";
import Graduation from "../../assets/images/graduation-hat.svg";
import GraduationCap from "../../assets/images/graduationCap.png";
import HomeIcon from "../../assets/images/homeIcon.png";
import HomePhoto1 from "../../assets/images/homePhoto.png";
import HourGlassIcon from "../../assets/images/hourglass.png";
import Interview from "../../assets/images/interview.svg";
import JobIcon from "../../assets/images/job.png";
import LeftArrowCircle from "../../assets/images/arrowLeftCircle.svg";
import LinkedInIcon from "../../assets/images/linkedInIcon.png";
import Loan from "../../assets/images/loan.svg";
import Location from "../../assets/images/location.svg";
import LoginBoxImage from "../../assets/images/graphic.svg";
import Logo from "../../assets/images/Logo.svg";
import LogoIcon from "../../assets/images/Icon.png";
import LogoutIcon from "../../assets/images/logoutIcon.png";
import MaleFemaleRatio from "../../assets/images/graduates.svg";
import MaleStudent from "../../assets/images/male-student.svg";
import Map from "../../assets/images/map.png";
import Map2 from "../../assets/images/map2.png";
import Medical from "../../assets/images/cardiogram.svg";
import MeetingIcon from "../../assets/images/meetingIcon.svg";
import Metro from "../../assets/images/train.svg";
import Misc from "../../assets/images/bill.svg";
import MoneyBag from "../../assets/images/budget1.svg";
import NoData from "../../assets/images/no-data.svg";
import Others from "../../assets/images/pending.svg";
import PeopleIcon from "../../assets/images/people.png";
import PhoneIcon from "../../assets/images/phoneIcon.png";
import Placement from "../../assets/images/graduate.svg";
import PostGradSalary from "../../assets/images/postgradSalary.png";
import PrintIcon from "../../assets/images/print.png";
import Processing from "../../assets/images/work-process.svg";
import Profile from "../../assets/images/profileImg.png";
import Ranking from "../../assets/images/ranking.svg";
import RequestIcon from "../../assets/images/requestIcon.png";
import Research from "../../assets/images/research.svg";
import SalaryIcon from "../../assets/images/salary.png";
import SchoolCover from "../../assets/images/schoolCover.png";
import SchoolIcon from "../../assets/images/schoolIcon.png";
import SchoolProfile from "../../assets/images/schoolProfile.png";
import ShuttleIcon from "../../assets/images/shuttle.png";
import SortIcon from "../../assets/images/sortIcon.png";
import Student1 from "../../assets/images/student1.png";
import Student2 from "../../assets/images/student2.png";
import Student3 from "../../assets/images/student3.png";
import StudentsGroup from "../../assets/images/students.png";
import Thumb from "../../assets/images/thumbs-up.svg";
import Time from "../../assets/images/sand-clock.svg";
import TrainIcon from "../../assets/images/train.png";
import Uni1 from "../../assets/images/uni-1.png";
import Uni2 from "../../assets/images/uni-2.png";
import Uni3 from "../../assets/images/uni-3.png";
import Uni4 from "../../assets/images/uni-4.png";
import Uni5 from "../../assets/images/uni-5.png";
import Uni6 from "../../assets/images/uni-6.png";
import Uni7 from "../../assets/images/uni-7.png";
import UniversityDefault from "../../assets/images/universityDefault.png";
import UniversityIcon from "../../assets/images/universityIcon.png";
import UniversityStanding from "../../assets/images/standing.png";
import Visa from "../../assets/images/success.svg";
import WebsiteIcon from "../../assets/images/websiteIcon.png";
import MaleToFemaleRatio from "../../assets/images/graduates.svg";
import BackgroundSource from "../../assets/videos/background video.mp4";
import DefaultRestaurant from "../../assets/images/defaultRestaurant.png";
import Journey1 from "../../assets/images/journey1.svg";
import Journey2 from "../../assets/images/journey2.svg";
import Journey3 from "../../assets/images/journey3.svg";
import Journey4 from "../../assets/images/journey4.svg";
import GroupGraduation from "../../assets/images/groupGraduation.jpg";
import MeetingDetails from "../../assets/images/meetingDetails.png";
import HousingCost from "../../assets/images/housingCost.png";
import TotalApplication from "../../assets/images/totalApplication.svg";
import Promotion from "../../assets/images/promotion.png";
import MedicalIcon from "../../assets/images/medical.svg";
import Science from "../../assets/images/science.svg";
import SocialScience from "../../assets/images/socialScience.svg";
import Law from "../../assets/images/law.svg";

import Admission from "../../assets/images/admission.svg";
import Deadline from "../../assets/images/deadline.svg";
import Sort from "../../assets/images/sort.png";
import Apply from "../../assets/images/apply.svg";
import TotalStudents from "../../assets/images/student.svg";
import ActiveStudents from "../../assets/images/morning-routine.svg";
import Whatsapp from "../../assets/images/whatsapp.svg";
import Business from "../../assets/images/business.svg";
import DateRange from "../../assets/images/calendar.svg";
import Converter from "../../assets/images/converter.svg";
import LoginBackground from "../../assets/images/schoolPortalBackground.jpg";
import Chatbot from "../../assets/images/chatbot.svg";

import StudentTemplate from "../../assets/documents/students.xlsx";
const Images = {
  StudentTemplate,
  Chatbot,
  LoginBackground,
  Converter,
  DateRange,
  Whatsapp,
  ActiveStudents,
  TotalStudents,
  Apply,
  Sort,
  Admission,
  Deadline,
  MedicalIcon,
  Science,
  SocialScience,
  Law,
  Business,
  Promotion,
  TotalApplication,
  HousingCost,
  MeetingDetails,
  GroupGraduation,
  Journey1,
  Journey2,
  Journey3,
  Journey4,
  DefaultRestaurant,
  MaleToFemaleRatio,
  LoginBoxImage,
  Logo,
  LogoIcon,
  HomeIcon,
  UniversityIcon,
  SchoolIcon,
  LogoutIcon,
  Uni1,
  Uni2,
  Uni3,
  Uni4,
  Uni5,
  Uni6,
  Uni7,
  SchoolProfile,
  SchoolCover,
  WebsiteIcon,
  Emailcon,
  PhoneIcon,
  LinkedInIcon,
  Student1,
  Student2,
  Student3,
  ApplicationIcon,
  CountryIcon,
  GraduationCap,
  Map,
  PeopleIcon,
  SortIcon,
  RequestIcon,
  BackgroundSource,
  FullLogo,
  LeftArrowCircle,
  Flag1,
  Flag2,
  Flag3,
  Flag4,
  GraduatingMale,
  GraduatingFemale,
  PrintIcon,
  CafeIcon,
  JobIcon,
  ShuttleIcon,
  TrainIcon,
  BusIcon,
  CarIcon,
  ExchangeIcon,
  HourGlassIcon,
  FocusIcon,
  FinanceIcon,
  EmploymentIcon,
  SalaryIcon,
  ApplicationJourney1,
  ApplicationJourney2,
  ApplicationJourney3,
  ApplicationJourney4,
  Profile,
  UniversityStanding,
  UniversityDefault,
  AnnouncementIcon,
  DummyUser,
  ComingSoon,
  AdminIcon,
  AnnouncementSidebarIcon,
  MeetingIcon,
  ApplicationSidebarIcon,
  Education,
  ClassSize,
  FacultyStudentRatio,
  Thumb,
  Location,
  Clipboard,
  Research,
  HomePhoto1,
  Exam,
  MaleStudent,
  FemaleStudent,
  Accommodation,
  Food,
  Others,
  Facilities,
  NoData,
  Bus2,
  Metro,
  Exchange1,
  MoneyBag,
  Misc,
  Medical,
  Time,
  Visa,
  Processing,
  Interview,
  Calculator,
  Loan,
  Placement,
  Graduation,
  PostGradSalary,
  Ranking,
  Book,
  Duration,
  Campus,
  Approval,
  MaleFemaleRatio,
  StudentsGroup,
  Map2,
};

export default Images;
