import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { CardElement } from "@stripe/react-stripe-js";

import Lottie from "react-lottie-player";

import { AiFillCloseCircle } from "react-icons/ai";
import { BsFillPatchQuestionFill } from "react-icons/bs";

import { BillingAction } from "../../store/actions";

import { Button, Layout, Modal, Text, dateFormatter } from "../../components";
import { Colors } from "../../config";

import styles from "./styles";

import Done from "../../assets/images/57767-done.json";

function BillingForm() {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const schoolId = useSelector((state) => state.auth.user.user.schoolId);
  const billingLoading = useSelector((state) => state.billing.billings);
  const billingInfo = useSelector(
    (state) => state.billing.billings.updatedBilling
  );

  const [isDisabled, setDisabled] = useState(false);
  const [isCancelDisabled, setCancelDisabled] = useState(false);
  const [buttonText, setButtonText] = useState("Add Subscription");
  const [cancelButtonText, setCancelButtonText] = useState(
    "Cancel Subscription"
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [successModal, setSuccessModal] = useState(false);
  const [failModal, setFailModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [cancelConfirmModal, setCancelConfirmModal] = useState(false);

  const createSubscription = async () => {
    try {
      setButtonText("Adding...");
      setDisabled(true);
      const paymentMethod = await stripe.createPaymentMethod({
        card: elements.getElement("card"),
        type: "card",
      });

      const payload = {
        paymentMethod: paymentMethod.paymentMethod.id,
        schoolId: billingInfo.schoolId,
      };
      dispatch(
        BillingAction.addBilling(payload, async (res) => {
          if (res.status === 201) {
            const confirm = await stripe.confirmCardPayment(
              res.data.clientSecret
            );

            if (confirm.error) return alert("Payment unsuccessful!");
            setSuccessMessage("Payment Successful! Subscription active.");
            setSuccessModal(true);
            setDisabled(false);
            setButtonText("Add Subscription");
            dispatch(BillingAction.getBilling({ schoolId: schoolId }));
          } else {
            setErrorMessage(res.data.message);
            setFailModal(true);
            setDisabled(false);
            setButtonText("Add Subscription");
          }
        })
      );
    } catch (err) {
      console.error(err);
      alert("Payment failed! " + err.message);
    }
  };

  const cancelSubscription = async () => {
    try {
      setCancelButtonText("Cancelling...");
      setCancelDisabled(true);
      setCancelConfirmModal(false);
      const payload = {
        schoolId: billingInfo.schoolId,
        cancelRequest: true,
      };
      dispatch(
        BillingAction.updateBilling(payload, async (res) => {
          setCancelModal(true);
          setCancelDisabled(false);
          setCancelButtonText("Cancel Subscription");
          dispatch(BillingAction.getBilling({ schoolId: schoolId }));
        })
      );
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    dispatch(BillingAction.getBilling({ schoolId: schoolId }));
  }, []);

  return (
    <div>
      <div
        style={{
          width: "50%",
        }}
      >
        {billingLoading?.isLoading ? (
          <div></div>
        ) : (
          billingInfo?.billingStatus !== "active" && (
            <div style={{ color: "red", fontWeight: "600" }}>
              Your account will have limited access after{" "}
              {dateFormatter(billingInfo?.validUntil)}
            </div>
          )
        )}

        {billingInfo?.cancelRequest === true && (
          <div style={{ color: "black" }}>
            Your subscription cancellation request is pending approval from
            admin.
          </div>
        )}

        {billingInfo?.billingStatus === "active" &&
        billingInfo?.cancelRequest === false ? (
          <>
            <Layout.Spacer height={20} />
            <Button.Primary
              style={{
                ...styles.buttonStyle,
                width: "200px",
                backgroundColor: "red",
              }}
              onClick={() => setCancelConfirmModal(true)}
              disabled={isCancelDisabled}
            >
              {cancelButtonText}
            </Button.Primary>
          </>
        ) : (
          billingInfo?.billingStatus !== "active" && (
            <div>
              <Layout.Spacer height={20} />
              <Text.Heading
                fontSize={"18px"}
                fontWeight={"500"}
                color={Colors.Primary}
                text="Add Card"
              />
              <Layout.Spacer height={10} />
              <div style={styles.cardContainer}>
                <CardElement />
              </div>
              <Layout.Spacer height={20} />
              <Button.Primary
                style={{ ...styles.buttonStyle, width: "200px" }}
                onClick={createSubscription}
                disabled={isDisabled}
              >
                {buttonText}
              </Button.Primary>
            </div>
          )
        )}
      </div>

      {successModal && (
        <Modal.Basic
          isModalVisible={successModal}
          centered={true}
          handleCancel={() => setSuccessModal(false)}
          closable={false}
        >
          <div style={styles.flexColumnCenter}>
            <Lottie
              loop
              animationData={Done}
              play
              style={{ width: "100px" }}
            ></Lottie>

            <Text.Heading
              fontSize={"26px"}
              fontWeight={"700"}
              color={Colors.Black}
              text="Successful!"
            />

            <Text.Heading
              fontSize={"18px"}
              fontWeight={"400"}
              color={Colors.Black}
              text={successMessage}
            />
            <Layout.Spacer height={20} />
            <Button.Primary
              style={styles.modalButton}
              onClick={() => {
                setSuccessModal(false);
              }}
            >
              Okay
            </Button.Primary>
          </div>
        </Modal.Basic>
      )}
      {failModal && (
        <Modal.Basic
          isModalVisible={failModal}
          centered={true}
          handleCancel={() => setFailModal(false)}
          closable={false}
        >
          <div style={styles.flexColumnCenter}>
            <AiFillCloseCircle style={styles.redIcon} />

            <Text.Heading
              fontSize={"26px"}
              fontWeight={"700"}
              color={Colors.Red}
              text="Unsuccessful!"
            />

            <Text.Heading
              fontSize={"18px"}
              fontWeight={"400"}
              color={Colors.Black}
              text={errorMessage}
            />
            <Layout.Spacer height={20} />
            <div>
              <Button.Primary
                style={styles.modalButton}
                onClick={() => {
                  setFailModal(false);
                }}
              >
                Okay
              </Button.Primary>
            </div>
          </div>
        </Modal.Basic>
      )}
      {cancelModal && (
        <Modal.Basic
          isModalVisible={cancelModal}
          centered={true}
          handleCancel={() => setCancelModal(false)}
          closable={false}
        >
          <div style={styles.flexColumnCenter}>
            <Lottie
              loop
              animationData={Done}
              play
              style={{ width: "100px" }}
            ></Lottie>

            <Text.Heading
              fontSize={"26px"}
              fontWeight={"700"}
              color={Colors.Black}
              text="Request Sent!"
            />

            <Text.Heading
              fontSize={"18px"}
              fontWeight={"400"}
              color={Colors.Black}
              text={
                "Your cancellation request has been sent to the admin. For further queries please contact admin@univsion.hk."
              }
            />
            <Layout.Spacer height={20} />

            <Button.Primary
              style={styles.modalButton}
              onClick={() => {
                setCancelModal(false);
              }}
            >
              Okay
            </Button.Primary>
          </div>
        </Modal.Basic>
      )}
      {cancelConfirmModal && (
        <Modal.Basic
          isModalVisible={cancelConfirmModal}
          centered={true}
          handleCancel={() => setCancelConfirmModal(false)}
          closable={false}
        >
          <div style={styles.flexColumnCenter}>
            <BsFillPatchQuestionFill style={styles.redIcon} />

            <Text.Heading
              fontSize={"18px"}
              fontWeight={"400"}
              color={Colors.Black}
              text="Are you sure you want to cancel your subscription?"
            />
            <Layout.Spacer height={20} />
            <div style={{ display: "flex", gap: "20px" }}>
              <Button.Primary
                style={styles.modalButton}
                onClick={() => {
                  setCancelConfirmModal(false);
                }}
              >
                No
              </Button.Primary>
              <Button.Primary
                style={{
                  ...styles.modalButton,
                  backgroundColor: Colors.Red,
                }}
                onClick={cancelSubscription}
              >
                Yes
              </Button.Primary>
            </div>
          </div>
        </Modal.Basic>
      )}
    </div>
  );
}

export default BillingForm;
