import { Colors, Images } from "../../config";

const styles = {
  // flex styles
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  flexRowCenter: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  rowStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  flexJustifyCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  flexColumnCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  flexColumnStart: {
    alignSelf: "flex-start",
  },

  // dashboard
  graphImgBox: {
    position: "relative",
  },
  graphImg: {
    width: "100%",
  },
  graphImg1: {
    position: "absolute",
    top: "15%",
    left: "8%",
    width: "50px",
    height: "50px",
    borderRadius: "100%",
    backgroundColor: "white",
    objectFit: "contain",
  },
  graphImg2: {
    position: "absolute",
    top: "0%",
    left: "42%",
    width: "50px",
    height: "50px",
    borderRadius: "100%",
    backgroundColor: "white",
    objectFit: "contain",
  },
  graphImg3: {
    position: "absolute",
    top: "15%",
    right: "7%",
    width: "50px",
    height: "50px",
    borderRadius: "100%",
    backgroundColor: "white",
    objectFit: "contain",
  },
  textContainer: {
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  cursor: {
    cursor: "pointer",
  },

  universityDropdown: {
    color: Colors.Primary,
    width: "100%",
    borderColor: Colors.Primary,
    borderRadius: "10px",
    padding: "10px",
    textTransform: "capitalize",
  },
  cardContainerRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    background: Colors.LightGray,
    width: "100%",
    borderRadius: "10px",
    height: "auto",
    padding: "30px 20px",
  },

  announcementDivContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridColumnGap: "20px",
    width: "100%",
  },
  announcementContainer: {
    display: "flex",
    flexDirection: "column",
    background: Colors.LightGray,
    borderRadius: "10px",
    padding: "20px",
    height: "100px",
    width: "100%",
  },

  popularContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: "20px",
  },

  universityRankingContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: Colors.LightGray,
    borderRadius: "10px",
    width: "100%",
    height: "500px",
    padding: "20px 35px 20px 35px",
  },

  popularUniRow: {
    display: "flex",
    width: "100%",
    padding: "5px 0px",
    alignItems: "center",
  },
  numberBox: {
    width: "40px",
    height: "40px",
    padding: "5px",
    borderRadius: "100%",
    backgroundColor: Colors.Primary,
    marginRight: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  flagStyle: {
    width: "auto",
    height: "26px",
    marginRight: "20px",
    borderRadius: "5px",
  },
  locationIcon: {
    color: Colors.GrayText,
    marginRight: "5px",
    fontSize: "12px",
  },
  popularUniOccurrence: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    background: Colors.LightGray,
    borderRadius: "10px",
    width: "100%",
    padding: "15px 20px",
    marginBottom: "15px",
  },
  mapContainer: {
    background: Colors.LightGray,
    borderRadius: "10px",
    width: "100%",
    height: "60vh",
    padding: "20px",
  },
  map: {
    width: "100%",
    height: "100%",
    padding: "20px",
    position: "relative",
    background: `url(${Images.Map2})`,
    backgroundSize: "cover",
  },
  mapValue: {
    color: "white",
    fontSize: "15px",
    margin: "0px",
  },
  mapCircles: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#479c90",
    padding: "10px",
    borderRadius: "100%",
    height: "50px",
    width: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  studentsAppliedContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: Colors.LightGray,
    borderRadius: "10px",
    width: "100%",
    padding: "20px 0px",
  },
  appliedPercentageText: {
    fontSize: "15px",
    fontWeight: "500",
    color: Colors.BlackText,
  },
  appliedBox: {
    background: Colors.Primary,
    width: "60px",
    height: "20px",
  },
  notAppliedBox: {
    background: "#214854",
    width: "60px",
    height: "20px",
  },
  popularMajorContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    background: Colors.LightGray,
    borderRadius: "10px",
    width: "100%",
    padding: "13px 20px",
    marginBottom: "10px",
  },
  studentPercentageContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: Colors.LightGray,
    borderRadius: "10px",
    width: "100%",
    padding: "20px 0px",
  },
  dateRangeText: {
    color: "black",
    fontWeight: "600",
    fontSize: "12px",
    cursor: "pointer",
  },

  meetingDatePickerDiv: {
    animation: "slide-down 0.3s ease",
  },
  meetingDatePickerText: {
    color: "black",
    fontWeight: "600",
    fontSize: "12px",
    textAlign: "right",
    paddingRight: "10px",
  },
  meetingDatePicker: {
    width: "100%",
    fontSize: "12px",
    fontWeight: "400",
    color: Colors.BlackText,
    height: "25px",
    borderColor: Colors.Primary,
    borderRadius: "7px",
    padding: "10px",
  },
  meetingSubmitButton: {
    padding: "5px 10px",
    backgroundColor: Colors.Primary,
    borderRadius: "10px",
    width: "100px",
    textAlign: "center",
    cursor: "pointer",
  },
  noMeetingsText: {
    color: "black",
    fontSize: "14px",
    fontWeight: "500",
  },
  meetingsContainer: { maxHeight: "700px", overflowY: "auto" },
};

export default styles;
