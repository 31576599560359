import { Colors } from "../../config";

const styles = {
  flexColumnCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  buttonStyle: {
    width: "100%",
    backgroundColor: Colors.Primary,
    borderRadius: "15px",
  },

  // email form
  signinText: {
    textDecoration: "none",
    color: Colors.Primary,
    fontSize: "14px",
    marginLeft: "5px",
    fontWeight: "500",
  },

  // OTP form
  otpFormContainer: {
    width: "100%",
    margin: "0 auto",
  },
  otpCodeContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  codeInput: {
    border: `2px solid #ccc`,
    borderRadius: "5px",
    fontSize: "38px",
    color: Colors.Primary,
    fontWeight: "500",
    height: "70px",
    width: "70px",
    margin: "0 10px",
    textAlign: "center",
  },
  otpErrorText: {
    fontSize: "16px",
    color: "red",
    marginBottom: "15px",
    display: "flex",
    justifyContent: "center",
  },

  resendContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "auto",
  },
  resendLabel: { color: Colors.LabelText, fontSize: "18px" },
  counterText: {
    marginLeft: "5px",
    fontSize: "18px",
    color: "red",
  },
  resendText: {
    textDecoration: "none",
    color: Colors.Primary,
    fontSize: "18px",
    marginLeft: "5px",
    cursor: "pointer",
  },

  // password
  passwordContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    position: "relative",
  },
  eyeIcon: {
    position: "absolute",
    right: "10",
    top: "10",
    cursor: "pointer",
  },

  // details
  imageUpload: {
    width: "100%",
    border: `1px solid ${Colors.Primary}`,
    borderRadius: "6px",
    padding: "6px 12px",
    color: "black",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
  },
  // modal
  modalButton: {
    backgroundColor: Colors.Primary,
    fontWeight: "700",
    fontSize: "16px",
    borderRadius: "30px",
    width: "100px",
  },
};

export default styles;
