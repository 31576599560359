import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "antd";

import Lottie from "react-lottie-player";

import { AnnouncementAction } from "../../store/actions";

import { Button, Layout, Modal, Text, CardRow } from "../../components";
import { Colors } from "../../config";

import styles from "./styles";

import Done from "../../assets/images/announcement.json";

function Announcements() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const schoolIdState = useSelector((state) => state.auth.user.user.schoolId);
  const announcements = useSelector((state) => state.announcement);

  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [announcement, setAnnouncement] = useState(null);

  useEffect(() => {
    const payload = {
      limit,
      offset,
      schoolId: schoolIdState,
    };
    dispatch(AnnouncementAction.getAnnouncements(payload));
  }, []);

  const handleReadAnnouncement = (state) => {
    let payload = {
      id: state?._id,
      schoolId: schoolIdState,
    };
    handleChangeAnnouncementStatus(payload);
  };

  const handleShowAnnouncement = (state) => {
    setAnnouncement(state);
    setShowModal(true);
  };

  const handlePaginationChange = (pageNumber) => {
    let offset = (pageNumber - 1) * limit;
    setOffset(offset);
    setPage(pageNumber);
    const payload = {
      limit,
      offset: offset,
      schoolId: schoolIdState,
    };
    dispatch(AnnouncementAction.getAnnouncements(payload));
  };

  const handleChangeAnnouncementStatus = (data) => {
    dispatch(
      AnnouncementAction.changeAnnouncementStatus(data, () => {
        const payload = {
          limit,
          offset: 0,
          schoolId: schoolIdState,
        };
        dispatch(AnnouncementAction.getAnnouncements(payload));
        setPage(1);
      })
    );
  };

  return (
    <>
      <Text.Heading
        fontSize={"18px"}
        fontWeight={"600"}
        color={Colors.Primary}
        text="Announcements"
      />

      <Layout.Spacer height={20} />
      {announcements?.announcements?.map((announcement, index) => (
        <div key={index}>
          <CardRow
            data={announcement}
            announcement={true}
            handleReadAnnouncement={handleReadAnnouncement}
            handleShowAnnouncement={handleShowAnnouncement}
          />
        </div>
      ))}
      <Layout.Spacer height={10} />

      <div style={styles.justifyCenter}>
        <Pagination
          responsive="true"
          showSizeChanger={false}
          hideOnSinglePage={false}
          current={page}
          pageSize={10}
          total={announcements.count}
          onChange={handlePaginationChange}
        />
      </div>
      <Layout.Spacer height={10} />

      <div style={styles.justifyCenter}>
        <Button.Primary
          style={styles.buttonStyle}
          onClick={() => navigate("/announcements/add-announcement")}
        >
          New Announcement
        </Button.Primary>
      </div>
      <Layout.Spacer height={20} />
      {showModal && (
        <Modal.Basic
          isModalVisible={showModal}
          centered={true}
          handleCancel={() => setShowModal(false)}
          closable={false}
          className="ant-modal"
        >
          <div style={styles.justifyCenter}>
            <Lottie
              loop
              animationData={Done}
              play
              style={{ width: "120px", height: "120px" }}
            ></Lottie>
          </div>

          <Layout.Spacer height={10} />

          <div style={styles.justifyCenter}>
            <Text.Heading
              fontSize={"18px"}
              fontWeight={"600"}
              color={Colors.Primary}
              text={announcement?.subject}
            />
          </div>

          <Layout.Spacer height={10} />

          <div style={styles.justifyCenter}>
            <Text.Heading
              fontSize={"14px"}
              fontWeight={"400"}
              color={Colors.GrayText}
              text={announcement?.message}
            />
          </div>

          <Layout.Spacer height={30} />
          <div style={styles.justifyCenter}>
            <Button.Primary
              style={styles.buttonStyle}
              onClick={() => {
                setShowModal(false);
                setAnnouncement(null);
              }}
            >
              Continue
            </Button.Primary>
          </div>
          <Layout.Spacer height={10} />
        </Modal.Basic>
      )}
    </>
  );
}

export default Announcements;
