import { put } from "redux-saga/effects";

import { ApiCaller, Utils } from "../../config";
import { StudentAction } from "../actions";

export default class StudentMiddleware {
  static *addSchoolStudent({ payload, cb }) {
    try {
      let res = yield ApiCaller.Post(`/school/add-student`, payload);
      if (res.status === 201) {
        yield put(StudentAction.addSchoolStudentSuccess(res.data.data));
        if (cb) {
          cb(res);
        }
      } else {
        yield put(StudentAction.addSchoolStudentFailure());
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
        if (cb) {
          cb(res);
        }
      }
    } catch (err) {
      yield put(StudentAction.addSchoolStudentFailure());
    }
  }
  static *addSchoolStudentSheet({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(`/school/add-student-sheet`, payload);
      if (res.status === 201) {
        yield put(StudentAction.addSchoolStudentSheetSuccess(res.data.data));
      } else {
        yield put(StudentAction.addSchoolStudentSheetFailure());
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
      }
      if (cb) {
        cb(res);
      }
    } catch (err) {
      yield put(StudentAction.addSchoolStudentSheetFailure());
    }
  }

  static *getActiveStudents({ payload, cb }) {
    try {
      const { search, schoolId, limit, offset } = payload;
      let res;
      if (search) {
        res = yield ApiCaller.Get(
          `/school/filter-students?schoolId=${schoolId}&searchInput=${search}&limit=${limit}&offset=${offset}`
        );
      } else {
        res = yield ApiCaller.Get(
          `/school/filter-students?schoolId=${schoolId}&limit=${limit}&offset=${offset}`
        );
      }
      if (res.status === 200) {
        yield put(StudentAction.getActiveStudentsSuccess(res.data.data));
        if (cb) {
          cb();
        }
      } else {
        yield put(StudentAction.getActiveStudentsSuccess([]));
        // Utils.showSnackBar({
        //     message: res.data.message,
        //     severity: 'error',
        //   });
      }
    } catch (err) {
      yield put(StudentAction.getActiveStudentsFailure());
    }
  }

  static *getRegisteredStudents({ payload, cb }) {
    try {
      const { search, schoolId, limit, offset } = payload;
      let res;
      if (search) {
        res = yield ApiCaller.Get(
          `/school/get-registered-students?schoolId=${schoolId}&searchInput=${search}&limit=${limit}&offset=${offset}`
        );
      } else {
        res = yield ApiCaller.Get(
          `/school/get-registered-students?schoolId=${schoolId}&limit=${limit}&offset=${offset}`
        );
      }
      if (res.status === 200) {
        yield put(StudentAction.getRegisteredStudentsSuccess(res.data.data));
        if (cb) {
          cb();
        }
      } else {
        yield put(StudentAction.getRegisteredStudentsSuccess([]));
        // Utils.showSnackBar({
        //     message: res.data.message,
        //     severity: 'error',
        //   });
      }
    } catch (err) {
      yield put(StudentAction.getRegisteredStudentsFailure());
    }
  }

  static *getSchoolActiveStudents({ schoolId, cb }) {
    try {
      let res = yield ApiCaller.Get(
        `/school/get-school-active-students?schoolId=${schoolId}`
      );
      if (res.status === 200) {
        yield put(StudentAction.getSchoolActiveStudentsSuccess(res.data.data));
        if (cb) {
          cb();
        }
      } else {
        yield put(StudentAction.getSchoolActiveStudentsSuccess([]));
      }
    } catch (err) {
      yield put(StudentAction.getSchoolActiveStudentsFailure());
    }
  }

  static *getStudentDetails({ id, cb }) {
    try {
      let res = yield ApiCaller.Get(
        `/school/get-student-detail?studentId=${id}`
      );
      if (res.status === 200) {
        yield put(StudentAction.getStudentDetailsSuccess(res.data.data));
        if (cb) {
          cb();
        }
      } else {
        yield put(StudentAction.getStudentDetailsFailure());
        // Utils.showSnackBar({
        //   message: res.data.message,
        //   severity: 'error',
        // });
      }
    } catch (err) {
      yield put(StudentAction.getStudentDetailsFailure());
    }
  }

  static *getStudentMeetingDetails({ id, cb }) {
    try {
      let res = yield ApiCaller.Get(
        `/miscellaneous/fetchMeetingBasedOnDate?organizer=Student&studentId=${id}`
      );
      if (res.status === 200) {
        yield put(StudentAction.getStudentMeetingDetailsSuccess(res.data.data));
        if (cb) {
          cb();
        }
      } else {
        yield put(StudentAction.getStudentMeetingDetailsFailure());
      }
    } catch (err) {
      yield put(StudentAction.getStudentMeetingDetailsFailure());
    }
  }

  static *getStudentAllMeetingDetails({ id, cb }) {
    try {
      let res = yield ApiCaller.Get(
        `/miscellaneous/fetchAllMeetingsAndCount?organizer=Student&studentId=${id}`
      );
      if (res.status === 200) {
        yield put(
          StudentAction.getStudentAllMeetingDetailsSuccess(res.data.data)
        );
        if (cb) {
          cb(res.data.data);
        }
      } else {
        yield put(StudentAction.getStudentAllMeetingDetailsFailure());
      }
    } catch (err) {
      yield put(StudentAction.getStudentAllMeetingDetailsFailure());
    }
  }

  static *getRegisteredStudentDetails({ id, cb }) {
    try {
      let res = yield ApiCaller.Get(
        `/school/get-registered-student-detail?studentId=${id}`
      );
      if (res.status === 200) {
        yield put(
          StudentAction.getRegisteredStudentDetailsSuccess(res.data.data)
        );
        if (cb) {
          cb();
        }
      } else {
        yield put(StudentAction.getRegisteredStudentDetailsFailure());
        // Utils.showSnackBar({
        //   message: res.data.message,
        //   severity: 'error',
        // });
      }
    } catch (err) {
      yield put(StudentAction.getRegisteredStudentDetailsFailure());
    }
  }

  static *getStudentsDetails({ schoolId, cb }) {
    try {
      let studentsData = [];
      let res = yield ApiCaller.Get(
        `/school/filter-students?schoolId=${schoolId}`
      );
      if (res.status === 200) {
        const students = res?.data?.data?.data;
        for (let i = 0; i < students.length; i++) {
          let res = yield ApiCaller.Get(
            `/school/get-student-detail?studentId=${students[i]?._id}`
          );
          if (res.status === 200) {
            studentsData.push(res.data.data.studentDetails);
          } else {
            yield put(StudentAction.getStudentDetailsFailure());
          }
        }
        yield put(StudentAction.getStudentsDetailsSuccess(studentsData));
      } else {
        yield put(StudentAction.getStudentsDetailsSuccess([]));
      }
    } catch (err) {
      yield put(StudentAction.getStudentsDetailsFailure());
    }
  }

  static *sendStudentEmail({ payload, cb }) {
    try {
      let res = yield ApiCaller.Post(`/school/add-student-by-email`, payload);
      if (res.status === 201) {
        yield put(StudentAction.sendStudentEmailSuccess(res.data.data));
        Utils.showSnackBar({
          message: res.data.message,
        });
        if (cb) {
          cb();
        }
      } else {
        yield put(StudentAction.sendStudentEmailFailure());
        // Utils.showSnackBar({
        //   message: res.data.message,
        //   severity: 'error',
        // });
      }
    } catch (err) {
      yield put(StudentAction.sendStudentEmailFailure());
    }
  }
}
