import moment from "moment";

import { AiOutlineBell, AiFillRightCircle } from "react-icons/ai";
import { MdOutlineCalendarToday } from "react-icons/md";

import { Colors, Images } from "../../config";
import { Text, dateFormatter } from "../../components";

import styled from "styled-components";
import styles from "./styles";

function CardRow({
  data,
  announcement,
  application,
  meeting,
  handleReadAnnouncement,
  handleShowAnnouncement,
}) {
  return (
    <RowDiv
      announcement={application?.announcement}
      style={{
        background: announcement
          ? data?.recepient !== "all"
            ? "#DFF5F2"
            : Colors.LightGray
          : Colors.LightGray,
      }}
    >
      <span style={{ marginLeft: "15px" }}>
        {application ? null : (
          <img
            style={{
              borderRadius: announcement && "50px",
            }}
            src={
              announcement
                ? data?.universityAssociatedWith?.profilePic
                  ? data?.universityAssociatedWith.profilePic
                  : Images.AnnouncementIcon
                : application
                ? Images.ApplicationIcon
                : Images.PeopleIcon
            }
            alt="application"
          />
        )}

        <span>
          <Text.Heading
            style={styles.cardHeader}
            text={
              announcement
                ? data?.subject
                : application
                ? `${data?.instituteName} Application`
                : data?.name
            }
          />
          <Text.Heading
            style={styles.cardSubHeader}
            textAlign="left"
            text={
              announcement
                ? data?.message?.length > 45
                  ? data?.message?.slice(0, 45) + "..."
                  : data?.message
                : application
                ? "Undergraduate Application"
                : application?.university
            }
          />
        </span>
      </span>
      {application && (
        <span>
          <Text.Heading style={styles.cardHeader} text={"Date of Submission"} />
          <Text.Heading
            style={styles.cardSubHeader}
            text={dateFormatter(data?.appliedDate)}
          />
        </span>
      )}
      <span>
        <Text.Heading
          style={styles.cardHeader}
          text={
            announcement
              ? "Announcement Time"
              : application
              ? "Applied Date"
              : "Meeting Date"
          }
        />
        <Text.Heading
          style={styles.cardSubHeader}
          text={
            announcement
              ? moment(new Date(data?.createdAt)).fromNow()
              : dateFormatter(data?.dateOfApplication)
          }
        />
      </span>
      {announcement && (
        <span>
          <Text.Heading style={styles.cardHeader} text={"Expiry Date"} />
          <Text.Heading
            style={styles.cardSubHeader}
            text={moment(data?.expiryDate).format("DD MMMM YYYY")}
          />
        </span>
      )}

      {meeting && data?.time && (
        <span>
          <Text.Heading style={styles.cardHeader} text={"Time"} />
          <Text.Heading style={styles.cardSubHeader} text={data?.time} />
        </span>
      )}
      {meeting && data?.status && (
        <span>
          <Text.Heading
            style={{
              fontSize: "15px",
              fontWeight: "500",
              color: "#081C15",
            }}
            textAlign="left"
            text={"Status"}
          />
          <Text.Heading
            style={{
              fontSize: "15px",
              fontWeight: "400",
              color: "#474747",
            }}
            textAlign="left"
            text={data?.status}
          />
        </span>
      )}
      <span style={styles.flexRow}>
        {meeting && data?.time && (
          <span style={styles.iconDiv}>
            <MdOutlineCalendarToday color="white" />
          </span>
        )}
        {announcement && (
          <span
            style={
              !data?.read ? styles.iconDivSecondary : styles.iconDivPrimary
            }
            onClick={() =>
              announcement && !data?.read ? handleReadAnnouncement(data) : null
            }
          >
            {!data?.read ? (
              <AiOutlineBell color={Colors.Primary} />
            ) : (
              <AiOutlineBell color="white" />
            )}
          </span>
        )}
        {application ? null : (
          <AiFillRightCircle
            fontSize={"30px"}
            color={Colors.Primary}
            onClick={() => (announcement ? handleShowAnnouncement(data) : null)}
          />
        )}
      </span>
    </RowDiv>
  );
}

const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  padding: 12px 15px;
  border-radius: 5px;
  cursor: pointer;
  span:first-of-type {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: flex-start;
    img {
      width: 40px;
      height: 40px;
      object-fit: contain;
      background: white;
    }
    span {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-left: 10px;
      width: ${(props) => (props.announcement ? "430px" : "250px")};
      span:first-of-type {
        font-size: 16px;
        font-weight: 500;
        color: ${Colors.Text};
      }
      span:last-of-type {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        color: ${Colors.Text};
      }
    }
  }
  span:last-of-type {
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
  }
`;

export default CardRow;
