import {
  ADD_STAFF,
  ADD_STAFF_FAILURE,
  ADD_STAFF_SUCCESS,
  CHANGE_ROLE,
  CHANGE_ROLE_FAILURE,
  CHANGE_ROLE_SUCCESS,
  GET_COUNSELOR_INFO,
  GET_COUNSELOR_INFO_FAILURE,
  GET_COUNSELOR_INFO_SUCCESS,
  GET_STAFF,
  GET_STAFF_FAILURE,
  GET_STAFF_SUCCESS,
  REMOVE_STAFF,
  REMOVE_STAFF_FAILURE,
  REMOVE_STAFF_SUCCESS,
} from "../constants";

const initialState = {
  count: 0,
  staffs: [],
  staff: {},
  isLoading: true,
  error: null,
};

export default function StaffReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_STAFF:
    case GET_STAFF:
    case CHANGE_ROLE:
    case REMOVE_STAFF:
    case GET_COUNSELOR_INFO:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case GET_STAFF_SUCCESS:
      state = {
        ...state,
        count: action?.payload?.count,
        staffs: action?.payload?.data,
        isLoading: false,
      };
      break;
    case GET_COUNSELOR_INFO_SUCCESS:
      state = {
        ...state,
        staff: action?.payload?.data,
        isLoading: false,
      };
      break;
    case ADD_STAFF_SUCCESS:
    case CHANGE_ROLE_SUCCESS:
    case REMOVE_STAFF_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case ADD_STAFF_FAILURE:
    case GET_STAFF_FAILURE:
    case CHANGE_ROLE_FAILURE:
    case REMOVE_STAFF_FAILURE:
    case GET_COUNSELOR_INFO_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    default:
      break;
  }
  return state;
}
