import { Colors } from "../../config";

const styles = {
  rowStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  flexColumnCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  flexRowCenter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  flexRowSpace: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },

  buttonStyle: {
    width: "100%",
    height: "40px",
    borderRadius: "6px",
    fontWeight: "500",
    fontSize: "15px",
    color: Colors.WhiteText,
  },

  // billing information
  viewInvoicesButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    background: Colors.Primary,
    borderRadius: "5px",
    color: "white",
  },
  accountInfoContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridColumnGap: "40px",
    gridRowGap: "20px",
    width: "90%",
  },

  // billing form
  cardContainer: {
    border: `1px solid ${Colors.Primary}`,
    height: "40px",
    padding: "10px",
    borderRadius: "10px",
  },

  // payment details
  invoiceStyle: {
    color: "white",
    backgroundColor: Colors.Primary,
    padding: "5px",
    borderRadius: "5px",
    cursor: "pointer",
  },
  iconStyle: { height: "48px", width: "48px", padding: "10px" },

  // staff
  cardDiv: {
    background: Colors.LightGray,
    boxShadow: "0px 3px 12px #00000033",
    display: "flex",
    flexDirection: "column",
    width: "250px",
    borderRadius: "33px",
    padding: "15px 10px",
    marginInline: "15px",
  },

  tableText: {
    fontSize: "12px",
    fontWeight: "500",
    color: Colors.BlackText,
    textTransform: "capitalize",
  },

  // add staff
  textfieldStyle: {
    borderRadius: "6px",
    border: `1px solid ${Colors.Primary}`,
    padding: "5px",
    width: "100%",
    fontSize: "14px",
    fontWeight: "400",
    color: Colors.BlackText2,
  },
  roleDropdown: {
    color: Colors.Primary,
    width: "100%",
    borderColor: Colors.Primary,
    outline: "none",
    textTransform: "capitalize",
  },
  // modal\
  modalButton: {
    backgroundColor: Colors.Primary,
    fontWeight: "700",
    fontSize: "16px",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "15px",
    width: "100px",
  },
  redIcon: {
    color: "red",
    height: "68px",
    width: "68px",
    padding: "10px",
  },
};

export default styles;
