import { combineReducers } from "redux";

import AnnouncementReducer from "./AnnouncementReducer";
import ApplicationReducer from "./ApplicationReducer";
import AuthReducer from "./AuthReducer";
import ContactReducer from "./ContactReducer";
import MeetingReducer from "./MeetingReducer";
import ProfileReducer from "./ProfileReducer";
import StaffReducer from "./StaffReducer";
import StudentReducer from "./StudentReducer";
import UIReducer from "./UiReducer";
import UniversityReducer from "./UniversityReducer";
import NotificationsReducer from "./NotificationsReducer";
import BillingReducer from "./BillingReducer";
import ConverterReducer from "./ConverterReducer";
import ChatbotReducer from "./ChatbotReducer";
const RootReducer = combineReducers({
  billing: BillingReducer,
  notifications: NotificationsReducer,
  announcement: AnnouncementReducer,
  application: ApplicationReducer,
  auth: AuthReducer,
  contact: ContactReducer,
  chatbot: ChatbotReducer,
  meeting: MeetingReducer,
  profile: ProfileReducer,
  staff: StaffReducer,
  student: StudentReducer,
  ui: UIReducer,
  university: UniversityReducer,
  converter: ConverterReducer,
});

export default RootReducer;
