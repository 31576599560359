import {
  GET_MESSAGES,
  GET_MESSAGES_SUCCESS,
  GET_MESSAGES_FAILURE,
  GET_UNREAD_MESSAGES,
  GET_UNREAD_MESSAGES_SUCCESS,
  GET_UNREAD_MESSAGES_FAILURE,
  ADD_MESSAGE,
  ADD_MESSAGE_SUCCESS,
  ADD_MESSAGE_FAILURE,
  GET_STUDENTS,
  GET_STUDENTS_SUCCESS,
  GET_STUDENTS_FAILURE,
} from "../constants";

export default class ChatbotAction {
  static getMessages(payload, cb) {
    return {
      type: GET_MESSAGES,
      payload,
      cb,
    };
  }
  static getMessagesSuccess(payload) {
    return {
      type: GET_MESSAGES_SUCCESS,
      payload,
    };
  }
  static getMessagesFailure(payload) {
    return {
      type: GET_MESSAGES_FAILURE,
      payload,
    };
  }
  static getUnreadMessages(payload, cb) {
    return {
      type: GET_UNREAD_MESSAGES,
      payload,
      cb,
    };
  }
  static getUnreadMessagesSuccess(payload) {
    return {
      type: GET_UNREAD_MESSAGES_SUCCESS,
      payload,
    };
  }
  static getUnreadMessagesFailure(payload) {
    return {
      type: GET_UNREAD_MESSAGES_FAILURE,
      payload,
    };
  }
  static getStudents(payload, cb) {
    return {
      type: GET_STUDENTS,
      payload,
      cb,
    };
  }
  static getStudentsSuccess(payload) {
    return {
      type: GET_STUDENTS_SUCCESS,
      payload,
    };
  }
  static getStudentsFailure(payload) {
    return {
      type: GET_STUDENTS_FAILURE,
      payload,
    };
  }
  static addMessage(payload, cb) {
    return {
      type: ADD_MESSAGE,
      payload,
      cb,
    };
  }
  static addMessageSuccess(payload) {
    return {
      type: ADD_MESSAGE_SUCCESS,
      payload,
    };
  }
  static addMessageFailure(payload) {
    return {
      type: ADD_MESSAGE_FAILURE,
      payload,
    };
  }
}
