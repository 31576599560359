import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "react-bootstrap";

import { IoMdAttach } from "react-icons/io";
import { AiFillCloseCircle } from "react-icons/ai";

import Lottie from "react-lottie-player";

import { AuthAction, StaffAction } from "../../store/actions";

import { Button, Layout, Modal, Text, Loader } from "../../components";
import { Colors } from "../../config";

import styles from "./styles";

import Done from "../../assets/images/57767-done.json";

const imageMimeType = /image\/(png|jpg|jpeg)/i;

function DetailsForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const profileImgRef = useRef(null);

  const staffLoading = useSelector((state) => state.staff);
  const counselorInfo = useSelector((state) => state.staff.staff);

  const [file, setFile] = useState(null);
  const [failModal, setFailModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [state, setState] = useState({
    firstName: "",
    firstNameError: "",
    lastName: "",
    lastNameError: "",
    phoneNumber: "",
    phoneNumberError: "",
    location: "",
    locationError: "",
    profileImage: "",
    profileImageError: "",
  });

  useEffect(() => {
    if (location?.state?.role === "Counselor") {
      dispatch(StaffAction.getCounselorInfo(location?.state?.email));
    }
  }, []);

  const handleSubmit = () => {
    if (location?.state?.role !== "Counselor" && state.firstName === "") {
      setState({
        ...state,
        firstNameError: "First name can not be left empty",
      });
    } else if (location?.state?.role !== "Counselor" && state.lastName === "") {
      setState({
        ...state,
        firstNameError: "",
        lastNameError: "Last name can not be left empty",
      });
    } else if (state.phoneNumber === "") {
      setState({
        ...state,
        lastNameError: "",
        phoneNumberError: "Phone number can not be left empty",
      });
    } else if (file === null) {
      setState({
        ...state,
        phoneNumberError: "",
        profileImageError: "Please upload profile image",
      });
    } else {
      setState({
        ...state,
        profileImageError: "",
      });
      let data = new FormData();

      data.append(
        "fullName",
        location?.state?.role === "Counselor"
          ? counselorInfo?.name
          : `${state.firstName} ${state.lastName}`
      );
      data.append("email", location?.state?.email);
      data.append("password", location?.state?.password);
      data.append("phoneNumber", state.phoneNumber);
      data.append("role", location?.state?.role);
      data.append("image", file);
      dispatch(
        AuthAction.Signup(data, (res) => {
          if (res.data.status === 201) {
            setSuccessModal(true);
          } else {
            setFailModal(true);
          }
        })
      );
    }
  };

  const changeHandler = (e) => {
    const file = e.target.files[0];
    if (!file.type.match(imageMimeType)) {
      alert("Image mime type is not valid");
      return;
    }
    setFile(file);
  };

  return (
    <>
      <Layout.LoginLayout backLink={"/signup/password"}>
        <Layout.HeaderSection
          title="Enter Details"
          subtitle="Enter staff details"
        />
        <Layout.Spacer height={20} />
        {staffLoading?.isLoading ? (
          <Loader.Circular />
        ) : (
          <div>
            {location?.state?.role === "Counselor" ? (
              <>
                <Form.Group>
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control value={counselorInfo?.name} disabled={true} />
                </Form.Group>
                <Layout.Spacer height={20} />
              </>
            ) : (
              <>
                <Form.Group>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    value={state.firstName}
                    disabled={location?.state?.role === "Counselor"}
                    onChange={(e) => {
                      setState({
                        ...state,
                        firstName: e.target.value,
                      });
                    }}
                  />
                  {state.firstNameError && (
                    <Form.Text className="text-danger">
                      {state.firstNameError}
                    </Form.Text>
                  )}
                </Form.Group>
                <Layout.Spacer height={20} />
                <Form.Group>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    value={state.lastName}
                    disabled={location?.state?.role === "Counselor"}
                    onChange={(e) => {
                      setState({
                        ...state,
                        lastName: e.target.value,
                      });
                    }}
                  />
                  {state.lastNameError && (
                    <Form.Text className="text-danger">
                      {state.lastNameError}
                    </Form.Text>
                  )}
                </Form.Group>
                <Layout.Spacer height={20} />
              </>
            )}

            {location?.state?.role === "Counselor" && (
              <>
                <Form.Group>
                  <Form.Label>School Name</Form.Label>
                  <Form.Control
                    value={counselorInfo?.schoolId?.name}
                    disabled={true}
                  />
                </Form.Group>
                <Layout.Spacer height={20} />
                <Form.Group>
                  <Form.Label>School Position</Form.Label>
                  <Form.Control
                    value={counselorInfo?.position}
                    disabled={true}
                  />
                </Form.Group>
                <Layout.Spacer height={20} />
              </>
            )}
            <Form.Group>
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                placeholder="+971 507912614"
                onChange={(e) => {
                  setState({
                    ...state,
                    phoneNumber: e.target.value,
                  });
                }}
              />
              {state.phoneNumberError && (
                <Form.Text className="text-danger">
                  {state.phoneNumberError}
                </Form.Text>
              )}
            </Form.Group>
            <Layout.Spacer height={20} />
            <Form.Group>
              <Form.Label>Upload Profile Picture</Form.Label>
              <div
                style={styles.imageUpload}
                onClick={() => profileImgRef.current.click()}
              >
                <input
                  style={{ display: "none" }}
                  type={"file"}
                  ref={profileImgRef}
                  accept=".png, .jpg, .jpeg"
                  onChange={changeHandler}
                />
                {file ? file.name : "Attach Document"}
                <IoMdAttach size={20} />
              </div>
              {state.profileImageError && (
                <Form.Text className="text-danger">
                  {state.profileImageError}
                </Form.Text>
              )}
            </Form.Group>
            <Layout.Spacer height={30} />
          </div>
        )}

        <Button.Primary style={styles.buttonStyle} onClick={handleSubmit}>
          Submit
        </Button.Primary>

        <Layout.Spacer height={20} />
      </Layout.LoginLayout>
      {successModal && (
        <Modal.Basic
          isModalVisible={successModal}
          centered={true}
          handleCancel={() => setSuccessModal(false)}
          closable={false}
        >
          <div style={styles.flexColumnCenter}>
            <Lottie
              loop
              animationData={Done}
              play
              style={{ height: "120px" }}
            ></Lottie>
            <Layout.Spacer height={20} />
            <Text.Heading
              fontSize={"32px"}
              fontWeight={"700"}
              color={Colors.Black}
              text="Successful!"
            />
            <Layout.Spacer height={20} />
            <Text.Heading
              fontSize={"18px"}
              fontWeight={"400"}
              color={Colors.Black}
              text="School registered successfully."
            />
            <Layout.Spacer height={20} />
            <Button.Primary
              style={styles.modalButton}
              onClick={() => {
                setSuccessModal(false);
                navigate("/login");
              }}
            >
              Ok
            </Button.Primary>
          </div>
          <Layout.Spacer height={10} />
        </Modal.Basic>
      )}
      {failModal && (
        <Modal.Basic
          isModalVisible={failModal}
          centered={true}
          handleCancel={() => setFailModal(false)}
          closable={false}
        >
          <div style={styles.flexColumnCenter}>
            <AiFillCloseCircle
              color="red"
              style={{ width: "70px", height: "70px" }}
            />
            <Layout.Spacer height={20} />
            <Text.Heading
              fontSize={"32px"}
              fontWeight={"700"}
              color={Colors.Black}
              text="Unsuccessful!"
            />
            <Layout.Spacer height={20} />
            <Text.Heading
              fontSize={"18px"}
              fontWeight={"400"}
              color={Colors.Black}
              text="We have encountered an error. Please check if you have filled all
                the required fields."
            />
            <Layout.Spacer height={20} />
            <Button.Primary
              style={styles.modalButton}
              onClick={() => {
                setFailModal(false);
              }}
            >
              Ok
            </Button.Primary>
          </div>
          <Layout.Spacer height={10} />
        </Modal.Basic>
      )}
    </>
  );
}

export default DetailsForm;
