import {
  ADD_OR_REMOVE_FROM_WISHLIST,
  ADD_OR_REMOVE_FROM_WISHLIST_FAILURE,
  ADD_OR_REMOVE_FROM_WISHLIST_SUCCESS,
  GET_POPULAR_MAJORS,
  GET_POPULAR_MAJORS_FAILURE,
  GET_POPULAR_MAJORS_SUCCESS,
  GET_POPULAR_UNIVERSITIES,
  GET_POPULAR_UNIVERSITIES_FAILURE,
  GET_POPULAR_UNIVERSITIES_SUCCESS,
  GET_UNIVERSITIES,
  GET_UNIVERSITIES_FAILURE,
  GET_UNIVERSITIES_SUCCESS,
  GET_ALL_UNIVERSITIES,
  GET_ALL_UNIVERSITIES_FAILURE,
  GET_ALL_UNIVERSITIES_SUCCESS,
  GET_UNIVERSITY_DETAILS,
  GET_UNIVERSITY_DETAILS_FAILURE,
  GET_UNIVERSITY_DETAILS_SUCCESS,
} from "../constants";

const initialState = {
  count: null,
  popularUniversities: [],
  popularMajors: [],
  universities: [],
  allUniversities: [],
  university: {},
  isLoading: true,
  allUniversityLoading: true,
  error: null,
};

export default function UniversityReducer(state = initialState, action) {
  switch (action.type) {
    case GET_UNIVERSITIES:
    case GET_ALL_UNIVERSITIES:
    case GET_UNIVERSITY_DETAILS:
    case ADD_OR_REMOVE_FROM_WISHLIST:
    case GET_POPULAR_UNIVERSITIES:
      state = {
        ...state,
        universities: [],
        isLoading: true,
        error: null,
      };
      break;
    case GET_UNIVERSITIES_SUCCESS:
      state = {
        ...state,
        universities: action.payload.data,
        count: action.payload.count,
        university: {},
        isLoading: false,
      };
      break;
    case GET_ALL_UNIVERSITIES_SUCCESS:
      state = {
        ...state,
        allUniversities: action?.payload,
        isLoading: false,
        allUniversityLoading: false,
      };
      break;
    case GET_UNIVERSITY_DETAILS_SUCCESS:
      state = {
        ...state,
        university: action.payload,
        isLoading: false,
      };
      break;
    case GET_POPULAR_UNIVERSITIES_SUCCESS:
      state = {
        ...state,
        popularUniversities: action.payload.maxOccurrenceSorted,
        popularCountries: action.payload.countryOccurrencesArray,
        isLoading: false,
      };
      break;

    case ADD_OR_REMOVE_FROM_WISHLIST_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case GET_UNIVERSITIES_FAILURE:
    case GET_ALL_UNIVERSITIES_FAILURE:
    case GET_UNIVERSITY_DETAILS_FAILURE:
    case ADD_OR_REMOVE_FROM_WISHLIST_FAILURE:
    case GET_POPULAR_UNIVERSITIES_FAILURE:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    default:
      break;
  }
  return state;
}
