import {
  ADD_OR_REMOVE_FROM_WISHLIST,
  ADD_OR_REMOVE_FROM_WISHLIST_FAILURE,
  ADD_OR_REMOVE_FROM_WISHLIST_SUCCESS,
  GET_POPULAR_MAJORS,
  GET_POPULAR_MAJORS_FAILURE,
  GET_POPULAR_MAJORS_SUCCESS,
  GET_POPULAR_UNIVERSITIES,
  GET_POPULAR_UNIVERSITIES_FAILURE,
  GET_POPULAR_UNIVERSITIES_SUCCESS,
  GET_UNIVERSITIES,
  GET_UNIVERSITIES_FAILURE,
  GET_UNIVERSITIES_SUCCESS,
  GET_ALL_UNIVERSITIES,
  GET_ALL_UNIVERSITIES_FAILURE,
  GET_ALL_UNIVERSITIES_SUCCESS,
  GET_UNIVERSITY_DETAILS,
  GET_UNIVERSITY_DETAILS_FAILURE,
  GET_UNIVERSITY_DETAILS_SUCCESS,
} from "../constants";

export default class UniversityAction {
  static getUniversities(payload, cb) {
    return {
      type: GET_UNIVERSITIES,
      payload,
      cb,
    };
  }
  static getUniversitiesSuccess(payload) {
    return {
      type: GET_UNIVERSITIES_SUCCESS,
      payload,
    };
  }
  static getUniversitiesFailure(payload) {
    return {
      type: GET_UNIVERSITIES_FAILURE,
      payload,
    };
  }
  static getAllUniversities(payload, cb) {
    return {
      type: GET_ALL_UNIVERSITIES,
      payload,
      cb,
    };
  }
  static getAllUniversitiesSuccess(payload) {
    return {
      type: GET_ALL_UNIVERSITIES_SUCCESS,
      payload,
    };
  }
  static getAllUniversitiesFailure(payload) {
    return {
      type: GET_ALL_UNIVERSITIES_FAILURE,
      payload,
    };
  }
  static getPopularUniversities(payload, cb) {
    return {
      type: GET_POPULAR_UNIVERSITIES,
      payload,
      cb,
    };
  }
  static getPopularUniversitiesSuccess(payload) {
    return {
      type: GET_POPULAR_UNIVERSITIES_SUCCESS,
      payload,
    };
  }
  static getPopularUniversitiesFailure(payload) {
    return {
      type: GET_POPULAR_UNIVERSITIES_FAILURE,
      payload,
    };
  }

  static getUniversityDetails(id, cb) {
    return {
      type: GET_UNIVERSITY_DETAILS,
      id,
      cb,
    };
  }
  static getUniversityDetailsSuccess(payload) {
    return {
      type: GET_UNIVERSITY_DETAILS_SUCCESS,
      payload,
    };
  }
  static getUniversityDetailsFailure(payload) {
    return {
      type: GET_UNIVERSITY_DETAILS_FAILURE,
      payload,
    };
  }
  static addOrRemoveToWishlist(id, cb) {
    return {
      type: ADD_OR_REMOVE_FROM_WISHLIST,
      id,
      cb,
    };
  }
  static addOrRemoveToWishlistSuccess(payload) {
    return {
      type: ADD_OR_REMOVE_FROM_WISHLIST_SUCCESS,
      payload,
    };
  }
  static addOrRemoveToWishlistFailure(payload) {
    return {
      type: ADD_OR_REMOVE_FROM_WISHLIST_FAILURE,
      payload,
    };
  }
}
