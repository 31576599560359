import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Col, Row } from "antd";
import { io } from "socket.io-client";
import styled from "styled-components";

import { ChatbotAction } from "../../store/actions";

import { Text, Layout } from "../../components";
import { Colors } from "../../config";
import configVariables from "../../config/constants/index";

import ChatContainer from "./ChatContainer";
import Contacts from "./Contacts";
import Welcome from "./Welcome";

function StudentChat() {
  const socket = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loginUser = useSelector((state) => state.profile.user);
  const schoolIdState = useSelector((state) => state.auth.user.user.schoolId);
  const chatbot = useSelector((state) => state.chatbot);
  const students = useSelector((state) => state.chatbot.students);

  const [contacts, setContacts] = useState([]);
  const [currentChat, setCurrentChat] = useState(undefined);
  const [currentUser, setCurrentUser] = useState(undefined);

  useEffect(() => {
    setCurrentUser(loginUser._id);
  }, []);

  useEffect(() => {
    if (currentUser) {
      socket.current = io(configVariables.BASE_URL);
      socket.current.emit("add-user", currentUser);
    }
  }, [currentUser]);

  useEffect(() => {
    const payload = {
      schoolId: schoolIdState,
    };
    dispatch(
      ChatbotAction.getStudents(payload, (res) => {
        setContacts(res.students);
      })
    );
  }, [currentUser]);

  const handleChatChange = (chat) => {
    setCurrentChat(chat);
  };

  return (
    <div>
      <Text.Heading
        fontSize={"18px"}
        fontWeight={"600"}
        color={Colors.Primary}
        text="Chatbot"
      />
      <Layout.Spacer height={10} />
      <Container>
        <div className="chatContainer">
          <Contacts contacts={contacts} changeChat={handleChatChange} />
          {currentChat === undefined ? (
            <Welcome />
          ) : (
            <ChatContainer currentChat={currentChat} socket={socket} />
          )}
        </div>
      </Container>
    </div>
  );
}

export default StudentChat;

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 20px;
  .chatContainer {
    border-radius: 20px;
    height: 75vh;
    width: 100%;
    opacity: 1;
    background-color: #f5f5f5;
    display: grid;
    grid-template-columns: 25% 75%;
  }
`;
