import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Tabs } from "antd";

import { MdLocationPin } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";

import { StudentAction, BillingAction } from "../../store/actions";

import { Button, Layout, Loader, Text } from "../../components";
import { Colors, Images } from "../../config";

import Application from "./applications";
import General from "./general";

import styles from "./styles";

const { TabPane } = Tabs;

function StudentDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const id = location.pathname.split("/")[2];

  const schoolId = useSelector((state) => state.auth.user.user.schoolId);
  const { student, studentMeetings, studentMeetingsAll } = useSelector(
    (state) => state?.student
  );
  const studentLoading = useSelector((state) => state?.student);
  const billingInfo = useSelector(
    (state) => state.billing.billings.updatedBilling
  );

  useEffect(() => {
    dispatch(BillingAction.getBilling({ schoolId: schoolId }));
  }, []);

  useEffect(() => {
    dispatch(StudentAction.getStudentDetails(id));
    dispatch(StudentAction.getStudentMeetingDetails(id));
    dispatch(StudentAction.getStudentAllMeetingDetails(id));
  }, []);

  const handleNavigate = () => {
    if (student._id) {
      navigate("/meetings/add-meeting", {
        state: {
          studentId: student._id,
        },
      });
    }
  };

  const handleEmailClick = (e, email) => {
    e.preventDefault();
    const provider = email.includes("@gmail.com")
      ? "https://mail.google.com/"
      : "https://outlook.live.com/";
    const url = `${provider}mail/u/0/#inbox?compose=${encodeURIComponent(
      email
    )}`;
    window.open(url, "_blank");
  };

  return (
    <div>
      <Text.Heading
        fontSize={"18px"}
        fontWeight={"600"}
        color={Colors.Primary}
        text="Student Information"
      />
      <Layout.Spacer height={30} />
      {studentLoading?.isLoading || Object.keys(student).length === 0 ? (
        <Loader.Circular />
      ) : (
        <div>
          <div style={styles.upperSideContainer}>
            <div style={styles.leftSideData}>
              <div style={{ margin: "20px" }}>
                <img
                  src={
                    student?.profileImage
                      ? student?.profileImage
                      : Images.DummyUser
                  }
                  style={styles.profileImage}
                  alt="school"
                />
              </div>

              <div style={styles.flexColumn}>
                <Text.Heading
                  fontSize={"22px"}
                  fontWeight={"500"}
                  color={Colors.Primary}
                  style={styles.mb5}
                  textAlign="left"
                  textTransform="capitalize"
                  text={student?.name}
                />
                <Text.Heading
                  style={styles.mb5}
                  fontSize={"14px"}
                  fontWeight={"500"}
                  color={Colors.GrayText}
                  textAlign="left"
                  textTransform="capitalize"
                  text={`${student?.gender} | ${student?.age} Years | Grade ${student?.grade}`}
                />

                <div>
                  <MdLocationPin
                    color={Colors.GrayText}
                    style={{ marginRight: "5px" }}
                  />
                  <Text.Heading
                    fontSize={"18px"}
                    fontWeight={"400"}
                    color={Colors.GrayText}
                    textAlign="left"
                    textTransform="capitalize"
                    text={student?.location}
                  />
                </div>

                <Layout.Spacer height={5} />
                <Text.Heading
                  style={styles.mb5}
                  fontSize={"10px"}
                  fontWeight={"700"}
                  color={Colors.Red}
                  textAlign="left"
                  textTransform="capitalize"
                  text={`Account Validity: ${
                    billingInfo?.billingCycle?.split("-")[1]
                  }`}
                />
              </div>
            </div>

            <div>
              <div style={styles.rightSideData}>
                <div
                  style={{ width: "20%", ...styles.justifyCenterItemsCenter }}
                >
                  <FaWhatsapp color={Colors.Primary} fontSize={20} />
                </div>
                <div style={{ width: "80%" }}>
                  <Text.Heading
                    fontSize={"14px"}
                    fontWeight={"400"}
                    color={Colors.GrayText}
                    text={`+${student?.phoneNumber}` || "NA"}
                  />
                </div>
              </div>
              <Layout.Spacer height={10} />
              <div style={styles.rightSideData}>
                <div
                  style={{ width: "20%", ...styles.justifyCenterItemsCenter }}
                >
                  <HiOutlineMail color={Colors.Primary} fontSize={20} />
                </div>
                <div style={{ width: "80%" }}>
                  <a
                    href={`mailto:${student.email}`}
                    onClick={(e) => handleEmailClick(e, student.email)}
                  >
                    <Text.Heading
                      fontSize={"14px"}
                      fontWeight={"400"}
                      color={Colors.GrayText}
                      text={
                        student.email
                          ? student?.email.length > 22
                            ? `${student?.email.slice(0, 22)}...`
                            : student?.email
                          : "NA"
                      }
                    />
                  </a>
                </div>
              </div>
              <Layout.Spacer height={20} />
              <Button.Primary
                style={styles.requestButton}
                onClick={handleNavigate}
              >
                Request meeting
              </Button.Primary>
            </div>
          </div>
          <Layout.Spacer height={40} />
          <Tabs type="card" animated={true}>
            <TabPane tab="General" key="1">
              <General
                data={student?.applications}
                preferredProgram={
                  student?.preferredProgram ? student?.preferredProgram : "-"
                }
                meetings={studentMeetings}
                meetingsAll={
                  !studentMeetingsAll?.meetingDataAll
                    ? []
                    : studentMeetingsAll?.meetingDataAll
                }
              />
            </TabPane>
            <TabPane tab="Applications" key="2">
              <Application data={student?.applications} />
            </TabPane>
          </Tabs>
        </div>
      )}
    </div>
  );
}

export default StudentDetails;
