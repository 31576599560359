import { Colors } from "../../config";

const styles = {
  justifyCenter: {
    display: "flex",
    justifyContent: "center",
  },
  justifyEnd: {
    display: "flex",
    justifyContent: "right",
  },

  buttonStyle: {
    background: Colors.Primary,
    fontWeight: "400",
    fontSize: "14px",
    width: "250px",
    borderRadius: "10px",
  },

  // add meeting
  boxHeader: {
    fontSize: "14px",
    color: "black",
    fontWeight: "400",
  },
  textfieldStyle: {
    borderRadius: "6px",
    border: `1px solid ${Colors.Primary}`,
    padding: "5px",
    width: "100%",
    fontSize: "14px",
    fontWeight: "400",
    color: Colors.BlackText2,
  },
  datePicker: {
    color: Colors.Primary,
    height: "35px",
    width: "100%",
    borderColor: Colors.Primary,
    borderRadius: "7px",
  },
};

export default styles;
