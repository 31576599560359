import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import PhoneInput from "react-phone-input-2";

import { AiFillCamera } from "react-icons/ai";

import { ProfileAction } from "../../store/actions";

import Settings from ".";
import { Button, Layout, Text, TextField, Loader } from "../../components";
import { Colors, Images } from "../../config";

import styles from "./styles";
import "react-phone-input-2/lib/style.css";

const imageMimeType = /image\/(png|jpg|jpeg)/i;

function EditProfile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profileImgRef = useRef(null);

  const profile = useSelector((state) => state.profile);

  const [buttonText, setButtonText] = useState("Save Changes");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [file, setFile] = useState(null);
  const [state, setState] = useState({
    fullName: profile?.user?.fullName,
    email: profile?.user?.email,
    phoneNumber: profile?.user?.phoneNumber,
  });
  const [error, setError] = useState("");
  const [imageError, setImageError] = useState("");
  const [fileDataURL, setFileDataURL] = useState(
    profile?.user?.profileImage ? profile?.user?.profileImage : Images.DummyUser
  );

  useEffect(() => {
    dispatch(ProfileAction.getUserProfile());
  }, []);

  useEffect(() => {
    let fileReader,
      isCancel = false;
    if (file) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURL(result);
        }
      };
      fileReader.readAsDataURL(file);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [file]);

  const defaultState = () => {
    setButtonDisabled(false);
    setButtonText("Save Changes");
  };

  const handleEdit = () => {
    setButtonDisabled(true);
    setButtonText("Saving...");
    if (!state.fullName || !state.email || !state.phoneNumber) {
      defaultState();
      setError("Please enter all fields");
    } else {
      setImageError("");
      setError("");
      let data = new FormData();
      data.append("name", state.fullName);
      data.append("email", state.email);
      data.append("phoneNumber", state.phoneNumber);
      data.append("image", file ? file : fileDataURL);
      dispatch(
        ProfileAction.editUserProfile(data, () => {
          defaultState();
          navigate("/profile");
        })
      );
    }
  };

  const changeHandler = (e) => {
    const file = e.target.files[0];
    if (!file.type.match(imageMimeType)) {
      alert("Image mime type is not valid");
      return;
    }
    setFile(file);
  };

  return (
    <>
      <Settings>
        <Text.Heading
          fontSize={"16px"}
          fontWeight={"600"}
          color={Colors.BlackText2}
          text="Account Settings"
        />
        <Layout.Spacer height={20} />
        {profile?.isLoading ? (
          <Loader.Circular />
        ) : (
          <div>
            <div style={styles.flexRowCenter}>
              <div style={styles.positionRelative}>
                <img
                  src={fileDataURL}
                  style={styles.profileImg}
                  alt="profile"
                />
                <div
                  style={styles.imageUploadDiv}
                  onClick={() => profileImgRef.current.click()}
                >
                  <AiFillCamera color={Colors.Primary} fontSize={20} />
                </div>
              </div>
              <input
                type={"file"}
                ref={profileImgRef}
                accept=".png, .jpg, .jpeg"
                onChange={changeHandler}
                style={styles.inputDisplayNone}
              />
            </div>

            {imageError && (
              <>
                <Layout.Spacer height={20} />
                <div style={styles.flexRowCenter}>
                  <Text.Heading
                    fontSize={"16px"}
                    fontWeight={"400"}
                    color={"red"}
                    text={imageError}
                  />
                </div>
              </>
            )}
            <Layout.Spacer height={30} />

            <div style={styles.editProfileValuesContainer}>
              <div style={{ gridColumn: "1 / span 2" }}>
                <TextField.CustomInput
                  placeholder="Enter Full Name"
                  value={state.fullName}
                  onChange={(e) =>
                    setState({
                      ...state,
                      fullName: e.target.value,
                    })
                  }
                  label="Full Name"
                  style={styles.textfieldStyle}
                />
              </div>
              <div>
                <TextField.CustomInput
                  placeholder="Enter email"
                  value={state.email}
                  onChange={(e) =>
                    setState({
                      ...state,
                      email: e.target.value,
                    })
                  }
                  label="Email"
                  style={styles.textfieldStyle}
                />
              </div>
              <div>
                <label style={styles.labelStyle}>Phone number</label>
                <PhoneInput
                  inputProps={{
                    name: "phone",
                    required: true,
                  }}
                  country={"my"}
                  placeholder="Phone Number"
                  excludeCountries={[]}
                  value={state.phoneNumber}
                  onChange={(phone) =>
                    setState({
                      ...state,
                      phoneNumber: phone,
                    })
                  }
                  containerClass="phone-container"
                  inputClass="phone-input"
                  countryCodeEditable={false}
                />
              </div>
            </div>

            {error && (
              <>
                <Layout.Spacer height={20} />
                <div style={styles.flexRowCenter}>
                  <Text.Heading
                    fontSize={"16px"}
                    fontWeight={"400"}
                    color={"red"}
                    text={error}
                  />
                </div>
              </>
            )}
            <Layout.Spacer height={50} />
            <div style={styles.flexRowCenter}>
              <Button.Primary
                style={styles.secondaryButtonStyle}
                onClick={() => navigate("/profile")}
                disabled={buttonDisabled}
              >
                Reset Changes
              </Button.Primary>
              <Layout.Spacer width={50} />
              <Button.Primary
                style={styles.buttonStyle}
                onClick={handleEdit}
                disabled={buttonDisabled}
              >
                {buttonText}
              </Button.Primary>
            </div>
            <Layout.Spacer height={20} />
          </div>
        )}
      </Settings>
    </>
  );
}

export default EditProfile;
