import { AiFillRightCircle } from "react-icons/ai";
import { MdLocationPin } from "react-icons/md";
import styled from "styled-components";

import { Colors } from "../../config";
import Text from "../Text";

function CustomRowTable({ university, handleClick }) {
  return (
    <RowDiv>
      <span>
        <img src={university.img} alt="university" />
        <span style={{ marginLeft: "15px" }}>
          <Text.Heading
            style={{
              fontSize: "18px",
              fontWeight: "500",
              color: "#081C15",
            }}
            textAlign="left"
            text={university.name}
          />
          <span>
            <MdLocationPin color="#474747" style={{ marginRight: "5px" }} />
            <Text.Heading
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: "#474747",
              }}
              textAlign="left"
              text={university.location}
            />
          </span>
        </span>
      </span>
      <span>
        <AiFillRightCircle
          fontSize={"30px"}
          color={"#3A9AB7"}
          onClick={handleClick}
        />
      </span>
    </RowDiv>
  );
}

const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  span:first-of-type {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: flex-start;
    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      object-fit: contain;
    }
    span {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      span:first-of-type {
        font-size: 16px;
        font-weight: 500;
        color: ${Colors.Text};
      }
      span:last-of-type {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        color: ${Colors.Text};
      }
    }
  }
  span:last-of-type {
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
  }
`;

export default CustomRowTable;
