import React from "react";
import styled from "styled-components";
import { Colors } from "../../config";
const TabButton = ({
  isActive = "false",
  activeColor = Colors.LightGreen,
  text = "Sample",
  position = "left",
  fontFamily,
  ...props
}) => {
  const StyledTabButton = styled.button`
    background-color: ${isActive ? Colors.Primary : Colors.White};
    color: ${isActive ? Colors.White : Colors.Primary};
    cursor: pointer;
    border-radius: ${position === "left"
      ? "30px 0px 0px 30px"
      : "0px 30px 30px 0px"};
    height: 44px;
    width: 50%;
    border: none;
    box-shadow: 0px 3px 6px #00000029;
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 16px;
  `;
  return <StyledTabButton {...props}>{text}</StyledTabButton>;
};
export default TabButton;
