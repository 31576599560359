import { put } from "redux-saga/effects";

import { NotificationsAction } from "../actions";
import { ApiCaller } from "../../config";

export default class NotificationsMiddleware {
  static *addNotifications({ payload, cb }) {
    try {
      let res = yield ApiCaller.Post(
        `/notifications/createNotifications`,
        payload
      );
      if (res.status === 201) {
        yield put(NotificationsAction.addNotificationsSuccess(res.data.data));
        if (cb) {
          cb();
        }
      } else {
        yield put(NotificationsAction.addNotificationsFailure());
        // Utils.showSnackBar({
        //   message: res.data.message,
        //   severity: 'error',
        // });
      }
    } catch (err) {
      yield put(NotificationsAction.addNotificationsFailure());
    }
  }

  static *getNotifications({ payload, cb }) {
    try {
      const { limit, offset, userId } = payload;

      let res;
      if (limit || offset) {
        res = yield ApiCaller.Get(
          `/notifications/getNotifications?limit=${limit}&offset=${offset}&userId=${userId}`
        );
      } else {
        res = yield ApiCaller.Get(
          `/notifications/getNotifications?userId=${userId}`
        );
      }
      if (res.status === 200) {
        yield put(NotificationsAction.getNotificationsSuccess(res.data.data));
        if (cb) {
          cb();
        }
      } else {
        yield put(NotificationsAction.getNotificationsSuccess([]));
        // Utils.showSnackBar({
        //     message: res.data.message,
        //     severity: 'error',
        //   });
      }
    } catch (err) {
      yield put(NotificationsAction.getNotificationsFailure());
    }
  }

  static *changeNotificationsStatus({ payload, cb }) {
    try {
      let res = yield ApiCaller.Post(
        `/notifications/updateNotifications`,
        payload
      );
      if (res.status === 200) {
        yield put(
          NotificationsAction.changeNotificationsStatusSuccess(res.data.data)
        );
        if (cb) {
          cb();
        }
      } else {
        yield put(NotificationsAction.changeNotificationsStatusFailure());
        // Utils.showSnackBar({
        //   message: res.data.message,
        //   severity: 'error',
        // });
      }
    } catch (err) {
      yield put(NotificationsAction.changeNotificationsStatusFailure());
    }
  }
}
