import { useSelector } from 'react-redux';

import { Colors, Utils } from './config';
import { Loader, Popup } from './components';
import AllRoutes from './config/routes/AllRoutes';

import './App.css';

const { saveSnackbarRef } = Utils;

const App = (props) => {
  const isLoading = useSelector((state) => state.auth.isLoading);
  return (
    <div>
      <AllRoutes />
      <Popup.Snackbar ref={(ref) => saveSnackbarRef(ref)} />

      {isLoading ? (
        <div
          style={{
            display: 'flex',
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: Colors.BlackOpacity(0.7),
          }}
        >
          <Loader.Circular sx={{ color: Colors.WhiteOpacity(0.7) }} />
        </div>
      ) : null}

      {/* <ComponentsSample /> */}
    </div>
  );
};

export default App;
