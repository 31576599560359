import { useState } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import ReactCodeInput from "react-code-input";

import { AuthAction } from "../../store/actions";
import { Button, Layout, Text } from "../../components";

import styles from "./styles";

const VerifyOTPForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [otpCode, setOtpCode] = useState("");
  const [otpCodeErrMsg, setOtpCodeErrMsg] = useState("");

  const handleVerifyCode = () => {
    if (otpCode.length === 0) {
      setOtpCodeErrMsg("Please enter code");
    } else if (otpCode.length < 4 && otpCode.length > 0) {
      setOtpCodeErrMsg("Incomplete Code");
    } else {
      setOtpCodeErrMsg("");
      const body = {
        OTPCode: otpCode,
        email: location.state.email,
        role: location.state.role,
      };
      dispatch(
        AuthAction.VerifySignupOtp(body, () => {
          navigate("/signup/password", {
            state: {
              email: location.state.email,
              role: location.state.role,
            },
          });
        })
      );
    }
  };

  const handleChange = (val) => {
    setOtpCode(val);
  };

  return (
    <Layout.LoginLayout backLink={"/signup/email"}>
      <Layout.HeaderSection
        title="Verify OTP"
        subtitle="Please enter the 4-digit OTP"
      />
      <Layout.Spacer height={50} />
      <div style={styles.otpFormContainer}>
        <div style={styles.otpCodeContainer}>
          <ReactCodeInput
            type="number"
            fields={4}
            onChange={handleChange}
            value={otpCode}
            inputStyle={styles.codeInput}
            onClick={(e) => setOtpCode(e.target.value)}
          />
          <Layout.Spacer height={20} />
          {otpCodeErrMsg && (
            <Text.ErrorText style={styles.otpErrorText} text={otpCodeErrMsg} />
          )}
        </div>
        <Layout.Spacer height={10} />

        <Button.Primary style={styles.buttonStyle} onClick={handleVerifyCode}>
          Continue
        </Button.Primary>

        <Layout.Spacer height={20} />
      </div>
    </Layout.LoginLayout>
  );
};

export default VerifyOTPForm;
