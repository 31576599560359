import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

import moment from "moment";
import Calendar from "react-calendar";

import styles from "./styles";

const CustomCalendar = ({ allMeetings, deadlines }) => {
  const navigate = useNavigate();

  const { studentType } = useSelector((state) => state.auth.user.user);

  const [calendarmeetingsArr, setCalendarMeetingsArr] = useState([]);
  // const [deadlinesArr, setDeadlinesArr] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 });

  const date = new Date();

  const formatShortWeekday = (locale, date) =>
    new Intl.DateTimeFormat(locale, { weekday: "short" }).format(date)[0];

  const handleDateClick = (date) => {
    setShowPopup(!showPopup);
    const dateClicked = moment(date).format("DD-MM-YYYY");

    const meetingsArray = [];
    // const deadlineArray = [];

    allMeetings?.map((date) => {
      if (dateClicked === moment(date.meetingDate).format("DD-MM-YYYY")) {
        meetingsArray.push(date);
      }
    });

    // deadlines?.map((deadline) => {
    //   if (dateClicked === moment(deadline.deadline).format("DD-MM-YYYY")) {
    //     deadlineArray.push(deadline);
    //   }
    // });

    setCalendarMeetingsArr(meetingsArray);
    // setDeadlinesArr(deadlineArray);
  };

  return (
    <div
      style={styles.calendarDiv}
      onClick={(e) => {
        setClickPosition({ x: e.clientX, y: e.clientY });
      }}
    >
      <Calendar
        formatShortWeekday={formatShortWeekday}
        onClickDay={handleDateClick}
        value={date}
        tileClassName={({ date, view }) => {
          const meetingsDate = [];
          // const deadlinesDate = [];
          if (studentType !== "individualStudent") {
            allMeetings?.map((date) => {
              const format = date.meetingDate.split("T")[0];
              const dateValue = format.split("-")[2];
              const monthValue = format.split("-")[1];
              const yearValue = format.split("-")[0];
              meetingsDate.push(`${dateValue}-${monthValue}-${yearValue}`);
            });

            if (
              meetingsDate.find((x) => x === moment(date).format("DD-MM-YYYY"))
            ) {
              return "highlight";
            }
          }
          // deadlines?.map((deadline) => {
          //   const format = deadline.deadline.split("T")[0];
          //   const dateValue = format.split("-")[2];
          //   const monthValue = format.split("-")[1];
          //   const yearValue = format.split("-")[0];
          //   deadlinesDate.push(`${dateValue}-${monthValue}-${yearValue}`);
          // });

          // if (
          //   deadlinesDate.find((x) => x === moment(date).format("DD-MM-YYYY"))
          // ) {
          //   return "highlight";
          // }
        }}
      />
      {showPopup && (
        <div
          style={{
            ...styles.calendarPopup,
            top: clickPosition.y + 20 + "px",
            left: clickPosition.x - 270 + "px",
            width: "260px",
          }}
          onClick={() => setShowPopup(false)}
        >
          <div
            style={styles.calendarPopupCancel}
            onClick={() => setShowPopup(false)}
          >
            x
          </div>
          <div style={styles.calendarChip} className="calendarChip">
            <>
              <p style={styles.calendarChipHeader}>Meetings</p>
              {calendarmeetingsArr?.length <= 0 ? (
                <div>No meetings on this day.</div>
              ) : (
                <>
                  {calendarmeetingsArr?.map((event) => (
                    <div
                      onClick={() => {
                        navigate("/meetings/meetingdetails", {
                          state: { data: event, sent: "true" },
                        });
                      }}
                      style={{ cursor: "pointer", width: "99%" }}
                      key={event._id}
                    >
                      <p style={styles.calendarChipCounselor}>
                        Meeting with {event?.studentId?.name}
                      </p>
                      <p style={styles.calendarChipSubject}>{event?.subject}</p>
                    </div>
                  ))}
                </>
              )}
              {/* <hr></hr> */}
            </>

            {/* <p style={styles.calendarChipHeader}>Deadlines</p>
            {deadlinesArr?.length <= 0 ? (
              <div>No deadlines on this day.</div>
            ) : (
              <>
                {deadlinesArr?.map((event) => {
                  return (
                    <div style={{ width: "99%" }} key={event._id}>
                      <p style={styles.calendarChipDeadline}>
                        Deadline of{" "}
                        <span style={styles.calendarChipCounselor}>
                          <b>{event?.universityId?.name}</b>
                        </span>
                      </p>
                    </div>
                  );
                })}
              </>
            )} */}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomCalendar;
