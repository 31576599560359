import { put } from "redux-saga/effects";

import { ApiCaller } from "../../config";
import { UniversityAction } from "../actions";

export default class UniversityMiddleware {
  static *getUniversities({ payload, cb }) {
    try {
      const { region, search, limit, offset, filter } = payload;

      const res = yield ApiCaller.Get(
        `/university/search-university?searchInput=${search}&filterRegion=${region}&limit=${limit}&offset=${offset}&filter=${filter}`
      );

      if (res.status === 200) {
        yield put(UniversityAction.getUniversitiesSuccess(res.data.data));
        if (cb) {
          cb();
        }
      } else {
        yield put(UniversityAction.getUniversitiesSuccess([]));
      }
    } catch (err) {
      yield put(UniversityAction.getUniversitiesFailure());
    }
  }

  static *getAllUniversities({ cb }) {
    try {
      let res = yield ApiCaller.Get(`/university/getAllUniversities`);
      if (res.status === 200) {
        yield put(UniversityAction.getAllUniversitiesSuccess(res.data.data));
        if (cb) {
          cb(res.data.data);
        }
      } else {
        yield put(UniversityAction.getAllUniversitiesSuccess([]));
        // Utils.showSnackBar({
        //   message: res.data.message,
        //   severity: 'error',
        // });
      }
    } catch (err) {
      yield put(UniversityAction.getAllUniversitiesFailure());
    }
  }

  static *getUniversityDetails({ id, cb }) {
    try {
      let res = yield ApiCaller.Get(`/university/get-universityDetails/${id}`);
      if (res.status === 200) {
        yield put(UniversityAction.getUniversityDetailsSuccess(res.data.data));
        if (cb) {
          cb();
        }
      } else {
        yield put(UniversityAction.getUniversityDetailsFailure());
        // Utils.showSnackBar({
        //   message: res.data.message,
        //   severity: 'error',
        // });
      }
    } catch (err) {
      yield put(UniversityAction.getUniversityDetailsFailure());
    }
  }

  static *addOrRemoveWishlist({ id, cb }) {
    try {
      let res = yield ApiCaller.Post(`/university/add-to-wishlist?id=${id}`);
      if (res.status === 200) {
        yield put(UniversityAction.addOrRemoveToWishlistSuccess(res.data.data));
        if (cb) {
          cb();
        }
      } else {
        yield put(UniversityAction.addOrRemoveToWishlistFailure());
        // Utils.showSnackBar({
        //   message: res.data.message,
        //   severity: 'error',
        // });
      }
    } catch (err) {
      yield put(UniversityAction.addOrRemoveToWishlistFailure());
    }
  }

  static *getPopularUniversities({ payload, cb }) {
    try {
      const schoolId = payload.schoolId;
      const res = yield ApiCaller.Get(
        `/university/popular-university-school?schoolId=${schoolId}`
      );
      if (res.status === 200) {
        yield put(
          UniversityAction.getPopularUniversitiesSuccess(res.data.data)
        );
        if (cb) {
          cb();
        }
      } else {
        yield put(UniversityAction.getPopularUniversitiesFailure());
      }
    } catch (err) {
      yield put(UniversityAction.getPopularUniversitiesFailure());
    }
  }
}
