import React, { useRef, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker, Radio, Select, Input } from "antd";

import { AiFillCloseCircle } from "react-icons/ai";
import { CgAttachment } from "react-icons/cg";

import Lottie from "react-lottie-player";

import { MeetingAction, StudentAction } from "../../store/actions";

import {
  Button,
  Layout,
  Modal,
  Text,
  TextField,
  Loader,
} from "../../components";
import { Colors } from "../../config";

import styles from "./styles";

import Done from "../../assets/images/57767-done.json";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

const { TextArea } = Input;
const plainOptions = ["Online", "Offline"];

const disabledDate = (current) => {
  return current < dayjs().startOf("day");
};

function AddMeeting() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const fileSelect = useRef();

  const students = useSelector((state) => state.student.activeStudents);
  const schoolId = useSelector((state) => state.auth.user.user.schoolId);
  const counselorId = useSelector((state) => state.auth.user.user._id);
  const counselorName = useSelector((state) => state.auth.user.user.fullName);

  const [loading, setLoading] = useState(false);
  const [bodyData, setBodyData] = useState({});
  const [failModal, setFailModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(
    location?.state?.edit === true ? location.state.data.meetingFile : null
  );
  const [studentId, setStudentId] = useState(
    location?.state?.data?.studentId?._id
      ? location?.state?.data?.studentId?._id
      : location.state.studentId
      ? location.state.studentId
      : students[0]?._id
  );
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [buttonText, setButtonText] = useState("Schedule");

  const [state, setState] = useState({
    timeSlot:
      location?.state?.edit === true
        ? location.state.data.timeSlot
        : "12:00 AM - 12:30 AM",
    meetingDate: "",
    subject: location?.state?.edit === true ? location.state.data?.subject : "",
    message: location?.state?.edit === true ? location.state.data?.message : "",
    meetingType:
      location?.state?.edit === true ? location.state.data?.meetingType : "",
    meetingLink:
      location?.state?.edit === true ? location.state.data?.meetingLink : "",
  });
  const [error, setError] = useState("");

  useEffect(() => {
    const payload = {
      schoolId: schoolId,
      limit: 0,
      offset: 0,
    };

    dispatch(StudentAction.getActiveStudents(payload));
    dispatch(StudentAction.getRegisteredStudents(payload));
  }, []);

  const onDateChange = (date, dateString) => {
    setState({
      ...state,
      meetingDate: dateString ? dateString : "",
    });
  };

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const defaultStates = () => {
    setLoading(false);
    setButtonDisabled(false);
    setButtonText("Schedule");
  };

  const scheduleMeeting = () => {
    setLoading(true);
    setButtonDisabled(true);
    setButtonText("Scheduling...");
    const {
      subject,
      meetingDate,
      timeSlot,
      message,
      meetingType,
      meetingLink,
    } = state;
    if (
      subject === "" ||
      meetingDate === "" ||
      timeSlot === "" ||
      message === "" ||
      meetingType === ""
    ) {
      setLoading(false);
      setError("Please fill all the fields.");
      setButtonDisabled(false);
      setButtonText("Schedule");
    } else {
      if (location?.state?.edit === true) {
        let data = new FormData();
        data.append("timeSlot", state.timeSlot);
        data.append("meetingDate", state.meetingDate);
        data.append(
          "status",
          location?.state?.edit === true ? "Rescheduled" : "Pending"
        );
        data.append("message", state.message);
        data.append("meetingId", location.state.data._id);
        data.append("edit", location?.state?.edit === true ? "true" : "false");

        dispatch(
          MeetingAction.addMeeting(data, (res) => {
            if (res.data.status === 201) {
              setBodyData(res.data.data.data);
              setSuccessModal(true);
            } else {
              setFailModal(true);
            }
            defaultStates();
          })
        );
      } else {
        let data = new FormData();
        data.append("timeSlot", timeSlot);
        data.append("meetingDate", meetingDate);
        data.append("subject", subject);
        data.append("counselor", counselorId);
        data.append("counselorName", counselorName);
        data.append("status", "Pending");
        data.append("studentId", studentId);
        data.append("organizer", "School");
        data.append("schoolId", schoolId);
        data.append("file", selectedFile);
        data.append("message", message);
        data.append("meetingType", meetingType);
        data.append("meetingLink", meetingLink);
        data.append("edit", "false");

        dispatch(
          MeetingAction.addMeeting(data, (res) => {
            if (res.data.status === 201) {
              setBodyData(res.data.data.data);
              setSuccessModal(true);
            } else {
              setLoading(false);
              setFailModal(true);
            }
            defaultStates();
          })
        );
      }
    }
  };

  return (
    <>
      <Text.Heading
        fontSize={"18px"}
        fontWeight={"600"}
        color={Colors.Primary}
        text={location.state.title || "Schedule new meeting"}
      />
      {loading ? (
        <Loader.Circular />
      ) : (
        <>
          {location.state.title === "New Meeting" && (
            <div>
              <Text.Heading
                fontSize={"16px"}
                fontWeight={"500"}
                color={Colors.Primary}
                text="Schedule new meeting"
              />
            </div>
          )}
          <Layout.Spacer height={10} />
          <div
            style={
              location.state.edit === true
                ? { ...styles.pointerDsiabled, width: "100%" }
                : { width: "100%" }
            }
          >
            <Layout.Spacer height={10} />

            <TextField.CustomInput
              placeholder="Subject of the meeting"
              label="Subject *"
              name="subject"
              style={styles.textfieldStyle}
              onChange={handleChange}
              value={state.subject}
            />
          </div>

          <Layout.Spacer height={10} />
          <Text.Heading
            fontSize={"14px"}
            fontWeight={"200"}
            color={Colors.Black}
            text="Student *"
            opacity={location?.state?.edit === true ? "0.6" : "1"}
          />
          {location?.state?.edit === true ? (
            <div
              style={{
                ...styles.flexRowSpace,
                pointerEvents: location?.state?.edit === true ? "none" : "",
                opacity: location?.state?.edit === true ? 0.6 : 1,
                textTransform: "capitalize",
              }}
            >
              <div style={styles.counselorDisabled}>
                {
                  students?.find((student) => {
                    return student._id === studentId;
                  })?.name
                }
              </div>
            </div>
          ) : (
            <>
              <div className="counselor-select">
                <Select
                  style={styles.counselorDropdown}
                  value={studentId}
                  onChange={(e) => setStudentId(e)}
                  filterOption={(input, dropdown) =>
                    (dropdown?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  showSearch
                >
                  {students?.map((studentName) => {
                    return (
                      <Select.Option
                        value={studentName._id}
                        key={studentName._id}
                        style={{ textTransform: "capitalize" }}
                      >
                        {studentName.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </div>
            </>
          )}

          <Layout.Spacer height={10} />
          <Text.Heading style={styles.boxHeader} text="Meeting Date*" />

          <DatePicker
            style={styles.meetingDatePicker}
            disabledDate={disabledDate}
            onChange={onDateChange}
          />
          <Layout.Spacer height={10} />
          <div style={{ display: "flex" }}>
            <div style={{ width: "100%", marginRight: "7px" }}>
              <Text.Heading style={styles.boxHeader} text="Time Slot*" />
            </div>
            <div style={{ width: "100%" }}>
              <Text.Heading
                style={
                  location.state.edit === true
                    ? { ...styles.boxHeader, opacity: "0.8" }
                    : { ...styles.boxHeader, opacity: "1" }
                }
                text="Document Attachment"
              />
            </div>
          </div>

          <div style={styles.rowStyle} className="counselor-select">
            <div style={{ width: "100%", marginRight: "10px" }}>
              <Select
                style={styles.counselorDropdown}
                onChange={(e) =>
                  setState({
                    ...state,
                    timeSlot: e,
                  })
                }
                showSearch
                placeholder="Search Time Slot "
                value={state.timeSlot}
              >
                <Select.Option value="12:00 AM - 12:30 AM">
                  12:00 AM - 12:30 AM
                </Select.Option>
                <Select.Option value="12:30 AM - 01:00 AM">
                  12:30 AM - 01:00 AM
                </Select.Option>
                <Select.Option value="01:00 AM - 01:30 AM">
                  01:00 AM - 01:30 AM
                </Select.Option>
                <Select.Option value="01:30 AM - 02:00 AM">
                  01:30 AM - 02:00 AM
                </Select.Option>
                <Select.Option value="02:00 AM - 02:30 AM">
                  02:00 AM - 02:30 AM
                </Select.Option>
                <Select.Option value="02:30 AM - 03:00 AM">
                  02:30 AM - 03:00 AM
                </Select.Option>
                <Select.Option value="03:00 AM - 03:30 AM">
                  03:00 AM - 03:30 AM
                </Select.Option>
                <Select.Option value="03:30 AM - 04:00 AM">
                  03:30 AM - 04:00 AM
                </Select.Option>
                <Select.Option value="04:00 AM - 04:30 AM">
                  04:00 AM - 04:30 AM
                </Select.Option>
                <Select.Option value="04:30 AM - 05:00 AM">
                  04:30 AM - 05:00 AM
                </Select.Option>
                <Select.Option value="05:00 AM - 05:30 AM">
                  05:00 AM - 05:30 AM
                </Select.Option>
                <Select.Option value="05:30 AM - 06:00 AM">
                  05:30 AM - 06:00 AM
                </Select.Option>
                <Select.Option value="06:00 AM - 06:30 AM">
                  06:00 AM - 06:30 AM
                </Select.Option>
                <Select.Option value="06:30 AM - 07:00 AM">
                  06:30 AM - 07:00 AM
                </Select.Option>
                <Select.Option value="07:00 AM - 07:30 AM">
                  07:00 AM - 07:30 AM
                </Select.Option>
                <Select.Option value="07:30 AM - 08:00 AM">
                  07:30 AM - 08:00 AM
                </Select.Option>
                <Select.Option value="08:00 AM - 08:30 AM">
                  08:00 AM - 08:30 AM
                </Select.Option>
                <Select.Option value="08:30 AM - 09:00 AM">
                  08:30 AM - 09:00 AM
                </Select.Option>
                <Select.Option value="09:00 AM - 09:30 AM">
                  09:00 AM - 09:30 AM
                </Select.Option>
                <Select.Option value="09:30 AM - 10:00 AM">
                  09:30 AM - 10:00 AM
                </Select.Option>
                <Select.Option value="10:00 AM - 10:30 AM">
                  10:00 AM - 10:30 AM
                </Select.Option>
                <Select.Option value="10:30 AM - 11:00 AM">
                  10:30 AM - 11:00 AM
                </Select.Option>
                <Select.Option value="11:00 AM - 11:30 AM">
                  11:00 AM - 11:30 AM
                </Select.Option>
                <Select.Option value="11:30 AM - 12:00 PM">
                  11:30 AM - 12:00 PM
                </Select.Option>
                <Select.Option value="12:00 PM - 12:30 PM">
                  12:00 PM - 12:30 PM
                </Select.Option>
                <Select.Option value="12:30 PM - 01:00 PM">
                  12:30 PM - 01:00 PM
                </Select.Option>
                <Select.Option value="01:00 PM - 01:30 PM">
                  01:00 PM - 01:30 PM
                </Select.Option>
                <Select.Option value="01:30 PM - 02:00 PM">
                  01:30 PM - 02:00 PM
                </Select.Option>
                <Select.Option value="02:00 PM - 02:30 PM">
                  02:00 PM - 02:30 PM
                </Select.Option>
                <Select.Option value="02:30 PM - 03:00 PM">
                  02:30 PM - 03:00 PM
                </Select.Option>
                <Select.Option value="03:00 PM - 03:30 PM">
                  03:00 PM - 03:30 PM
                </Select.Option>
                <Select.Option value="03:30 PM - 04:00 PM">
                  03:30 PM - 04:00 PM
                </Select.Option>
                <Select.Option value="04:00 PM - 04:30 PM">
                  04:00 PM - 04:30 PM
                </Select.Option>
                <Select.Option value="04:30 PM - 05:00 PM">
                  04:30 PM - 05:00 PM
                </Select.Option>
                <Select.Option value="05:00 PM - 05:30 PM">
                  05:00 PM - 05:30 PM
                </Select.Option>
                <Select.Option value="05:30 PM - 06:00 PM">
                  05:30 PM - 06:00 PM
                </Select.Option>
                <Select.Option value="06:00 PM - 06:30 PM">
                  06:00 PM - 06:30 PM
                </Select.Option>
                <Select.Option value="06:30 PM - 07:00 PM">
                  06:30 PM - 07:00 PM
                </Select.Option>
                <Select.Option value="07:00 PM - 07:30 PM">
                  07:00 PM - 07:30 PM
                </Select.Option>
                <Select.Option value="07:30 PM - 08:00 PM">
                  07:30 PM - 08:00 PM
                </Select.Option>
                <Select.Option value="08:00 PM - 08:30 PM">
                  08:00 PM - 08:30 PM
                </Select.Option>
                <Select.Option value="08:30 PM - 09:00 PM">
                  08:30 PM - 09:00 PM
                </Select.Option>
                <Select.Option value="09:00 PM - 09:30 PM">
                  09:00 PM - 09:30 PM
                </Select.Option>
                <Select.Option value="09:30 PM - 10:00 PM">
                  09:30 PM - 10:00 PM
                </Select.Option>
                <Select.Option value="10:00 PM - 10:30 PM">
                  10:00 PM - 10:30 PM
                </Select.Option>
                <Select.Option value="10:30 PM - 11:00 PM">
                  10:30 PM - 11:00 PM
                </Select.Option>
                <Select.Option value="11:00 PM - 11:30 PM">
                  11:00 PM - 11:30 PM
                </Select.Option>
                <Select.Option value="11:30 PM - 12:00 AM">
                  11:30 PM - 12:00 AM
                </Select.Option>
              </Select>
            </div>

            {location.state.edit === true ? (
              <div style={{ width: "100%" }}>
                <div
                  style={styles.meetingFileDsiabled}
                  onClick={() => {
                    if (location.state.data.meetingFile) {
                      window.open(
                        location.state.data.meetingFile
                          ? location.state.data.meetingFile
                          : "#",
                        "_blank"
                      );
                    }
                  }}
                >
                  <div>
                    {location.state.data.meetingFile
                      ? "Click to open file"
                      : "No File"}
                  </div>
                  <div>
                    <CgAttachment color={Colors.Primary} />
                  </div>
                </div>
              </div>
            ) : (
              <div style={{ width: "100%" }}>
                <div
                  style={styles.meetingFileInput}
                  onClick={() => {
                    document.getElementById("getFile").click();
                  }}
                >
                  <input
                    style={{ display: "none" }}
                    type="file"
                    name="csv"
                    id="getFile"
                    ref={fileSelect}
                    onChange={(event) => {
                      setSelectedFile(event.target.files[0]);
                    }}
                    accept=".doc,.docx,.csv,.xlsx, .pdf"
                  />
                  <div>
                    {selectedFile ? selectedFile.name : "Select a file"}
                  </div>
                  <div>
                    <CgAttachment color={Colors.Primary} />
                  </div>
                </div>
              </div>
            )}
          </div>

          <Layout.Spacer height={10} />

          <Text.Heading style={styles.boxHeader} text="Message*" />

          <div>
            <TextArea
              rows={4}
              style={{ width: "100%" }}
              onChange={(e) => {
                setState({
                  ...state,
                  message: e.target.value,
                });
              }}
              value={state.message}
              placeholder={"Meeting description"}
            />
          </div>

          <Layout.Spacer height={10} />
          {state.meetingType && (
            <>
              <div
                style={{
                  pointerEvents: location?.state?.edit === true && "none",
                  opacity: location?.state?.edit === true && 0.7,
                }}
              >
                <div style={{ width: "100%" }}>
                  <TextField.CustomInput
                    label={
                      state.meetingType === "Online"
                        ? "Meeting Link"
                        : "Meeting Place"
                    }
                    name="meetingLink"
                    style={styles.textfieldStyle}
                    onChange={handleChange}
                    value={state.meetingLink}
                    placeholder={
                      state.meetingType === "Online"
                        ? "Link of the Meeting"
                        : "Place of the Meeting"
                    }
                  />
                </div>
              </div>
              <Layout.Spacer height={10} />
            </>
          )}

          <div style={styles.rowStyle}>
            <div
              style={
                location.state.edit === true ? styles.pointerDsiabled : null
              }
            >
              <Radio.Group
                options={plainOptions}
                onChange={handleChange}
                name="meetingType"
                value={state.meetingType}
              />
            </div>
            {error && <div style={{ color: "red" }}>{error}</div>}
            <div>
              <Button.Primary
                onClick={scheduleMeeting}
                style={styles.buttonStyle}
                disabled={buttonDisabled}
              >
                {buttonText}
              </Button.Primary>
            </div>
          </div>

          <Layout.Spacer height={20} />
          {successModal && (
            <Modal.Basic
              isModalVisible={successModal}
              centered={true}
              handleCancel={() => setSuccessModal(false)}
              closable={false}
            >
              <div style={styles.modalContainer}>
                <Lottie
                  loop
                  animationData={Done}
                  play
                  style={styles.modalIcon}
                ></Lottie>

                <Text.Heading style={styles.modalHeader} text="Successful!" />

                <Text.Heading
                  style={styles.modalDescription}
                  text="Schedule meeting successfully"
                />

                <Button.Primary
                  style={styles.modalButton}
                  onClick={() => {
                    setSuccessModal(false);
                    navigate("/meetings/meetingdetails", {
                      state: { data: bodyData, sent: "true" },
                    });
                  }}
                >
                  Ok
                </Button.Primary>
              </div>
            </Modal.Basic>
          )}
          {failModal && (
            <Modal.Basic
              isModalVisible={failModal}
              centered={true}
              handleCancel={() => setFailModal(false)}
              closable={false}
            >
              <div style={styles.modalContainer}>
                <AiFillCloseCircle color="red" style={styles.modalIcon} />

                <Text.Heading
                  fontSize={"24px"}
                  fontWeight={"700"}
                  color={Colors.Red}
                  text="Unsuccessful!"
                />
                <Layout.Spacer height={10} />
                <Text.Heading
                  style={styles.modalDescription}
                  text="We have encountered an error. Please check if you have filled all
                the required fields."
                />

                <Button.Primary
                  style={styles.modalButton}
                  onClick={() => {
                    setFailModal(false);
                  }}
                >
                  Ok
                </Button.Primary>
              </div>
            </Modal.Basic>
          )}
        </>
      )}
    </>
  );
}

export default AddMeeting;
