import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { Form } from "react-bootstrap";

import { AiFillCloseCircle } from "react-icons/ai";
import { FiEye, FiEyeOff } from "react-icons/fi";

import Lottie from "react-lottie-player";

import { AuthAction } from "../../store/actions";

import { Button, Layout, Modal, Text } from "../../components";
import { Colors, Utils } from "../../config";

import styles from "./styles";
import Done from "../../assets/images/57767-done.json";

function PasswordForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    password: "",
    confirmPassword: "",
    passwordError: "",
    confirmPasswordError: "",
  });
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [failModal, setFailModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  const handlePasswordChange = (e) => {
    setState({
      ...state,
      password: e.target.value,
    });
  };

  const handleConfirmPasswordChange = (e) => {
    setState({
      ...state,
      confirmPassword: e.target.value,
    });
  };

  const handleNextPassword = () => {
    if (state.password === "") {
      setState({
        ...state,
        passwordError: "Password can not be left empty",
      });
    } else if (Utils.passwordRegex.test(state.password) === false) {
      setState({
        ...state,
        passwordError: "Please enter a valid password",
      });
    } else if (state.confirmPassword === "") {
      setState({
        ...state,
        passwordError: "",
        confirmPasswordError: "Password can not be left empty",
      });
    } else if (Utils.passwordRegex.test(state.confirmPassword) === false) {
      setState({
        ...state,
        confirmPasswordError: "Please enter a valid password",
      });
    } else if (state.password !== state.confirmPassword) {
      setState({
        ...state,
        confirmPasswordError: "Passwords did not match",
      });
    } else {
      setState({
        ...state,
        passwordError: "",
        confirmPasswordError: "",
      });
      if (location.state.role === "School") {
        let data = new FormData();

        data.append("email", location?.state?.email);
        data.append("password", state?.password);
        data.append("role", "School");
        dispatch(
          AuthAction.Signup(data, (res) => {
            if (res.data.status === 201) {
              setSuccessModal(true);
            } else {
              setFailModal(true);
            }
          })
        );
      } else {
        navigate("/signup/details", {
          state: {
            email: location.state.email,
            password: state.password,
            role: "Counselor",
          },
        });
      }
    }
  };

  return (
    <>
      <Layout.LoginLayout backLink={"/signup/verify-otp"}>
        <Layout.HeaderSection
          title="Set New Password"
          subtitle="Enter new password and confirm password"
        />
        <Layout.Spacer height={30} />

        <Form.Group>
          <Form.Label>Set Password</Form.Label>
          <div style={styles.passwordContainer}>
            <Form.Control
              type={showPassword1 ? "text" : "password"}
              placeholder="Password"
              value={state.password}
              onChange={handlePasswordChange}
            />
            {showPassword1 ? (
              <FiEye
                fontSize={17}
                style={styles.eyeIcon}
                onClick={() => setShowPassword1(false)}
              />
            ) : (
              <FiEyeOff
                fontSize={17}
                style={styles.eyeIcon}
                onClick={() => setShowPassword1(true)}
              />
            )}
          </div>
          {state.passwordError && (
            <Form.Text className="text-danger">{state.passwordError}</Form.Text>
          )}
        </Form.Group>
        <Layout.Spacer height={20} />
        <Form.Group>
          <Form.Label>Confirm New Password</Form.Label>
          <div style={styles.passwordContainer}>
            <Form.Control
              type={showPassword2 ? "text" : "password"}
              placeholder="Password"
              value={state.confirmPassword}
              onChange={handleConfirmPasswordChange}
            />
            {showPassword2 ? (
              <FiEye
                fontSize={17}
                style={styles.eyeIcon}
                onClick={() => setShowPassword2(false)}
              />
            ) : (
              <FiEyeOff
                fontSize={17}
                style={styles.eyeIcon}
                onClick={() => setShowPassword2(true)}
              />
            )}
          </div>
          {state.confirmPasswordError && (
            <Form.Text className="text-danger">
              {state.confirmPasswordError}
            </Form.Text>
          )}
        </Form.Group>
        <Layout.Spacer height={40} />

        <Button.Primary style={styles.buttonStyle} onClick={handleNextPassword}>
          Continue
        </Button.Primary>

        <Layout.Spacer height={20} />
      </Layout.LoginLayout>
      {successModal && (
        <Modal.Basic
          isModalVisible={successModal}
          centered={true}
          handleCancel={() => setSuccessModal(false)}
          closable={false}
        >
          <div style={styles.flexColumnCenter}>
            <Lottie
              loop
              animationData={Done}
              play
              style={{ height: "120px" }}
            ></Lottie>
            <Layout.Spacer height={20} />
            <Text.Heading
              fontSize={"32px"}
              fontWeight={"700"}
              color={Colors.Black}
              text="Successful!"
            />
            <Layout.Spacer height={20} />
            <Text.Heading
              fontSize={"18px"}
              fontWeight={"400"}
              color={Colors.Black}
              text="School registered successfully."
            />
            <Layout.Spacer height={20} />
            <Button.Primary
              style={styles.modalButton}
              onClick={() => {
                setSuccessModal(false);
                navigate("/login");
              }}
            >
              Ok
            </Button.Primary>
          </div>
          <Layout.Spacer height={10} />
        </Modal.Basic>
      )}
      {failModal && (
        <Modal.Basic
          isModalVisible={failModal}
          centered={true}
          handleCancel={() => setFailModal(false)}
          closable={false}
        >
          <div style={styles.flexColumnCenter}>
            <AiFillCloseCircle
              color="red"
              style={{ width: "70px", height: "70px" }}
            />
            <Layout.Spacer height={20} />
            <Text.Heading
              fontSize={"32px"}
              fontWeight={"700"}
              color={Colors.Black}
              text="Unsuccessful!"
            />
            <Layout.Spacer height={20} />
            <Text.Heading
              fontSize={"18px"}
              fontWeight={"400"}
              color={Colors.Black}
              text="We have encountered an error. Please check if you have filled all
                the required fields."
            />
            <Layout.Spacer height={20} />
            <Button.Primary
              style={styles.modalButton}
              onClick={() => {
                setFailModal(false);
              }}
            >
              Ok
            </Button.Primary>
          </div>
          <Layout.Spacer height={10} />
        </Modal.Basic>
      )}
    </>
  );
}

export default PasswordForm;
