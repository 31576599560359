import { delay, put } from "redux-saga/effects";

import { ApiCaller, Utils } from "../../config";
import { AuthAction } from "../actions";

export default class AuthMiddleware {
  static *SignIn({ payload, cb }) {
    try {
      let res = yield ApiCaller.Post("/auth/login", payload);
      if (
        res.data.status === 404 &&
        res.data.message === "User does not exist"
      ) {
        yield put(AuthAction.SignInFailure());
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
      } else if (
        res.data.status === 400 &&
        res.data.message === "Invalid Email or Password."
      ) {
        yield put(AuthAction.SignInFailure());
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
      } else if (
        res.data.status === 401 &&
        res.data.message === "You are not allowed to access this route"
      ) {
        yield put(AuthAction.SignInFailure());
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
      } else if (
        res.data.status === 403 &&
        res.data.message ===
          "Login failed. Your account is banned by the admin."
      ) {
        yield put(AuthAction.SignInFailure());
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
      } else {
        yield put(AuthAction.SignInSuccess(res.data.data));
        Utils.showSnackBar({ message: res.data.message });
        // yield delay(2000);
        yield localStorage.setItem("authUser", JSON.stringify(res.data.data));
        if (cb) {
          cb();
        }
      }
    } catch (err) {
      yield put(AuthAction.SignInFailure());
    }
  }

  static *Signup({ payload, cb }) {
    try {
      let res = yield ApiCaller.Post("/auth/signup", payload);
      if (
        res.data.status === 400 &&
        res.data.message === "Email is already taken"
      ) {
        yield put(AuthAction.SignupFailure());
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
        if (cb) {
          cb(res);
        }
      } else {
        yield put(AuthAction.SignupSuccess(res.data.data));
        Utils.showSnackBar({ message: res.data.message });
        yield delay(2000);
        if (cb) {
          cb(res);
        }
      }
    } catch (error) {
      yield put(AuthAction.SignupFailure());
    }
  }

  static *Logout({ cb }) {
    try {
      // let res = yield ApiCaller.Post(
      //     "logout",
      //     payload
      // )
      // if (res.status == 200) {
      //     yield put(AuthAction.LogoutSuccess())
      // } else {
      //     yield put(AuthAction.LogoutFailure())
      // }
      yield delay(1000);
      localStorage.removeItem("authUser");
      yield put(AuthAction.LogoutSuccess());
      // Utils.showSnackBar({ message: 'Successfully logout' });
      cb();
    } catch (err) {
      yield put(AuthAction.LogoutFailure());
    }
  }

  static *ForgotPassword({ payload, cb }) {
    try {
      let res = yield ApiCaller.Post("/auth/forget-password", payload);
      if (res.data.status === 200) {
        yield put(AuthAction.ForgotPasswordSuccess(res.data.data));
        Utils.showSnackBar({
          message: res.data.message,
        });
        delay(2000);
        cb();
      } else {
        yield put(AuthAction.ForgotPasswordFailure());
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(AuthAction.ForgotPasswordFailure());
    }
  }

  static *VerifyOtp({ payload, cb }) {
    try {
      let res = yield ApiCaller.Post("/auth/verify-code", payload);
      if (res.data.status === 200) {
        yield put(AuthAction.VerifyOtpSuccess(res.data.data));
        Utils.showSnackBar({
          message: res.data.message,
        });
        yield delay(2000);
        cb();
      } else {
        yield put(AuthAction.VerifyOtpFailure());
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(AuthAction.VerifyOtpFailure());
    }
  }

  static *ResetPassword({ payload, cb }) {
    try {
      let res = yield ApiCaller.Post("/auth/reset-password", payload);
      if (res.data.status === 200) {
        yield put(AuthAction.ResetPasswordSuccess(res.data.data));
        Utils.showSnackBar({
          message: res.data.message,
        });
        yield delay(2000);
        cb(res);
      } else {
        yield put(AuthAction.ResetPasswordFailure());
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(AuthAction.ResetPasswordFailure());
    }
  }

  static *CheckEmail({ payload, cb }) {
    try {
      let res = yield ApiCaller.Post("/auth/check-user-email", payload);
      if (res.data.status === 200) {
        yield put(AuthAction.CheckEmailSuccess(res.data.data));
        Utils.showSnackBar({
          message: res.data.message,
        });
        delay(2000);
        cb(res);
      } else {
        yield put(AuthAction.CheckEmailFailure());
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(AuthAction.CheckEmailFailure());
    }
  }

  static *VerifySignupOtp({ payload, cb }) {
    try {
      let res = yield ApiCaller.Post("/auth/verify-signup-otp", payload);
      if (res.data.status === 200) {
        yield put(AuthAction.VerifySignupOtpSuccess(res.data.data));
        Utils.showSnackBar({
          message: res.data.message,
        });
        yield delay(2000);
        cb();
      } else {
        yield put(AuthAction.VerifySignupOtpFailure());
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(AuthAction.VerifySignupOtpFailure());
    }
  }
}
