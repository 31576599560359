import { Colors } from "../../config";
import { Layout, Text } from "../../components";

function Permissions() {
  return (
    <>
      <Text.Heading
        fontSize={"18px"}
        fontWeight={"600"}
        color={Colors.Primary}
        text="Permissions"
      />
      <Layout.Spacer height={0} />
      <Text.Heading
        fontSize={"16px"}
        fontWeight={"400"}
        color={Colors.BlackText}
        text="Here you can see what account type you have and subsequently, what permissions your account holds."
      />
      <Layout.Spacer height={15} />
      <Text.Heading
        fontSize={"18px"}
        fontWeight={"600"}
        color={Colors.Primary}
        text="Account Type : "
      />
      <Text.Heading
        fontSize={"18px"}
        fontWeight={"400"}
        color={Colors.BlackText}
        text=" Admin"
      />
    </>
  );
}

export default Permissions;
