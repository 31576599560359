import { all, takeLatest } from "redux-saga/effects";

import {
  ADD_SCHOOL_STUDENT_SHEET,
  ADD_ANNOUNCEMENT,
  ADD_NOTIFICATIONS,
  ADD_BILLING,
  ADD_BILL_INFORMATION,
  ADD_CONTACT,
  ADD_INTEREST,
  ADD_MEETING,
  ADD_OR_REMOVE_FROM_WISHLIST,
  ADD_SCHOOL_STUDENT,
  ADD_STAFF,
  CHANGE_ANNOUNCEMENT_STATUS,
  CHANGE_NOTIFICATIONS_STATUS,
  UPDATE_BILLING,
  CHANGE_APPLICATION_STATUS,
  CHANGE_MEETING_STATUS,
  CHANGE_PASSWORD,
  CHANGE_ROLE,
  CHECK_EMAIL,
  EDIT_PROFILE,
  FORGOT_PASSWORD,
  GET_ACTIVE_STUDENTS,
  GET_ANNOUNCEMENTS,
  GET_NOTIFICATIONS,
  GET_BILLING,
  GET_APPLICATIONS,
  GET_BILL_INFORMATION,
  GET_COUNSELOR_INFO,
  GET_MEETINGS_INVITES_AND_REQUESTS,
  GET_ALL_MEETINGS_AND_COUNT,
  GET_MEETINGS_BASED_ON_DATE,
  GET_POPULAR_MAJORS,
  GET_POPULAR_UNIVERSITIES,
  GET_PROFILE,
  GET_REGISTERED_STUDENTS,
  GET_REGISTERED_STUDENT_DETAILS,
  GET_SCHOOL_ACTIVE_STUDENTS,
  GET_STAFF,
  GET_STUDENTS_DETAILS,
  GET_STUDENT_DETAILS,
  GET_STUDENT_MEETING_DETAILS,
  GET_STUDENT_ALL_MEETING_DETAILS,
  GET_UNIVERSITIES,
  GET_ALL_UNIVERSITIES,
  GET_UNIVERSITY_DETAILS,
  GET_INVOICES,
  LOGIN,
  LOGOUT,
  REMOVE_INTEREST,
  REMOVE_STAFF,
  RESET_PASSWORD,
  SEND_STUDENT_EMAIL,
  SIGNUP,
  VERIFY_OTP,
  VERIFY_SIGNUP_OTP,
  UPDATE_CONVERTER,
  GET_MESSAGES,
  GET_UNREAD_MESSAGES,
  ADD_MESSAGE,
  GET_STUDENTS,
} from "../constants";
import {
  AnnouncementMiddleware,
  ApplicationMiddleware,
  AuthMiddleware,
  ContactMiddleware,
  MeetingMiddleware,
  ProfileMiddleware,
  StaffMiddleware,
  StudentMiddleware,
  UniversityMiddleware,
  NotificationsMiddleware,
  BillingMiddleware,
  ConverterMiddleware,
  ChatbotMiddleware,
} from "../middlewares";

export function* Sagas() {
  yield all([
    //login flow
    yield takeLatest(LOGIN, AuthMiddleware.SignIn),
    yield takeLatest(SIGNUP, AuthMiddleware.Signup),
    yield takeLatest(LOGOUT, AuthMiddleware.Logout),
    yield takeLatest(FORGOT_PASSWORD, AuthMiddleware.ForgotPassword),
    yield takeLatest(VERIFY_OTP, AuthMiddleware.VerifyOtp),
    yield takeLatest(RESET_PASSWORD, AuthMiddleware.ResetPassword),
    yield takeLatest(CHECK_EMAIL, AuthMiddleware.CheckEmail),
    yield takeLatest(VERIFY_SIGNUP_OTP, AuthMiddleware.VerifySignupOtp),

    //university
    yield takeLatest(GET_UNIVERSITIES, UniversityMiddleware.getUniversities),
    yield takeLatest(
      GET_ALL_UNIVERSITIES,
      UniversityMiddleware.getAllUniversities
    ),
    yield takeLatest(
      GET_POPULAR_UNIVERSITIES,
      UniversityMiddleware.getPopularUniversities
    ),
    yield takeLatest(
      GET_UNIVERSITY_DETAILS,
      UniversityMiddleware.getUniversityDetails
    ),
    yield takeLatest(
      ADD_OR_REMOVE_FROM_WISHLIST,
      UniversityMiddleware.addOrRemoveWishlist
    ),

    //students
    yield takeLatest(GET_ACTIVE_STUDENTS, StudentMiddleware.getActiveStudents),
    yield takeLatest(
      GET_REGISTERED_STUDENTS,
      StudentMiddleware.getRegisteredStudents
    ),
    yield takeLatest(
      GET_SCHOOL_ACTIVE_STUDENTS,
      StudentMiddleware.getSchoolActiveStudents
    ),
    yield takeLatest(GET_STUDENT_DETAILS, StudentMiddleware.getStudentDetails),
    yield takeLatest(
      GET_REGISTERED_STUDENT_DETAILS,
      StudentMiddleware.getRegisteredStudentDetails
    ),
    yield takeLatest(ADD_SCHOOL_STUDENT, StudentMiddleware.addSchoolStudent),
    yield takeLatest(
      ADD_SCHOOL_STUDENT_SHEET,
      StudentMiddleware.addSchoolStudentSheet
    ),
    yield takeLatest(
      GET_STUDENTS_DETAILS,
      StudentMiddleware.getStudentsDetails
    ),
    yield takeLatest(SEND_STUDENT_EMAIL, StudentMiddleware.sendStudentEmail),
    yield takeLatest(
      GET_STUDENT_MEETING_DETAILS,
      StudentMiddleware.getStudentMeetingDetails
    ),
    yield takeLatest(
      GET_STUDENT_ALL_MEETING_DETAILS,
      StudentMiddleware.getStudentAllMeetingDetails
    ),

    //user profile
    yield takeLatest(GET_PROFILE, ProfileMiddleware.getUserProfile),
    yield takeLatest(EDIT_PROFILE, ProfileMiddleware.editUserProfile),
    yield takeLatest(ADD_INTEREST, ProfileMiddleware.addProfileInterest),
    yield takeLatest(REMOVE_INTEREST, ProfileMiddleware.removeProfileInterest),
    yield takeLatest(
      GET_BILL_INFORMATION,
      ProfileMiddleware.getBillInformation
    ),
    yield takeLatest(
      ADD_BILL_INFORMATION,
      ProfileMiddleware.addBillInformation
    ),
    yield takeLatest(CHANGE_PASSWORD, ProfileMiddleware.changePassword),

    //miscellaneous
    yield takeLatest(ADD_CONTACT, ContactMiddleware.addContact),
    yield takeLatest(CHANGE_ROLE, StaffMiddleware.changeRole),

    //staff
    yield takeLatest(ADD_STAFF, StaffMiddleware.addStaff),
    yield takeLatest(GET_STAFF, StaffMiddleware.getStaff),
    yield takeLatest(REMOVE_STAFF, StaffMiddleware.removeStaff),
    yield takeLatest(GET_COUNSELOR_INFO, StaffMiddleware.getCounselorInfo),

    //announcements
    yield takeLatest(ADD_ANNOUNCEMENT, AnnouncementMiddleware.addAnnouncement),
    yield takeLatest(
      GET_ANNOUNCEMENTS,
      AnnouncementMiddleware.getAnnouncements
    ),
    yield takeLatest(
      CHANGE_ANNOUNCEMENT_STATUS,
      AnnouncementMiddleware.changeAnnouncementStatus
    ),

    //notifications
    yield takeLatest(
      ADD_NOTIFICATIONS,
      NotificationsMiddleware.addNotifications
    ),
    yield takeLatest(
      GET_NOTIFICATIONS,
      NotificationsMiddleware.getNotifications
    ),
    yield takeLatest(
      CHANGE_NOTIFICATIONS_STATUS,
      NotificationsMiddleware.changeNotificationsStatus
    ),

    //billing
    yield takeLatest(ADD_BILLING, BillingMiddleware.addBilling),
    yield takeLatest(GET_BILLING, BillingMiddleware.getBilling),
    yield takeLatest(GET_INVOICES, BillingMiddleware.getInvoices),
    yield takeLatest(UPDATE_BILLING, BillingMiddleware.updateBilling),

    //meetings
    yield takeLatest(ADD_MEETING, MeetingMiddleware.addMeeting),
    yield takeLatest(
      GET_MEETINGS_INVITES_AND_REQUESTS,
      MeetingMiddleware.getMeetingInvitesAndRequests
    ),
    yield takeLatest(
      GET_ALL_MEETINGS_AND_COUNT,
      MeetingMiddleware.getAllMeetingsAndCount
    ),
    yield takeLatest(
      GET_MEETINGS_BASED_ON_DATE,
      MeetingMiddleware.getMeetingsBasedOnDate
    ),
    yield takeLatest(
      CHANGE_MEETING_STATUS,
      MeetingMiddleware.changeMeetingStatus
    ),

    //applications
    yield takeLatest(GET_APPLICATIONS, ApplicationMiddleware.getApplications),
    yield takeLatest(
      CHANGE_APPLICATION_STATUS,
      ApplicationMiddleware.changeApplicationStatus
    ),

    //converter
    yield takeLatest(UPDATE_CONVERTER, ConverterMiddleware.updateConverter),

    //chatbot
    yield takeLatest(GET_MESSAGES, ChatbotMiddleware.getMessages),
    yield takeLatest(GET_UNREAD_MESSAGES, ChatbotMiddleware.getUnreadMessages),
    yield takeLatest(ADD_MESSAGE, ChatbotMiddleware.addMessage),
    yield takeLatest(GET_STUDENTS, ChatbotMiddleware.getStudents),
  ]);
}
