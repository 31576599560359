import { put } from "redux-saga/effects";

import { ApiCaller, Utils } from "../../config";
import { ProfileAction } from "../actions";

export default class ProfileMiddleware {
  static *getUserProfile({ cb }) {
    try {
      let res = yield ApiCaller.Get("/user/get-profile-data");
      if (res.status === 200) {
        yield put(ProfileAction.getUserProfileSuccess(res.data.data));
        if (cb) {
          cb(res.status);
        }
      } else {
        yield put(ProfileAction.getUserProfileFailure());
      }
    } catch (err) {
      yield put(ProfileAction.getUserProfileFailure());
    }
  }

  static *editUserProfile({ payload, cb }) {
    try {
      let res = yield ApiCaller.Post(`/user/edit-profile-data`, payload);
      if (res.status === 200) {
        yield put(ProfileAction.editUserProfileSuccess(res.data.data));
        if (cb) {
          cb();
        }
      } else {
        yield put(ProfileAction.editUserProfileFailure());
        // Utils.showSnackBar({
        //   message: res.data.message,
        //   severity: 'error',
        // });
      }
    } catch (err) {
      yield put(ProfileAction.editUserProfileFailure());
    }
  }

  static *addProfileInterest({ payload, cb }) {
    try {
      let res = yield ApiCaller.Post(`/user/add-user-interest`, payload);
      if (res.status === 200) {
        yield put(ProfileAction.addInterestSuccess(res.data.data));
        if (cb) {
          cb();
        }
      } else {
        yield put(ProfileAction.addInterestFailure());
        // Utils.showSnackBar({
        //   message: res.data.message,
        //   severity: 'error',
        // });
      }
    } catch (err) {
      yield put(ProfileAction.addInterestFailure());
    }
  }

  static *removeProfileInterest({ interest, cb }) {
    try {
      let res = yield ApiCaller.Get(
        `/user/remove-user-interest?interest=${interest}`
      );
      if (res.status === 200) {
        yield put(ProfileAction.removeInterestSuccess(res.data.data));
        if (cb) {
          cb();
        }
      } else {
        yield put(ProfileAction.removeInterestFailure());
        // Utils.showSnackBar({
        //   message: res.data.message,
        //   severity: 'error',
        // });
      }
    } catch (err) {
      yield put(ProfileAction.removeInterestFailure());
    }
  }

  static *getBillInformation({ cb }) {
    try {
      let res = yield ApiCaller.Get("/user/get-user-bill");
      if (res.status === 200) {
        yield put(ProfileAction.getUserBillInfoSuccess(res.data.data));
        if (cb) {
          cb();
        }
      } else {
        yield put(ProfileAction.getUserBillInfoFailure());
      }
    } catch (err) {
      yield put(ProfileAction.getUserBillInfoFailure());
    }
  }

  static *addBillInformation({ payload, cb }) {
    try {
      let res = yield ApiCaller.Post(`/user/add-user-bill`, payload);
      if (res.status === 200) {
        yield put(ProfileAction.addUserBillInfoSuccess(res.data.data));
        if (cb) {
          cb();
        }
      } else {
        yield put(ProfileAction.addUserBillInfoFailure());
        // Utils.showSnackBar({
        //   message: res.data.message,
        //   severity: 'error',
        // });
      }
    } catch (err) {
      yield put(ProfileAction.addUserBillInfoFailure());
    }
  }

  static *changePassword({ payload, cb }) {
    try {
      let res = yield ApiCaller.Post(`/user/change-password`, payload);
      if (res.data.status === 400 && res.data.message === "Wrong password.") {
        yield put(ProfileAction.changePasswordFailure());
        if (cb) {
          cb(res);
        }
      } else {
        yield put(ProfileAction.changePasswordSuccess(res.data.data));
        Utils.showSnackBar({
          message: `${res.data.message}. Login again with new credentials`,
        });
        if (cb) {
          cb(res);
        }
      }
    } catch (err) {
      yield put(ProfileAction.changePasswordFailure());
    }
  }
}
