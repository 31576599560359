import Basic from "./Basic";
import Primary from "./Primary";
import TabButton from "./TabButton";

const Button = {
  Basic,
  TabButton,
  Primary,
};

export default Button;
