import React from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

import { AiOutlineCloudDownload } from "react-icons/ai";

import { Layout, Table, Text, dateFormatter } from "../../components";
import { Colors } from "../../config";

import styles from "./styles";

function PaymentDetails() {
  const navigate = useNavigate();

  const billingState = useSelector((state) => state.billing);
  const billingArray = useSelector(
    (state) => state.billing?.billings?.updatedBilling?.stripeBills
  );
  const manualBillingArray = useSelector(
    (state) => state.billing?.billings?.updatedBilling?.manualBills
  );

  const sortedBillingArray =
    billingArray?.length > 0
      ? billingArray.sort((a, b) => new Date(b.date) - new Date(a.date))
      : [];
  const sortedManualBillingArray =
    manualBillingArray?.length > 0
      ? manualBillingArray.sort((a, b) => new Date(b.date) - new Date(a.date))
      : [];

  const manualData = [
    // {
    //   title: "School Name",
    //   dataIndex: "schoolName",
    //   width: 350,

    //   render: (text, record) => <span>{schoolBilling?.name}</span>,
    // },
    {
      title: "Amount",
      dataIndex: "amount",
      align: "center",
      width: 150,

      render: (text, record) => <span>USD {record?.amount}</span>,
    },
    {
      title: "Paid At",
      dataIndex: "date",
      align: "center",
      width: 250,
      render: (text, record) => {
        return <span>{dateFormatter(record?.date)}</span>;
      },
    },
    {
      title: "Invoice",
      dataIndex: "pdf",
      align: "center",
      width: 100,
      render: (text, record) => (
        <div style={{ cursor: "pointer" }}>
          <AiOutlineCloudDownload
            fontSize="25px"
            color={Colors.Primary}
            cursor={"pointer"}
            onClick={() => {
              const url = record?.invoice;
              window.open(url, "_blank");
            }}
          />
        </div>
      ),
    },
  ];
  const data = [
    // {
    //   title: "School Name",
    //   dataIndex: "schoolName",
    //   width: 350,
    //   onCell: (record) => ({
    //     onClick: () =>
    //       navigate(`/admin/billing/paymentDetails/${record?.subId}`),
    //   }),
    //   render: (text, record) => <span>{schoolBilling?.name}</span>,
    // },
    {
      title: "Amount",
      dataIndex: "amount",
      align: "center",
      width: 150,
      onCell: (record) => ({
        onClick: () =>
          navigate(`/admin/billing/paymentDetails/${record?.subId}`),
      }),
      render: (text, record) => <span>USD {record?.amount}</span>,
    },
    {
      title: "Paid At",
      dataIndex: "date",
      align: "center",
      width: 250,
      onCell: (record) => ({
        onClick: () =>
          navigate(`/admin/billing/paymentDetails/${record?.subId}`),
      }),
      render: (text, record) => {
        return <span>{dateFormatter(record?.date)}</span>;
      },
    },
    {
      title: "Invoices",
      dataIndex: "invoices",
      align: "center",
      width: 150,
      onCell: (record) => ({
        onClick: () =>
          navigate(`/admin/billing/paymentDetails/${record?.subId}`),
      }),
      render: (text, record) => (
        <span style={styles.invoiceStyle}>View Invoices</span>
      ),
    },
  ];
  return (
    <div>
      <Text.Heading
        fontSize={"18px"}
        fontWeight={"600"}
        color={Colors.Primary}
        text="View Payment Details"
      />
      <Layout.Spacer height={20} />
      <Text.Heading
        fontSize={"17px"}
        fontWeight={"600"}
        color={Colors.Primary}
        text="Stripe"
      />
      <Layout.Spacer height={10} />
      <Table.AntDesign
        rowKey={(record) => record._id}
        columns={data}
        data={sortedBillingArray}
        loading={billingState?.isLoading}
        pagination={false}
      />
      <Layout.Spacer height={20} />
      <Text.Heading
        fontSize={"17px"}
        fontWeight={"600"}
        color={Colors.Primary}
        text="Manual"
      />
      <Layout.Spacer height={10} />
      <Table.AntDesign
        rowKey={(record) => record._id}
        columns={manualData}
        data={sortedManualBillingArray}
        loading={billingState?.isLoading}
        pagination={false}
      />
    </div>
  );
}

export default PaymentDetails;
