import { Colors } from "../../config";

const styles = {
  secondaryButtonStyle: {
    width: "100%",
    height: "50px",
    borderRadius: "6px",
    border: "1px solid #3A9AB7",
    fontSize: "16px",
    fontWeight: "400",
    color: Colors.Secondary,
    backgroundColor: Colors.White,
  },
  buttonStyle: {
    width: "100%",
    height: "50px",
    borderRadius: "6px",
    border: "1px solid #3A9AB7",
    fontSize: "16px",
    fontWeight: "400",
    color: Colors.WhiteText,
    backgroundColor: Colors.Secondary,
  },
  rowStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  labelStyle: {
    textAlign: "left",
    color: Colors.LabelText,
    fontWeight: "400",
    fontSize: "16px",
  },
  checkboxDivStyle: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
  },
  centerAlign: {
    textAlign: "center !important",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  flexColumnCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  flexRowEnd: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
  },

  mt20: {
    marginTop: "20px",
  },
  //////////////////////////////////////

  justifyCenterAlignCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  // students
  customTextfieldStyle: {
    borderRadius: "10px",
    border: `1px solid ${Colors.Primary}`,
    padding: "10px",
    width: "100%",
    fontSize: "16px",
    fontWeight: "500",
    outline: "none",
  },
  buttonStyle: {
    backgroundColor: Colors.Primary,
    fontWeight: "500",
    fontSize: "12px",
  },
  imageStyle: {
    height: "50px",
    width: "50px",
    borderRadius: "100%",
    objectFit: "contain",
    background: "white",
    marginRight: "10px",
  },
  textStyle: {
    fontSize: "12px",
    fontWeight: "500",
    textTransform: "capitalize",
  },
  statusText: {
    fontSize: "12px",
    fontWeight: "600",
    textTransform: "uppercase",
  },

  // add students
  addStudentContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    width: "60%",
    margin: "0px auto",
    gap: "20px",
  },
  registerButton: {
    backgroundColor: Colors.Primary,
    fontWeight: "500",
    fontSize: "15px",
    height: "50px",
    width: "200px",
  },
  labelTextStyle: {
    textAlign: "left",
    color: Colors.BlackText,
    fontWeight: "400",
    fontSize: "14px",
  },
  textfieldStyle: {
    borderRadius: "6px",
    border: `1px solid ${Colors.Primary}`,
    width: "100%",
    fontSize: "16px",
    fontWeight: "400",
    color: Colors.BlackText2,
  },
  // modal
  flexColumnCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  uploadUniTitle: {
    fontSize: "26px",
    color: Colors.Primary,
    fontWeight: "600",
  },
  addUniversityButton: {
    width: "100%",
    borderRadius: "10px",
    fontSize: "14px",
    color: Colors.White,
  },
  modalButton: {
    backgroundColor: Colors.Primary,
    fontWeight: "700",
    fontSize: "16px",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "30px",
    width: "100px",
  },
};

export default styles;
