import { useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";

import { StaffAction } from "../../store/actions";

import { Button, Layout, Text, TextField } from "../../components";
import { Colors } from "../../config";

import styles from "./styles";

function AddStaff() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const schoolId = useSelector((state) => state.auth.user.user.schoolId);

  const [buttonText, setButtonText] = useState("Add Staff Member");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [error, setError] = useState("");
  const [emailEerror, setEmailError] = useState("");
  const [state, setState] = useState({
    name: "",
    email: "",
    position: "admin",
  });

  const handleAddStaff = () => {
    setButtonText("Adding...");
    setButtonDisabled(true);
    if (!state.name || !state.email || !state.position) {
      setError("Please enter all fields");
      setButtonText("Add Staff Member");
      setButtonDisabled(false);
    } else {
      setError("");
      const payload = {
        name: state.name,
        email: state.email,
        position: state.position,
        schoolId,
      };
      dispatch(
        StaffAction.addStaff(payload, (res) => {
          if (
            res.status === 400 &&
            res.data.message === "Staff already exists"
          ) {
            setEmailError(res.data.message);
            setButtonText("Add Staff Member");
            setButtonDisabled(false);
          } else {
            setButtonText("Add Staff Member");
            setButtonDisabled(false);
            setEmailError("");
            setState({
              name: "",
              email: "",
              position: "",
            });
            navigate("/admin/staff-roles");
          }
        })
      );
    }
  };

  return (
    <>
      <div>
        <Text.Heading
          fontSize={"18px"}
          fontWeight={"600"}
          color={Colors.Primary}
          text="Add by email"
        />
      </div>

      <Text.Heading
        fontSize={"12px"}
        fontWeight={"500"}
        color={Colors.Gray}
        text="You can add a staff account by their email address. You will not be charged for staff accounts."
      />
      <Layout.Spacer height={10} />

      <TextField.CustomInput
        placeholder="Enter name of staff member"
        label="Name"
        value={state.name}
        onChange={(e) =>
          setState({
            ...state,
            name: e.target.value,
          })
        }
        style={styles.textfieldStyle}
      />

      <Layout.Spacer height={10} />

      <TextField.CustomInput
        placeholder="Enter email of staff member"
        label="Email Address"
        value={state.email}
        onChange={(e) =>
          setState({
            ...state,
            email: e.target.value,
          })
        }
        style={styles.textfieldStyle}
      />
      {emailEerror && (
        <Text.Heading
          fontSize={"16px"}
          fontWeight={"400"}
          color={"red"}
          text={emailEerror}
        />
      )}

      <Layout.Spacer height={10} />

      <div style={{ color: "black" }}>Designation</div>
      <div className="counselor-select">
        <Select
          placeholder="Enter position of staff member"
          label="Designation"
          value={state.position}
          onChange={(e) =>
            setState({
              ...state,
              position: e,
            })
          }
          style={styles.roleDropdown}
        >
          <Select.Option value="SchoolAdmin">Admin</Select.Option>
          <Select.Option value="Counselor">Counselor</Select.Option>
        </Select>
      </div>

      {error && (
        <>
          <Layout.Spacer height={20} />
          <Text.Heading
            fontSize={"16px"}
            fontWeight={"400"}
            color={"red"}
            text={error}
          />
        </>
      )}
      <Layout.Spacer height={20} />

      <Button.Primary
        style={{ ...styles.buttonStyle, width: "250px" }}
        onClick={handleAddStaff}
        disabled={buttonDisabled}
      >
        {buttonText}
      </Button.Primary>

      <Layout.Spacer height={30} />
    </>
  );
}

export default AddStaff;
