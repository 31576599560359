import { Colors } from "../../config";

const styles = {
  flexRowSpace: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  flexRowCenter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  iconRowDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "space-between",
  },

  applicationDivContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    gridColumnGap: "30px",
  },
  applicationDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "130px",
    width: "100%",
    background: Colors.LightGray,
    borderRadius: "30px",
    boxShadow: "0px 6px 18px -4px rgba(0,0,0,0.4)",
    paddingTop: "10px",
    cursor: "pointer",
  },

  tableText: {
    fontSize: "12px",
    fontWeight: "600",
    color: Colors.BlackText,
    textTransform: "capitalize",
  },
  tableSubHeader: {
    fontSize: "10px",
    fontWeight: "400",
    color: Colors.GrayText,
    textTransform: "capitalize",
    textAlign: "left",
  },

  // modal
  modalLeftSide: { width: "40%", textAlign: "left" },
  modalRightSide: { width: "60%", textAlign: "right" },
  modalTextLeft: {
    fontSize: "14px",
    fontWeight: "600",
    color: Colors.Primary,
  },
  modalTextRight: {
    fontSize: "14px",
    fontWeight: "500",
    color: Colors.Primary,
    textTransform: "capitalize",
  },
  fileCard: {
    border: `1px dashed ${Colors.Primary}`,
    padding: "25px 10px",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  modalButton: { width: "100%", fontWeight: "500", fontSize: "15px" },
};

export default styles;
