const capitalizeText = (str) => {
  str = str.charAt(0).toUpperCase() + str.slice(1);
  str = str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
  return str;
};

export default capitalizeText;
