import { put } from "redux-saga/effects";

import { ApiCaller } from "../../config";
import { MeetingAction } from "../actions";

export default class MeetingMiddleware {
  static *addMeeting({ payload, cb }) {
    try {
      let res = yield ApiCaller.Post(`/miscellaneous/add-meeting`, payload);
      if (res.status === 201) {
        yield put(MeetingAction.addMeetingSuccess(res.data.data));
        if (cb) {
          cb(res);
        }
      } else {
        yield put(MeetingAction.addMeetingFailure());
        // Utils.showSnackBar({
        //   message: res.data.message,
        //   severity: 'error',
        // });
      }
    } catch (err) {
      yield put(MeetingAction.addMeetingFailure());
    }
  }

  static *getMeetingInvitesAndRequests({ payload, cb }) {
    const { organizer, schoolId, limit, offset } = payload;
    try {
      const res = yield ApiCaller.Get(
        `/miscellaneous/meetingInvitesAndRequests?organizer=${organizer}&schoolId=${schoolId}&limit=${limit}&offset=${offset}`
      );
      if (res.status === 200) {
        yield put(
          MeetingAction.getMeetingInvitesAndRequestsSuccess(res.data.data)
        );
        if (cb) {
          cb(res.data.data);
        }
      } else {
        yield put(MeetingAction.getMeetingInvitesAndRequestsFailure());
      }
    } catch (err) {
      yield put(MeetingAction.getMeetingInvitesAndRequestsFailure());
    }
  }
  static *getAllMeetingsAndCount({ payload, cb }) {
    const { organizer, schoolId } = payload;
    try {
      const res = yield ApiCaller.Get(
        `/miscellaneous/fetchAllMeetingsAndCount?organizer=${organizer}&schoolId=${schoolId}`
      );
      if (res.status === 200) {
        yield put(MeetingAction.getAllMeetingsAndCountSuccess(res.data.data));
        if (cb) {
          cb(res.data.data);
        }
      } else {
        yield put(MeetingAction.getAllMeetingsAndCountFailure());
      }
    } catch (err) {
      yield put(MeetingAction.getAllMeetingsAndCountFailure());
    }
  }
  static *getMeetingsBasedOnDate({ payload, cb }) {
    const { organizer, schoolId, fromDate, toDate } = payload;
    try {
      const res =
        fromDate && toDate
          ? yield ApiCaller.Get(
              `/miscellaneous/fetchMeetingBasedOnDate?organizer=${organizer}&schoolId=${schoolId}&fromDate=${fromDate}&toDate=${toDate}`
            )
          : yield ApiCaller.Get(
              `/miscellaneous/fetchMeetingBasedOnDate?organizer=${organizer}&schoolId=${schoolId}`
            );
      if (res.status === 200) {
        yield put(MeetingAction.getMeetingsBasedOnDateSuccess(res.data.data));
        if (cb) {
          cb(res.data.data);
        }
      } else {
        yield put(MeetingAction.getMeetingsBasedOnDateFailure());
      }
    } catch (err) {
      yield put(MeetingAction.getMeetingsBasedOnDateFailure());
    }
  }

  static *changeMeetingStatus({ id, status, cb }) {
    try {
      let res = yield ApiCaller.Get(
        `/miscellaneous/change-meeting-status/${id}?status=${status}`
      );
      if (res.status === 200) {
        yield put(MeetingAction.changeMeetingStatusSuccess(res.data.data));
        if (cb) {
          cb();
        }
      } else {
        yield put(MeetingAction.changeMeetingStatusFailure());
        // Utils.showSnackBar({
        //   message: res.data.message,
        //   severity: 'error',
        // });
      }
    } catch (err) {
      yield put(MeetingAction.changeMeetingStatusFailure());
    }
  }
}
