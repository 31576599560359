import { Input } from 'antd';
import React from 'react';

const { TextArea } = Input;

function CustomTextArea({
  placeholder,
  value,
  onChange,
  rows,
  style,
  ...props
}) {
  return (
    <TextArea
      style={style}
      placeholder={placeholder}
      value={value}
      rows={rows}
      size='large'
      type='text'
      onChange={onChange}
    />
  );
}

export default CustomTextArea;
