import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";
import Lottie from "react-lottie-player";
import PhoneInput from "react-phone-input-2";
import { Form } from "react-bootstrap";

import { AiOutlineLoading3Quarters, AiFillCloseCircle } from "react-icons/ai";

import { StudentAction } from "../../store/actions";

import { Button, Layout, Modal, Text, TextField } from "../../components";
import { Colors } from "../../config";

import styles from "./styles";
import "./loader.css";
import "react-phone-input-2/lib/style.css";

import Done from "../../assets/images/57767-done.json";

function AddStudent() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const schoolId = useSelector((state) => state.auth.user.user.schoolId);

  const [error, setError] = useState("");
  const [failModal, setFailModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [buttonText, setButtonText] = useState("Register Now");
  const [state, setState] = useState({
    name: "",
    grade: "",
    email: "",
    phoneNumber: "",
  });

  const handleAddStudent = () => {
    if (!state.name || !state.grade) {
      setError("Please fill all fields");
    } else {
      setError("");
      setButtonDisable(true);
      setButtonText("Registering");
      const body = {
        name: state.name,
        grade: state.grade,
        email: state.email,
        phoneNumber: `+${state.phoneNumber}`,
        schoolId,
      };
      dispatch(
        StudentAction.addSchoolStudent(body, (res) => {
          if (res.status === 500) {
            setButtonDisable(false);
            setFailModal(true);
            setButtonText("Register Now");
          } else if (res.status === 400) {
            setButtonDisable(false);
            setButtonText("Register Now");
          } else {
            setButtonText("Register Now");
            setSuccessModal(true);
          }
        })
      );
    }
  };

  return (
    <>
      <Text.Heading
        fontSize={"18px"}
        fontWeight={"600"}
        color={Colors.Primary}
        text="Register New Student"
      />
      <Layout.Spacer height={120} />
      <div style={styles.addStudentContainer}>
        <div>
          <label style={styles.labelTextStyle}>Name*</label>
          <TextField.CustomInput
            placeholder=""
            value={state.name}
            onChange={(e) =>
              setState({
                ...state,
                name: e.target.value,
              })
            }
            style={styles.textfieldStyle}
          />
        </div>
        <div style={styles.flexColumn} className="university-form-1">
          <label style={styles.labelTextStyle}>Grade*</label>
          <Select
            defaultValue=""
            onChange={(value) =>
              setState({
                ...state,
                grade: value,
              })
            }
          >
            <Select.Option value="10">10</Select.Option>
            <Select.Option value="11">11</Select.Option>
            <Select.Option value="12">12</Select.Option>
            <Select.Option value="13">13</Select.Option>
          </Select>
        </div>
        <div>
          <label style={styles.labelTextStyle}>Email (First Priority)</label>
          <TextField.CustomInput
            placeholder=""
            value={state.email}
            onChange={(e) =>
              setState({
                ...state,
                email: e.target.value,
              })
            }
            style={styles.textfieldStyle}
          />
        </div>
        <div>
          <Form.Group>
            <div style={{ fontSize: "14px", color: "black" }}>
              Phone Number (Second Priority)
            </div>
            <PhoneInput
              inputProps={{
                name: "phone",
                required: true,
              }}
              country={"my"}
              placeholder="Phone Number"
              excludeCountries={[]}
              value={state.phoneNumber}
              onChange={(e) => {
                setState({
                  ...state,
                  phoneNumber: e,
                });
              }}
              containerClass="phone-container"
              inputClass="phone-input"
              countryCodeEditable={false}
            />
          </Form.Group>
        </div>
      </div>
      <Layout.Spacer height={20} />

      <div style={styles.justifyCenterAlignCenter}>
        {error && (
          <Text.Heading
            fontSize={"16px"}
            fontWeight={"500"}
            color={Colors.Red}
            text={error}
          />
        )}
      </div>

      <div style={styles.justifyCenterAlignCenter}>
        <Button.Primary
          style={styles.registerButton}
          disabled={buttonDisable}
          onClick={handleAddStudent}
        >
          {buttonText}
          {buttonText === "Registering" && (
            <AiOutlineLoading3Quarters
              style={{ fontSize: "18px", marginLeft: "5px" }}
              class="loader-icon"
            />
          )}
        </Button.Primary>
      </div>

      {successModal && (
        <Modal.Basic
          isModalVisible={successModal}
          centered={true}
          handleCancel={() => setSuccessModal(false)}
          closable={false}
        >
          <div style={styles.flexColumnCenter}>
            <Lottie
              loop
              animationData={Done}
              play
              style={{ width: "100px" }}
            ></Lottie>

            <Text.Heading
              fontSize={"32px"}
              fontWeight={"700"}
              color={Colors.Black}
              text="Successful!"
            />
            <Layout.Spacer height={10} />
            <Text.Heading
              fontSize={"18px"}
              fontWeight={"400"}
              color={Colors.Black}
              text="Student registered successfully!"
            />
            <Layout.Spacer height={10} />
            <Button.Primary
              style={styles.modalButton}
              onClick={() => {
                setSuccessModal(false);
                navigate("/students");
              }}
            >
              Ok
            </Button.Primary>
          </div>
        </Modal.Basic>
      )}
      {failModal && (
        <Modal.Basic
          isModalVisible={failModal}
          centered={true}
          handleCancel={() => setFailModal(false)}
          closable={false}
        >
          <div style={styles.flexColumnCenter}>
            <AiFillCloseCircle
              color="red"
              style={{ width: "70px", height: "70px" }}
            />
            <Text.Heading
              fontSize={"32px"}
              fontWeight={"700"}
              color={Colors.Black}
              text="Unsuccessful!"
            />
            <Layout.Spacer height={10} />
            <Text.Heading
              fontSize={"18px"}
              fontWeight={"400"}
              color={Colors.Black}
              text="We have encountered an error. Please check if you have filled all
              the required fields."
            />
            <Layout.Spacer height={10} />
            <Button.Primary
              style={styles.modalButton}
              onClick={() => {
                setFailModal(false);
              }}
            >
              Ok
            </Button.Primary>
          </div>
        </Modal.Basic>
      )}
    </>
  );
}

export default AddStudent;
