import {
  ADD_BILLING,
  ADD_BILLING_FAILURE,
  ADD_BILLING_SUCCESS,
  GET_BILLING,
  GET_BILLING_FAILURE,
  GET_BILLING_SUCCESS,
  GET_INVOICES,
  GET_INVOICES_SUCCESS,
  GET_INVOICES_FAILURE,
  UPDATE_BILLING,
  UPDATE_BILLING_FAILURE,
  UPDATE_BILLING_SUCCESS,
} from "../constants";

const initialState = {
  billings: {},
  invoices: [],
  isLoading: true,
  error: null,
};

export default function BillingReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_BILLING:
    case GET_BILLING:
    case GET_INVOICES:
    case UPDATE_BILLING:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case ADD_BILLING_SUCCESS:
    case UPDATE_BILLING_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case GET_BILLING_SUCCESS:
      state = {
        ...state,

        billings: action.payload,
        isLoading: false,
      };
      break;
    case GET_INVOICES_SUCCESS:
      state = {
        ...state,
        invoices: action.payload,
        isLoading: false,
      };
      break;
    case ADD_BILLING_FAILURE:
    case GET_BILLING_FAILURE:
    case GET_INVOICES_FAILURE:
    case UPDATE_BILLING_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    default:
      break;
  }
  return state;
}
