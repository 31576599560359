import {
  ADD_SCHOOL_STUDENT,
  ADD_SCHOOL_STUDENT_FAILURE,
  ADD_SCHOOL_STUDENT_SUCCESS,
  ADD_SCHOOL_STUDENT_SHEET,
  ADD_SCHOOL_STUDENT_SHEET_FAILURE,
  ADD_SCHOOL_STUDENT_SHEET_SUCCESS,
  GET_ACTIVE_STUDENTS,
  GET_ACTIVE_STUDENTS_FAILURE,
  GET_ACTIVE_STUDENTS_SUCCESS,
  GET_REGISTERED_STUDENTS,
  GET_REGISTERED_STUDENTS_FAILURE,
  GET_REGISTERED_STUDENTS_SUCCESS,
  GET_REGISTERED_STUDENT_DETAILS,
  GET_REGISTERED_STUDENT_DETAILS_FAILURE,
  GET_REGISTERED_STUDENT_DETAILS_SUCCESS,
  GET_SCHOOL_ACTIVE_STUDENTS,
  GET_SCHOOL_ACTIVE_STUDENTS_FAILURE,
  GET_SCHOOL_ACTIVE_STUDENTS_SUCCESS,
  GET_STUDENTS_DETAILS,
  GET_STUDENTS_DETAILS_FAILURE,
  GET_STUDENTS_DETAILS_SUCCESS,
  GET_STUDENT_DETAILS,
  GET_STUDENT_DETAILS_FAILURE,
  GET_STUDENT_DETAILS_SUCCESS,
  GET_STUDENT_MEETING_DETAILS,
  GET_STUDENT_MEETING_DETAILS_FAILURE,
  GET_STUDENT_MEETING_DETAILS_SUCCESS,
  GET_STUDENT_ALL_MEETING_DETAILS,
  GET_STUDENT_ALL_MEETING_DETAILS_SUCCESS,
  GET_STUDENT_ALL_MEETING_DETAILS_FAILURE,
  SEND_STUDENT_EMAIL,
  SEND_STUDENT_EMAIL_FAILURE,
  SEND_STUDENT_EMAIL_SUCCESS,
} from "../constants";

export default class StudentAction {
  static getActiveStudents(payload, cb) {
    return {
      type: GET_ACTIVE_STUDENTS,
      payload,
      cb,
    };
  }
  static getActiveStudentsSuccess(payload) {
    return {
      type: GET_ACTIVE_STUDENTS_SUCCESS,
      payload,
    };
  }
  static getActiveStudentsFailure(payload) {
    return {
      type: GET_ACTIVE_STUDENTS_FAILURE,
      payload,
    };
  }
  static getRegisteredStudents(payload, cb) {
    return {
      type: GET_REGISTERED_STUDENTS,
      payload,
      cb,
    };
  }
  static getRegisteredStudentsSuccess(payload) {
    return {
      type: GET_REGISTERED_STUDENTS_SUCCESS,
      payload,
    };
  }
  static getRegisteredStudentsFailure(payload) {
    return {
      type: GET_REGISTERED_STUDENTS_FAILURE,
      payload,
    };
  }
  static getSchoolActiveStudents(schoolId, cb) {
    return {
      type: GET_SCHOOL_ACTIVE_STUDENTS,
      schoolId,
      cb,
    };
  }
  static getSchoolActiveStudentsSuccess(payload) {
    return {
      type: GET_SCHOOL_ACTIVE_STUDENTS_SUCCESS,
      payload,
    };
  }
  static getSchoolActiveStudentsFailure(payload) {
    return {
      type: GET_SCHOOL_ACTIVE_STUDENTS_FAILURE,
      payload,
    };
  }
  static getStudentsDetails(schoolId, cb) {
    return {
      type: GET_STUDENTS_DETAILS,
      schoolId,
      cb,
    };
  }
  static getStudentsDetailsSuccess(payload) {
    return {
      type: GET_STUDENTS_DETAILS_SUCCESS,
      payload,
    };
  }
  static getStudentsDetailsFailure(payload) {
    return {
      type: GET_STUDENTS_DETAILS_FAILURE,
      payload,
    };
  }
  static getStudentDetails(id, cb) {
    return {
      type: GET_STUDENT_DETAILS,
      id,
      cb,
    };
  }
  static getStudentDetailsSuccess(payload) {
    return {
      type: GET_STUDENT_DETAILS_SUCCESS,
      payload,
    };
  }
  static getStudentDetailsFailure(payload) {
    return {
      type: GET_STUDENT_DETAILS_FAILURE,
      payload,
    };
  }
  static getRegisteredStudentDetails(id, cb) {
    return {
      type: GET_REGISTERED_STUDENT_DETAILS,
      id,
      cb,
    };
  }
  static getRegisteredStudentDetailsSuccess(payload) {
    return {
      type: GET_REGISTERED_STUDENT_DETAILS_SUCCESS,
      payload,
    };
  }
  static getRegisteredStudentDetailsFailure(payload) {
    return {
      type: GET_REGISTERED_STUDENT_DETAILS_FAILURE,
      payload,
    };
  }
  static sendStudentEmail(payload, cb) {
    return {
      type: SEND_STUDENT_EMAIL,
      payload,
      cb,
    };
  }
  static sendStudentEmailSuccess(payload) {
    return {
      type: SEND_STUDENT_EMAIL_SUCCESS,
      payload,
    };
  }
  static sendStudentEmailFailure(payload) {
    return {
      type: SEND_STUDENT_EMAIL_FAILURE,
      payload,
    };
  }
  static addSchoolStudent(payload, cb) {
    return {
      type: ADD_SCHOOL_STUDENT,
      payload,
      cb,
    };
  }
  static addSchoolStudentSuccess(payload) {
    return {
      type: ADD_SCHOOL_STUDENT_SUCCESS,
      payload,
    };
  }
  static addSchoolStudentFailure(payload) {
    return {
      type: ADD_SCHOOL_STUDENT_FAILURE,
      payload,
    };
  }
  static addSchoolStudentSheet(payload, cb) {
    return {
      type: ADD_SCHOOL_STUDENT_SHEET,
      payload,
      cb,
    };
  }
  static addSchoolStudentSheetSuccess(payload) {
    return {
      type: ADD_SCHOOL_STUDENT_SHEET_SUCCESS,
      payload,
    };
  }
  static addSchoolStudentSheetFailure(payload) {
    return {
      type: ADD_SCHOOL_STUDENT_SHEET_FAILURE,
      payload,
    };
  }
  static getStudentMeetingDetails(id, cb) {
    return {
      type: GET_STUDENT_MEETING_DETAILS,
      id,
      cb,
    };
  }
  static getStudentMeetingDetailsSuccess(payload) {
    return {
      type: GET_STUDENT_MEETING_DETAILS_SUCCESS,
      payload,
    };
  }
  static getStudentMeetingDetailsFailure(payload) {
    return {
      type: GET_STUDENT_MEETING_DETAILS_FAILURE,
      payload,
    };
  }
  static getStudentAllMeetingDetails(id, cb) {
    return {
      type: GET_STUDENT_ALL_MEETING_DETAILS,
      id,
      cb,
    };
  }
  static getStudentAllMeetingDetailsSuccess(payload) {
    return {
      type: GET_STUDENT_ALL_MEETING_DETAILS_SUCCESS,
      payload,
    };
  }
  static getStudentAllMeetingDetailsFailure(payload) {
    return {
      type: GET_STUDENT_ALL_MEETING_DETAILS_FAILURE,
      payload,
    };
  }
}
