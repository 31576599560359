import {
  ADD_BILLING,
  ADD_BILLING_FAILURE,
  ADD_BILLING_SUCCESS,
  GET_BILLING,
  GET_BILLING_FAILURE,
  GET_BILLING_SUCCESS,
  GET_INVOICES,
  GET_INVOICES_SUCCESS,
  GET_INVOICES_FAILURE,
  UPDATE_BILLING,
  UPDATE_BILLING_FAILURE,
  UPDATE_BILLING_SUCCESS,
} from "../constants";

export default class BillingAction {
  static addBilling(payload, cb) {
    return {
      type: ADD_BILLING,
      payload,
      cb,
    };
  }
  static addBillingSuccess(payload) {
    return {
      type: ADD_BILLING_SUCCESS,
      payload,
    };
  }
  static addBillingFailure(payload) {
    return {
      type: ADD_BILLING_FAILURE,
      payload,
    };
  }
  static getBilling(payload, cb) {
    return {
      type: GET_BILLING,
      payload,
      cb,
    };
  }
  static getBillingsSuccess(payload) {
    return {
      type: GET_BILLING_SUCCESS,
      payload,
    };
  }
  static getBillingFailure(payload) {
    return {
      type: GET_BILLING_FAILURE,
      payload,
    };
  }
  static getInvoices(payload, cb) {
    return {
      type: GET_INVOICES,
      payload,
      cb,
    };
  }
  static getInvoicesSuccess(payload) {
    return {
      type: GET_INVOICES_SUCCESS,
      payload,
    };
  }
  static getInvoicesFailure(payload) {
    return {
      type: GET_INVOICES_FAILURE,
      payload,
    };
  }
  static updateBilling(payload, cb) {
    return {
      type: UPDATE_BILLING,
      payload,
      cb,
    };
  }
  static updateBillingSuccess(payload) {
    return {
      type: UPDATE_BILLING_SUCCESS,
      payload,
    };
  }
  static updateBillingFailure(payload) {
    return {
      type: UPDATE_BILLING_FAILURE,
      payload,
    };
  }
}
