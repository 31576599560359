import { LOADER_FALSE, LOADER_TRUE, TOGGLE_SIDEBAR } from '../constants';

const initialState = {
  isLoading: false,
  isSidebarCollapsed: false,
};

export default function UIReducer(state = initialState, action) {
  switch (action.type) {
    case LOADER_TRUE:
      state = {
        ...state,
        isLoading: true,
      };
      break;

    case LOADER_FALSE:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case TOGGLE_SIDEBAR:
      state = {
        ...state,
        isSidebarCollapsed: !state.isSidebarCollapsed,
      };
      break;
    default:
      break;
  }

  return state;
}
