import { applyMiddleware, createStore } from "redux";
import { createLogger } from "redux-logger";
import { persistReducer, persistStore } from "redux-persist";
import createSagaMiddleware from "redux-saga";
import storage from "redux-persist/lib/storage";

import { Sagas } from "./sagas/Sagas";
import RootReducer from "./reducers";

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};

const persistedReducer = persistReducer(persistConfig, RootReducer);

const store = createStore(
  persistedReducer,
  {},
  process.env.REACT_APP_ENV === "local"
    ? applyMiddleware(createLogger(), sagaMiddleware)
    : applyMiddleware(sagaMiddleware) //Production
);

let persistor = persistStore(store);

sagaMiddleware.run(Sagas);

export { store, persistor };
