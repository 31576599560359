import React from "react";
import { useLocation, useNavigate } from "react-router";

import { Layout, Text } from "../../components";
import { Colors, Images } from "../../config";

import styles from "./styles";

const MeetingDetails = () => {
  const { state } = useLocation();

  const navigate = useNavigate();

  const date = new Date(state.data.meetingDate);
  const options = {
    day: "numeric",
    month: "long",
    year: "numeric",
  };
  const formattedDate = date.toLocaleDateString("en-US", options);

  const handleFileLink = () => {
    if (state?.data?.meetingFile) {
      window.open(state?.data?.meetingFile, "_blank");
    } else {
      return;
    }
  };

  const values = [
    { id: 1, title: "Subject", value: state?.data?.subject },
    { id: 2, title: "Date", value: formattedDate },
    { id: 3, title: "Time", value: state?.data?.timeSlot },
    { id: 4, title: "Availability", value: state?.data?.meetingType },
  ];

  return (
    <>
      <Layout.Spacer height={20} />
      <Text.Heading
        fontSize={"18px"}
        fontWeight={"600"}
        color={Colors.Primary}
        text="Meeting Details"
      />
      <Layout.Spacer height={20} />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div>
          <div style={styles.meetingDetailsCardContainer}>
            <div style={styles.meetingDetailsCard}>
              <div style={styles.meetingDetailsImageContainer}>
                <img
                  src={Images.MeetingDetails}
                  style={{ width: "100px", height: "100px" }}
                  alt=""
                ></img>
              </div>

              <div>
                {values?.map((item) => (
                  <div key={item.id}>
                    <Text.Heading
                      style={styles.meetingDetailsCardHeading}
                      text={item.title}
                    />
                    <br></br>
                    <Text.Heading
                      style={styles.meetingDetailsCardDetails}
                      text={item.value}
                    />
                    <br></br>
                    <div style={styles.meetingDetailsDividerHorizontal}></div>
                    <br></br>
                  </div>
                ))}

                <Text.Heading
                  style={styles.meetingDetailsCardHeading}
                  text={
                    state.data.meetingType === "Online"
                      ? "Meeting Link"
                      : "Meeting Place"
                  }
                />
                <br></br>
                {state.data.meetingType === "Online" ? (
                  <a
                    href={state.data.meetingLink}
                    target="_blank"
                    style={{ color: Colors.Primary }}
                  >
                    {state?.data?.meetingLink ? state.data.meetingLink : "NA"}
                  </a>
                ) : (
                  <Text.Heading
                    style={styles.meetingDetailsCardDetails}
                    text={
                      state?.data?.meetingLink ? state.data.meetingLink : "NA"
                    }
                  />
                )}

                <br></br>

                <div style={styles.meetingDetailsDividerHorizontal}></div>
                <br></br>
                <Text.Heading
                  style={styles.meetingDetailsCardHeading}
                  text="Meeting File"
                />
                <br></br>
                <Text.Heading
                  style={{
                    ...styles.meetingDetailsCardDetails,
                    cursor: "pointer",
                  }}
                  text={state?.data?.meetingFile ? "Click to Open" : "NA"}
                  onClick={handleFileLink}
                />
                <br></br>

                <div style={styles.meetingDetailsDividerHorizontal}></div>
                <br></br>
                <Text.Heading
                  style={styles.meetingDetailsCardHeading}
                  text="Message"
                />
                <br></br>
                {state?.data?.message.split(" ").length > 5 ? (
                  <Text.Heading
                    text={`${state.data.message
                      .split(" ")
                      .splice(0, 5)
                      .join(" ")} ... see more`}
                    style={{
                      ...styles.meetingDetailsCardDetails,
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      navigate("/meetings/meetingdetails/message", {
                        state: { data: state?.data },
                      })
                    }
                  />
                ) : (
                  <Text.Heading
                    style={styles.meetingDetailsCardDetails}
                    text={state.data.message}
                  />
                )}
                <br></br>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Layout.Spacer height={50} />

      {state?.data?.status.toLowerCase() === "rescheduled" ? (
        <div style={styles.rowCenter}>
          <div style={styles.rescheduledDiv}>Rescheduled</div>
        </div>
      ) : null}

      <Layout.Spacer height={40} />
    </>
  );
};

export default MeetingDetails;
