import { Layout } from "../../components";
import LoginForm from "./LoginForm";

const SignIn = () => {
  return (
    <Layout.LoginLayout>
      <>
        <Layout.HeaderSection
          title="Welcome Back"
          subtitle="Login to Continue"
        />
        <Layout.Spacer height={30} />
        <LoginForm />
      </>
    </Layout.LoginLayout>
  );
};

export default SignIn;
